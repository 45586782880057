.background {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(gray, 0.5);

    overflow-y: auto;

    padding-top: 10%;
    padding-bottom: 30px;

    z-index: 2000;
}

.modal {
    width: 400px;

    background-color: #fff;

    border-radius: 4px;

    padding: 20px 20px;

    @media (max-width: 960px){
        width: calc(100vw - 20px) !important;
    }
}

.close {
    cursor: pointer;
}