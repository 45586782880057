.avatar {
  position: relative;
  display: flex;
  align-items: center;
  
  &--background{
    position: absolute;
  
    border-radius: 4px;
    background: linear-gradient(90deg, #d6dbff 0%, #cfd5ff 31.75%, #f5f7ff 63.44%, #dee2ff 99.48%);
    background-size: 400% 400%;
    transition: width 0.3s;
    animation: gradient 4s ease infinite;
  }

  &--icon{
    position: absolute;
  }

  @keyframes gradient {
    0% {
      background-position: 100% 50%;
    }
    50% {
      background-position: 0% 50%;
    }
    100% {
      background-position: 100% 50%;
    }
  }
}



