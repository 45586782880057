@import "@palette";

.chip {
    background-color: $color-gray-8;
    list-style: none;
    border-radius: 4px;

    line-height: 20px !important;

    padding: 0 10px;

    &:not(:last-child) {
        margin-right: 10px;
    }
}

.text{
    font-size: 20px;
    line-height: 30px;
}

.name{
    // max-height: 85px;
}

.ViewAccount {
    margin: 2px 0 2px 0;
    font-size: 16px;
}

@media (max-width: 600px) {
    .text {
        font-size: 14px;
        line-height: 17px;
    }

    .ViewAccount {
        font-size: 12px;
        line-height: 14px;
    }
}