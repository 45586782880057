@import "@palette";

.popper {
    z-index: 10;

    width: 240px;

    padding: 6px;

    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    &--disabled {
        pointer-events: none;
        background-color: #F2F4F7;
    }

    &--slider {
        padding: 12px 16px;
    }
}

.item {
    min-height: 32px;

    border-radius: 4px;

    padding: 0 10px;

    cursor: pointer;

    user-select: none;

    &:hover {
        background-color: $color-gray-8;
    }

    &--main {
        composes: item;
        min-height: 38px;
        max-width: 240px;

        &--checkmark {
            composes: item;
            min-height: 38px;
            max-width: 240px;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &--checkmark-title {
        composes: item;
        cursor: default;

        &:hover {
            background-color: transparent;
        }

        min-height: 38px;
        max-width: 240px;
    }
}

.done {
    path {
        fill: $color-violet-1;
    }

    &--placeholder {
        width: 20px;
        height: 20px;
    }
}

.chip {
    height: 30px;

    border-radius: 4px;

    background: $color-violet-1;

    padding: 0 6px 0 10px;

    margin-left: -9px;

    path {
        fill: $color-white;
    }
}

.percentage {
    min-width: 110px;
    max-width: 110px;
}

.input {
    width: inherit;
    outline: none;
    border: 0;
    color: $color-gray-4;
    font-weight: 500;
    font-size: 14px;
    height: 100%;
    margin-right: -32px;
    margin-left: -12px;
    padding-right: 32px;
    padding-left: 14px;
    background: transparent;
    display: flex;
    flex-grow: 1;

    &::placeholder {
        outline: none;
        border: 0;
        color: $color-gray-4;
        font-weight: 500;
        font-size: 14px;
    }
}

.checkbox {
    &--title {
        width: 196px;
        height: 14px;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;

        color: $color-gray-3;
    }
}

.selector {
    pointer-events: auto;
    background-color: white;

    &--block {
        background-color: $color-gray-6;
        pointer-events: none;
    }
}

.mainPopper {
    z-index: 5;
    left: 0;

    height: 410px;

    padding: 6px;

    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.mainPopperWithCheckmark {
    composes: mainPopper;
    padding: 10px 20px 10px 20px;
    height: 446px;
}

.mainInput {
    width: 90px;
    outline: none;
    border: 0;
    color: $color-gray-4;
    font-weight: 500;
    font-size: 12px;
    height: 100%;
    margin: 0;
    padding-right: 0px;
    padding-left: 5px;
    background: transparent;
    display: flex;
    flex-grow: 1;

    &::placeholder {
        outline: none;
        border: 0;
        color: $color-gray-4;
        font-weight: 500;
        font-size: 12px;
    }
}

.label {
    padding: 0px 6px;
    background: #F5F6F8;
    border-radius: 4px;

    margin-right: 6px;
}

.icon {
    transition: transform .3s;

    path {
        fill: $color-gray-4;
    }

    &--opened {
        composes: icon;
        transform: rotate(180deg)
    }

    &--closed {
        composes: icon;
        transform: rotate(0deg)
    }
}

.checkbox-topics {
    cursor: pointer;
    display: inline-block;
    position: relative;
    user-select: none;
    margin-bottom: 2px;
}