@import "@palette";

.root {
	background: $color-common-background;
}

.layout {
	composes: globalWidth from global;
}

.tags {
	align-items: center;

	@media (max-width: $mobile-break) {
		flex-direction: column;
		align-items: start;

		&--container {
			max-width: 100%;
		}
	}

	@media (min-width: $mobile-break+1) {
		&--all {
			max-width: 75%;
		}
	}

	&--icon {
		width: 10px;
		height: 10px;
		margin-right: 8px;

		path {
			fill: #5D697D;
		}

		&-active {
			composes: tags--icon;

			path {
				fill: white;
			}
		}
	}

	&--container {
		cursor: pointer;
		height: 28px;

		/* Gray 5 */
		background: #D6DBE6;
		border-radius: 4px;
		padding: 10px 12px;

		&-active {
			composes: tags--container;
			background: #929EB0;
		}
	}

	&--text {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* Gray 2 */
		color: #5D697D;
		margin: 0;

		&-active {
			composes: tags--text;
			color: #FFFFFF;
		}
	}
}

.head-list {
	p {
		font-family: 'Montserrat';
		font-style: normal;
		font-weight: 600;
		font-size: 12px;
		line-height: 16px;
		/* identical to box height, or 133% */

		/* Gray 2 */
		color: #5D697D;
		margin: 0 0 0 6px;


	}

	p:nth-of-type(2) {
		padding: 3px 6px;

		background: #D6DBE6;
		border-radius: 10px;
	}

}