@import "@palette";
.main{
  padding: 30px;
  background-color: $color-white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.content {
  width: 100%;
  gap: 20px;
}
.icon-prev{
  & path {
    fill: #6071FF;
  }
  transform: scale(1.2);
}
.block{
  width: 50%;
  gap: 16px;
}
.step-chip{
  background-color: $color-violet-6;
  color: $color-white;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 500;
}
.avatar {
  width: 70px;
  height: 70px;
  background-color: $color-white;
  border-radius: 50%;
  margin: 20px 0;
}
.step {
  background-color: $color-violet-4;
  border-radius: 10px;
  width: 100%;
  padding: 24px;
}
.params-wrap {
  padding: 24px;
  border-radius: 10px;
  border: 1px solid #AEBACC;

}
.footer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 90px;
  border-top: 1px solid #E6EAF0;
  background-color: $color-white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0 30px;
}
.icon-next{
  & path {
    fill: $color-white;
  }
  transform: rotate(180deg) scale(1.4);
  margin-left: 8px;
  margin-top: 2px;
}
.btn-next {
  background-color: $color-violet-1;
  display: flex;
  align-items: center;
  color: $color-white;
}
.text {
  color: #6071FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}