@import "@palette";

.root {
	background: #FFFFFF;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	border-radius: 4px;
}

.percent {
	border-radius: 4px;

	&--default {
		composes: percent;
		width: 70px;
		line-height: 36px;
	}
	&--active {
		composes: percent;
		width: 100%;
		line-height: 54px;
		border: 2px solid $color-green;
		z-index: 4;
	}
}

.hidden {
	width: 100%;

	overflow: hidden;
	text-overflow: ellipsis;

	white-space: nowrap;
}

.avatar {
	position: relative;

	cursor: pointer;

	overflow: visible;

	img {
		border-radius: 4px;
	}
	
	&--active {
		composes: avatar;
		opacity: .8;
		&::after {
			content: "";
			position: absolute;
			top: -6px;
			left: -6px;

			width: calc(100% + 10px);
			height: calc(100% + 10px);

			border: 1px dashed $color-gray-5;
			border-radius: 5px;
		}
	}
	&--disabled {
		composes: percent;
	}

	&:not(.avatar--disabled):hover {
		&::after {
			content: "";
			position: absolute;
			top: -6px;
			left: -6px;

			width: calc(100% + 10px);
			height: calc(100% + 10px);

			border: 1px dashed $color-gray-5;
			border-radius: 5px;
		}
	}
}

.vertical_selection {
	position: absolute;
	top: 15px;
	left: 0;

	width: 100%;
	
	border: 1px dashed $color-gray-5;
	border-radius: 5px;
}

.horizontal_selection {
	position: absolute;
	top: -1px;
	left: -5px;

	height: calc(100% + 2px);

	border: 1px dashed $color-gray-5;
	border-radius: 5px;
}