@import "@palette";
.nav-footer {
  min-height: 91px;
  width: 100%;
  background-color: $color-white;
  padding: 30px;
  border-top: 1px solid #E6EAF0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.icon-prev{
  & path {
    fill: #6071FF;
  }
  transform: scale(1.2);
}
.icon-next{
  & path {
    fill: $color-white;
  }
  transform: rotate(180deg) scale(1.2);
  margin-left: 8px;
  margin-top: 2px;
}
.btn {
  padding: 12px 24px;
  border-radius: 4px;
  border: 1px solid #6071FF;
  color: $color-white;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  background-color: #6071FF;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.text {
  color: #6071FF;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}