.Metrics {
  display: flex;
  flex-direction: row;
}

@media (max-width: 600px) {
  .Metrics {
    flex-wrap: wrap;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}