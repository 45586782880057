.root {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 15px 0 18px;
	@media (max-width: 1000px) {
		flex-wrap: wrap;
	}
}

.yolo {
	display: flex;
	align-items: flex-end;
	text-decoration: none;
	&__icon {
		width: 30px;
		height: 30px;
		background-size: cover;
	}
	&__label {
		font-size: 17px;
		color: #fff;
		margin-left: 6px;
		font-family: 'Roboto Slab', serif;
		font-weight: bold;
	}
}

.menu_bar {
	display: flex;
	flex-grow: 1;
	justify-content: center;
	@media (max-width: 1000px) {
		order: 1;
		width: 100%;
		justify-content: space-between;
		padding: {
			top: 20px;
			bottom: 20px;
		}
	}
}

.option {
	font-size: 13px;
	margin: 0px 32px;
	display: block;
	text-decoration: none;
	font-weight: bold;
	position: relative;
	&--default {
		composes: option;
		color: #fff;
		&:hover {
			color: #fff;
		}
	}
	&--active {
		composes: option;
		color: #FFDA54;
		&:hover {
			color: #FFDA54;
		}
	}
	&--disabled {
		composes: option;
		color: rgba(153, 145, 203, .7);
		cursor: default;
		&:hover {
			color: rgba(153, 145, 203, .7);
		}
	}
}

.mark {
	position: absolute;
	left: calc(100% + 2px);
	bottom: calc(100% - 9px);
}

.user {
	display: flex;
	align-items: center;
	&_name {
		font-size: 14px;
		color: #fff;
	}
}