@import "@palette";

.smallSkeletonGrey {
    width: 40px;
    height: 10px;
    background: linear-gradient(90deg, #E6EAF0 0%, #F2F4F7 14.06%, #D6DBE6 27.08%, #F2F4F7 39.58%, #E6EAF0 100%);
    background-size: 400% 400%;
    animation: gradient 10s linear infinite;
    border-radius: 10px;
}

@keyframes gradient {
    100% {
        background-position: -50% 50%;
    }

    0% {
        background-position: 150% 50%;
    }

}

.root {
    border-radius: 4px;

    &--vertical {
        composes: root;
        display: inline-block;
        width: 875px;
    }

    &--horizontal {
        composes: root;
    }
}

.head {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &--vertical {
        composes: head;
        margin: 24px 30px 20px 30px;
        flex-wrap: wrap;
    }

    &--horizontal {
        composes: head;
        margin-bottom: 12px;
    }
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: $color-gray-4;
    margin: 0;
}

.value--skeleton {
    display: none;
}

.legend {
    &--vertical {
        display: flex;
        flex-direction: row;
    }

    &--horizontal {
        display: flex;
        flex-direction: row;
    }

    &--item {
        &-vertical {
            display: flex;
            align-items: center;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: $color-gray-1;

            &--skeleton {
                composes: legend--item-vertical;
                composes: smallSkeletonGrey;

            }
        }

        &-vertical:not(:last-child) {
            margin-right: 50px;
        }
    }

    &--circle {
        &-vertical {
            width: 12px;
            height: 12px;
            border-radius: 10px;
            margin-right: 10px;

            &--skeleton {
                composes: legend--circle-vertical;
                display: none;
            }
        }
    }
}

.charts {
    margin: 0;
    display: flex;

    &--vertical {
        composes: charts;
        flex-direction: row;
        justify-content: space-around;
    }

    &--horizontal {
        composes: charts;
        flex-direction: column;
        justify-content: space-between;
    }

    &--section {
        &-vertical {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            width: 60px;

            &> :nth-child(2) {
                margin-left: 20px;
            }
        }

        &-horizontal {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 60px;
        }
    }
}

.label {
    &--vertical {
        margin-top: 20px;

        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        color: $color-gray-1;

        &--skeleton {
            composes: smallSkeletonGrey;
            composes: label--vertical;
            font-size: 0;
            color: transparent;
            margin-left: auto;
            margin-right: auto;
        }
    }
}

@media (max-width: 600px) {
    .title {
        margin-bottom: 10px;
    }

    .legend--vertical {
        margin-bottom: 10px;
    }

    .head {
        &--vertical {
            margin: 12px 16px 0px 16px;
            flex-wrap: wrap;
        }

        &--horizontal {
            margin: 10px 0 0 0;
        }
    }

    .charts {
        flex-wrap: wrap;

        &--section--wrapper {
            flex-basis: 30%;
            display: flex;
            justify-content: center;
            flex-direction: column;
        }

        &--section-vertical {
            width: unset;
            justify-content: center;
        }
    }
}