@import "@palette";

.root {
    display: flex;
}

.contents {
    display: flex;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    &--vertical {
        composes: contents;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 30px 10px 27px 10px;
        // width: 195px;
        flex-grow: 1;
    }

    &--horizontal {
        composes: contents;
        flex-direction: row;
        padding: 20px 20px;
        width: 330px;
    }
}

.avatar {
    display: flex;
    flex-direction: row;

    &--image {
        img {
            height: 100px;
            width: 100px;
        }

        border-radius: 10px;
    }
}

.info {
    &--vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &--horizontal {
        margin-left: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &--name {
        font-family: Montserrat;
        font-style: normal;
        color: $color-gray-1;
        font-weight: 600;
        margin: 0;
        max-width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
            overflow: unset;
        }

        &--vertical {
            composes: info--name;
            font-size: 16px;
            line-height: 170%;
            margin-top: 12px;
            align-items: center;
        }

        &--horizontal {
            composes: info--name;
            font-size: 18px;
            line-height: 30px;
        }
    }

    &--link {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 170%;
        color: $color-violet-1;
        white-space: nowrap;
    }

    &--views-published {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        display: flex;
        align-items: center;

        color: $color-gray-1;
        margin: 0 0 2px 0;

        &--title {
            composes: info--views-published;
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $color-gray-3;
        }
    }
}

.views {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    color: $color-gray-3;
    margin: 0;
}

@media (max-width: 600px) {
    .contents {
        &--vertical {
            padding: 24px 30px 30px 30px;
        }
    }

    .info {
        &--name {
            font-size: 14px;
            line-height: 18px;
        }

        &--link {
            font-size: 12px;
            line-height: 20px;
        }
    }
}