@import "@palette";

.btn {
    width: 100%;
    height: 36px;

    border-radius: 4px;

    padding: 0 12px;

    &:not(&--disabled) {
        cursor: pointer
    }

    transition: border .3s, box-shadow .3s;

    &--opened {
        composes: btn;
        background: $color-white;
        border: 1px solid $color-violet-1;
        box-shadow: 0 0 0 1px $color-violet-1;
    }

    &--closed {
        composes: btn;
        background: $color-white;
        border: 1px solid $color-gray-6;
    }

    &--disabled {
        composes: btn;
        background: $color-gray-6;
    }

    user-select: none;
}

.icon {

    transition: transform .3s;

    path {
        fill: $color-gray-4;
    }

    &--opened {
        composes: icon;
        transform: rotate(180deg)
    }
    &--closed {
        composes: icon;
        transform: rotate(0deg)
    }
}