.filterTabs {
  display: flex;
  flex-direction: column;
  gap: 22px;

  .categories {
    display: flex;
    gap: 50px;
    border-bottom: 2px solid #e6eaf0;

    .category {
      background: none;
      border: none;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      color: #323841;
      padding: 12px 0;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      transition: all 0.5s ease;
      transform: translateY(1px);

      &.active {
        border-bottom: 2px solid #6071ff;
        color: #6071ff;
      }

      &:hover:not(.active) {
        color: #929eb0;
      }
    }
  }
  .statsMessage {
    font-size: 18px;
    color: #323841;
    font-weight: 600;
  }

  .timeFilters {
    display: flex;
    gap: 8px;

    .timeFilter {
      background: none;
      border: 1px solid #6071ff;
      color: #6071ff;
      padding: 4px 12px;
      border-radius: 4px;
      font-size: 14px;
      cursor: pointer;
      transition: all 0.3s ease;

      &.active {
        background: #6071ff;
        color: #fff;
      }

      &:hover:not(.active) {
        background: #eff1ff;
      }
    }
  }
}
