@import "@palette";

.root {
    padding: 20px 24px;
    border-radius: 10px;
    background-color: white;

    width: 530px;

    @media (max-width: $mobile-break) {
        width: 100%
    }
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: $color-gray-4;
    margin: 0 0 24px 0;
    color: rgba(255, 101, 156, 0.5)#FF659C
}

.dot {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-top: 20px;
}

.legend {
    width: 50%;
    &-percent {
        color: var(--gray-1, #323841);
        /* Header Secondary */
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin: 20px 0 0 6px;
        /* 122.222% */
    }

    &-text {
        color: var(--gray-2, #5D697D);
        font-family: Montserrat;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 18px;
        /* 128.571% */
        margin: 10px 0 0 0;
    }

    &-devices{
        width: fit-content;
        margin: 0 50px 0 0;
    }
}