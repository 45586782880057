@import "@palette";

.list {
    padding: 4px;

    margin: 0;

    list-style: none;

    width: 220px;

    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    li {
        padding: 6px 10px;

        user-select: none;

        border-radius: 4px;

        &:not(.disabled) {
            cursor: pointer;

            transition: background .3s;
            
            &:hover {
                background-color: $color-gray-8;
            }
        }
        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }
}

.active {
    position: absolute;
    z-index: 1000;
    top: 10px;
    right: 20px;
    margin: 0;
}

.disabled {
    cursor: default;
}

.sorterItem {
    position: relative;
    margin: 0;
}
