@import "@palette";

.root {
	width: 80px;
	position: relative;
	display: flex;
	// cursor: pointer;
}

.label {
	position: absolute;
	width: 100%;
	left: 0;
	top: calc(100% + 10px);
	margin: 0;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;

	text-transform:capitalize;
	color: $color-gray-1;
	&--hide-overflow{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
}

.one {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
}

.fill {
	position: relative;
	background: $color-green;
	border-radius: 10px 10px 0 0;
}

.share {
	position: absolute;
	width: 100%;
	left: 0;
	top: -22px;
	
	display: flex;
	justify-content: center;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 17px;
	text-align: center;
	color: $color-violet-1;
}
@media (max-width: 600px) {
	.fill {
		border-radius: 0 10px 10px 0;

	}
	.root{
		width: 90%;
	}
	.one {
		justify-content: start;
	}
	.share{
		display: flex;
		justify-content: flex-end;
		top: -3px;
		left: 38px;
		align-items: center;
	}
	.label{
		position: relative;
		top: 0;
		left: -80px;
		width: 72px;
		justify-content: end;
		max-height: 14px;
	}
}