@import '@palette';

.root {
    composes: card from global;
    align-items: center;
    flex-direction: column;

    box-shadow: unset;
    width: 190px;
    height: 310px;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;

    color: $color-gray-4;
    margin: 24px 0;
    color: rgba(255, 101, 156, 0.5)#FF659C
}

.body {
    display: flex;
    align-items: center;
    &--vertical {
        composes: body;
        flex-direction: column;
    }
}

.legend {
    display: flex;
    flex-direction: column;
    justify-content: center;
    &--section {
        margin-top: 20px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        &-percent {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;

            color: $color-violet-1;
            background: #E2E6FF;
            border-radius: 4px;
            margin: 0;
            padding: 2px 8px;
        }
        &-label {
            margin: 0;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;

            color: $color-gray-1;
            padding: 2px 8px;
        }
    }
}

.placeholder {
    composes: root;
    position: relative;
    &_content {
        filter: blur(3px);
    }
}

.empty_donut {
    border-radius: 50%;
    background: #f1f1ee;
    height: 150px;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    &_center {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: #fff;
    }
}

.lines {
    flex-grow: 1;
    padding: 5px 5px 60px 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    &_line {
        height: 10px;
        border-radius: 8px;
        background: #f1f1ee;
    }
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.value {
    border-radius: 6px;
    font-size: 14px;
    line-height: 18px;
    font-weight: bold;
    color: #fff;
    width: 40px;
    text-align: center;
    padding: 2px 0;
}

.label {
    font-size: 12px;
    line-height: 15px;
    color: #888;
    margin-left: 8px;
}

.blur {
    transition: filter 1s;

    &--active {
        composes: blur;
        filter: blur(10px);
    }
    &--default {
        composes: blur;
        filter: blur(0px);
    }
}

.content {
    display: flex;
    flex-direction: row;
}

@media (max-width: 600px) {
    .root {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        
        width: unset;
        height: unset;
    }
    .title {
        flex-basis: 100%;
        margin-left: 16px;
    }
}