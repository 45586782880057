@import '@palette';
$bar-border-radius: 20px;
$bar-line-border-radius: 10px;

.smallSkeletonGrey {
    width: 30px;
    height: 10px;
    background: linear-gradient(90deg, #E6EAF0 0%, #F2F4F7 14.06%, #D6DBE6 27.08%, #F2F4F7 39.58%, #E6EAF0 100%);
    background-size: 400% 400%;
    animation: horizontalGradient 10s linear infinite;
    border-radius: 10px;
}

.skeletonPink {
    opacity: 0.5;
    background: linear-gradient(0deg, #FF659C 0%, #ff659bde 14.06%, #ff659b88 27.08%, #ff659bde 39.58%, #FF659C 100%);
    background-size: 400% 400%;
    background-color: transparent;
    animation: verticalGradient 10s linear infinite;
}

.skeletonBlue {
    opacity: 0.5;
    background: linear-gradient(0deg, #6071FF 0%, #6070ffb0 14.06%, #6070ff4e 27.08%, #6070ffb0 39.58%, #6071FF 100%);
    background-size: 400% 400%;
    background-color: transparent;
    animation: verticalGradient 10s linear infinite;
}

.skeletonHorizontalBlue {
    opacity: 0.5;
    background: linear-gradient(0deg, #6071FF 0%, #6070ffb0 14.06%, #6070ff4e 27.08%, #6070ffb0 39.58%, #6071FF 100%);
    background-size: 400% 400%;
    background-color: transparent;
    animation: horizontalGradient 10s linear infinite;
}

@keyframes verticalGradient {
    100% {
        background-position: 50% -50%;
    }

    0% {
        background-position: 50% 150%;
    }
}

@keyframes horizontalGradient {
    100% {
        background-position: -50% 50%;
    }

    0% {
        background-position: 150% 50%;
    }

}

.root {
    &--vertical {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20px;
    }

    &--horizontal {
        display: flex;
        flex-direction: column;
        height: 100px;
    }
}

.head {
    &--horizontal {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: -5px;
    }
}

.label {
    &--horizontal {
        margin: 0;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: $color-gray-1;

        &--skeleton {
            composes: smallSkeletonGrey;
            width: 100px;
            composes: label--horizontal;
            color: transparent;
            font-size: 0;
            margin-bottom: 10px;
        }
    }
}

.percent {
    &--vertical {
        display: flex;
        justify-content: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;

        &--skeleton {
            composes: smallSkeletonGrey;
            composes: percent--vertical;
            color: transparent;
            font-size: 0;
            margin-bottom: 10px;
        }

        /* identical to box height, or 167% */

        /* Violet 1 */

        color: #6071ff;
    }

    &--horizontal {
        display: flex;
        justify-content: flex-end;
        color: #6071ff;
        margin: 0;

        &--skeleton {
            composes: smallSkeletonGrey;
            composes: percent--horizontal;
            color: transparent;
            font-size: 0;
            margin-bottom: 0px;
        }

        // width: 250px;
    }
}

.bar {
    &--vertical {
        background-color: $color-gray-7;
        width: 20px;
        height: 140px;
        overflow: auto;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: $bar-border-radius;


    }

    &--horizontal {
        background-color: $color-gray-7;
        height: 12px;
        overflow: auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        border-radius: $bar-border-radius;
        margin-top: 10px;
    }

    &--line {
        &-vertical {
            border-radius: $bar-line-border-radius;

            &--skeleton {
                composes: bar--line-vertical;

                &--pink {
                    composes: skeletonPink;
                }

                &--blue {
                    composes: skeletonBlue;
                }
            }
        }

        &-horizontal {
            border-radius: $bar-line-border-radius;

            &--skeleton {
                composes: bar--line-horizontal;

                &--blue {
                    composes: skeletonHorizontalBlue;
                }
            }
        }
    }
}