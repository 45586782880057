@import "@palette";

.root {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1000vw;
    height: 1000vh;
    padding: 30px;
    z-index: 2001;

    background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
    position: relative;
    width: 550px;
    background-color: white;
    padding: 32px 30px;

    border-radius: 4px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &--close {
        cursor: pointer;

        path: {
            fill: #929EB0;
        }
    }
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #323841;
    margin: 0;

    &-sub {
        composes: title;
        // font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        // color: #5D697D;
        margin: 0 0 10px 0;
    }
}

.line {
    position: relative;
    left: -30px;
    width: 550px;
    height: 1px;
    background: #E6EAF0;
}

.icon {
    cursor: pointer;

    path {
        fill: #929EB0;
    }
}

.avatar {
    cursor: pointer;

    img {
        border-radius: 50%;
        position: absolute;
        width: 40px;
        height: 40px;
        border: 2px solid #FFFFFF;
    }

    div {
        border-radius: 50%;

        svg {
            border-radius: 50%;
            border: solid 2px white;
        }
    }
}

.you {
    background-color: #cfd5ff;
    border-radius: 50%;
    width: 50px;
    height: 50px;

    p {
        font-family: 'Montserrat';
        font-style: normal;
        // font-weight: 600;
        font-size: 16px;
        color: #6171fe;
    }
}

.editRole {
    &-icon {
        path {
            fill: $color-violet-1
        }
    }

    &-role {
        padding: 10px;
        border-radius: 4px;

        &:hover {
            background: $color-gray-9;
        }

        cursor: pointer;
    }
}

.inputContainer {
    border: solid 1px $color-gray-5;
    border-radius: 10px;
    padding: 0 8px;

    &:focus-within {
        outline: solid 2px $color-violet-3
    }
}

.input {
    border: none;
    width: 100%;
    height: 36px;
    outline: none;
    font-size: 14px;
    color: $color-gray-1;
    min-width: 0;
    background: transparent;
    padding: 8px;

    &::placeholder {
        color: $color-gray-3;
        font-size: 14px;
    }
}

.btn{
    &:focus{
        // border: 1px solid $color-violet-2;
        background-color: $color-violet-2;
        outline: 1.5px solid $color-violet-2;
    }
}