.root {
	margin: {
		right: 20px;
		bottom: 20px;
	}
	width: 100%;
	@media (max-width: 600px) {
		width: 100%;
	}
	input {
		outline: none;

		transition: background .3s, border .3s;

		background: #f1f1ee;

		border: 2px solid transparent;
		border-radius: 10px;

		padding: 10px 12px;

		font-size: 14px;
		color: #2a2a31;

		width: 100%;

		&:focus {
			border: 2px solid #7583F7
		}
	}
}

.hat {
	display: flex;
	justify-content: space-between;
}

.title {
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	margin: {
		bottom: 6px;
	}
}

.error {
	font-size: 12px;
	color: #e73c3c;
}