.accountSettings {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.nameBlock {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.input {
  width: 260px;
}

.card {
  background-color: #F8F9FB;
  border-radius: 4px;
  padding: 30px 24px;

  display: flex;
  gap: 20px;
  flex-direction: column;
}

.cardHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
  height: 40px;
  align-items: center;
}

.cardInputs {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.done {
  path {
    fill: #10BF61;
  }
}