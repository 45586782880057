@import "@palette";

.header {
	padding: 108px 0 20px 0;

	&--icon {
		width: 60px;
		height: 60px;
		margin-right: 20px;
	}
}

.description {
	margin: 0;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;

	color: #929EB0;
	display: flex;

}

.wrap {
	background: #a3adff;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.icon {
	path {
		fill: white;
	}
}

.arrow {
	path {
		fill: #929EB0;
	}
}

.title {
	font-size: 30px;
	line-height: 40px;
	text-decoration-line: underline;
	text-decoration-thickness: 2px;

	/* Gray 3 */
	color: #929EB0;
}

.desc {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	/* Gray 3 */
	color: #929EB0;
	margin: 0;
}

.add {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;

}

.btns {
	@media (max-width: $mobile-break) {
		margin-top: 20px;
	}
}

.container {
	@media (max-width: $mobile-break) {
		flex-direction: column;
	}
}
.btn {
	display: flex;
	align-items: center;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	path {
		fill: $color-violet-1;
	}
	&:hover {
		path {
			fill: $color-white;
		}
	}
}