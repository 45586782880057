@import '@palette';

.chat {
  position: relative;
  width: 550px;
  height: 592px;
  border-radius: 10px;
  background-color: white;

  padding: 20px;
}

hr.divider {
  width: 100%;
  border: none;
  border-top: 2px solid #F2F4F7;
}

.messagesContainer {
  display: flex;
  flex-direction: column-reverse;
  height: 420px;
  overflow-y: scroll;
}



.sendMessage {
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: 25px;
  background-color: #F2F4F7;
  border-radius: 10px;
  padding: 11px 16px;
}

.sendMessageContainer {
  position: absolute;
  width: calc(100% - 40px);
  bottom: 20px;
}