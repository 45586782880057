@import '@palette';

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.popup {
  display: flex;
  flex-direction: column;
  z-index: 2000;
  position: relative;
}
.modalContent {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 500px;
  position: relative;
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  font-weight: bold;
  padding: 0;
  margin: 0;
  cursor: pointer;
  svg {
    width: 24px;
    height: 24px;
    path {
      fill: #929EB0;
    }
  }
}

.infoSection {
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.infoItem {
  padding: 16px;
  border-radius: 10px;
  flex-direction: column;
  width: 100%;
  border: 1px solid #D6DBE6;
}


.errorMessage {
  color: #d32f2f;
  font-size: 14px;
  margin: 10px 0;
}
.downloadButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    path{
      fill: white;
    }
  }
}
.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%); ;
}