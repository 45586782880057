.stats {
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
  gap: 30px;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    padding: 15px;
  }

  .stat {
    margin-left: 10px;
    .value {
      color: #323841;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }

    .label {
      color: #929EB0;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;
    }
  }
  @media (max-width: 500px) {
    display: flex;
    flex-direction: column;
  }
}
