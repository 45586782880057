@import "@palette";
.sidebar {
  width: 50%;
  display: flex;
  gap: 16px;
}
.item {
  border: 1px solid #AEBACC;
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.icon-wrapper {
  margin-left: -5px;
  z-index: 2;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    width: 40px;
    height: 40px;
    top: 0;
    left: -2px;
    border-radius: 50%;
    background-color: $color-white;
    z-index: -1;
  }
}

.option {
  display: flex;
  align-items: flex-start;
  border-bottom: 1px solid #AEBACC;
  background-color: #fff;
  min-height: 110px;
  margin-bottom: 24px;
  margin-left: 16px;
}

.icon {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

.details {
  flex-grow: 1;
  padding-right: 16px;
}

.selectButton {
  width: 100%;
  background-color: $color-violet-1;
  color: $color-white;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  &:disabled {
    background-color: $color-gray-new-3;
    color: #D6DBE6;
    path {
      fill: #D6DBE6;
    }
  }

  & > svg {
    transform: rotate(180deg);
    margin-left: 8px;
    width: 16px;
    height: 16px;
    path {
      fill: $color-white;
    }
  }
}
