* {
	font-family: Montserrat;
	box-sizing: border-box;
}

body {
	padding: 0;
	margin: 0;
	background: #F1F3F8;
}

#root {
	background: #F1F3F8;
	position: relative;
}

button {
	outline: none
}

a {
	text-decoration: none;
	color: #6172FF;
}

a:hover {
	color: #525EC2;
}

input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}

/* canny change log indicator style*/
.Canny_BadgeContainer .Canny_Badge {
	position: absolute;
	top: 8px; 
	right: 9px;
	border-radius: 10px;
	background-color: #f09a6b;
	border: 1px solid white;
  }