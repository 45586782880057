@import "@palette";

.table {
    &-header {
        position: sticky;
        top: 0;
        border-radius: 4px;
        background: var(--Gray-7, #F8F9FB);
        height: 48px;
        width: 100%;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 0.01fr 1fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr  0.4fr 0.4fr 1.2fr;
        gap: 20px;
    }

    &-item {
        border-radius: 4px;
        height: 75px;
        padding: 0 16px;
        background-color: white;

        display: grid;
        align-items: center;
        grid-template-columns: 0.01fr 1fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 0.4fr 1.2fr;
        gap: 20px;

        p {
            font-size: 12px;
        }
    }
}

.dividerHoriz {
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}

.socials {
    column-gap: 6px;

    svg {
        path {
            fill: #5D697D;
        }
    }
}

.ava {
    div {
        border-radius: 24px;
    }

    img {
        border-radius: 24px;
    }

    svg {
        top: 0;
        border-radius: 24px;
    }

    @media (max-width: $mobile-break) {
        div {
            border-radius: 10px;
        }

        div:not(:first-child) {
            //twitch
            position: absolute;
            top: 10px;
            left: 58px;
        }

        div:first-child {

            //avatar filler
            svg {
                top: unset;
                left: unset;
            }
        }

        img {
            border-radius: 10px;
        }

        svg {
            top: 10px;
            left: 58px;
        }
    }
}

//style second p in the div
.names {
    p:nth-child(2) {
        color: #929EB0;
        font-size: 12px;
    }
}

.link {
    width: 34px;
    height: 34px;

    border-radius: 4px;
    background: $color-violet-4;

    display: flex;
    align-items: center;
    justify-content: center;

    path {
        fill: $color-violet-1;
        opacity: .6;
    }

    &:hover {
        path {
            opacity: 1;
        }
    }
}

.loader {
    path {
        fill: red;
    }

    div {
        div {
            border-color: #929EB0 transparent transparent transparent;
        }
    }
}