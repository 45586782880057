.root {
    width: 282px;
    height: 38px;

    outline: none;

    border: none;

    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 4px;

    font-size: 14px;
    line-height: 18px;
    font-weight: 600;

    border: 1px solid #6172FF;

    cursor: pointer;

    &--blue {
        composes: root;
        background-color: #6172FF;
        color: #fff;
    }
    &--white {
        composes: root;
        background-color: #fff;
        color: #6172FF;
    }
}