.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 45px 25%;
  text-align: center;
}
.btn {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}