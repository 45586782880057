@import "@palette";

.root {
	flex-grow: 1;
	border: 1px solid $color-violet-1;
	border-radius: 4px;
	position: relative;
	&--margin{
		composes: root;
		margin-right: 22px;
	}
	grid-column: 2/6;
	z-index: 0;
}

.bar {
	border-radius: 4px;
	background: linear-gradient(90deg, #D6DBFF 0%, #CFD5FF 31.75%, #F5F7FF 63.44%, #DEE2FF 99.48%);
	background-size: 400% 400%;
	height: 36px;
	transition: width .3s;
	animation: gradient 4s ease infinite;
}

@keyframes gradient {
	0% {
		background-position: 100% 50%;
	}
	50% {
		background-position: 0% 50%;
	}
	100% {
		background-position: 100% 50%;
	}
}


.label {
	position: absolute;
	width: 100%;
	height: 100%;
	font-size: 14px;
	font-weight: bold;
	color: $color-violet-1;
	
	top: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	@media (max-width: 600px) {
		font-size: 12px;
	}
}