@import "@palette";

.main {
    display: flex;
    justify-content: center;
    width: 100%;
    border-radius: 4px;
    margin: 40px 0 0 0;
    scroll-margin-top: 80px;
}

@media (max-width: 600px) {
    .main {
        padding: 0px;
        margin: 30px 0 0 0;
    }
}
