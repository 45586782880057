.audienceLocker {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  border: 2px solid #6071FF;
  background-color: white;
  border-radius: 10px;

  padding: 20px 30px;
  gap: 20px;
}

.icon {
  background-color: #DFE2FF;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text {
  display: flex;
  flex-direction: column;
}