@import "@palette";

.root {
    border-radius: 10px;

    @media (min-width: 601px) {
        background-color: $color-gray-9;
        height: 100px;
        padding: 20px;
    }

    @media (max-width: 600px) {
        padding: 20px;
        flex-direction: column;
    }
}

.item {
    padding: 20px 25px;
    border-radius: 10px;

    @media (max-width: 600px) {
        background-color: $color-gray-9;
    }

    @media (min-width: 601px) {
        &--1 {
            composes: item;
            width: 100%;
        }

        &--2 {
            composes: item;
            width: calc((100% - 20px) / 2);
        }

        &--3 {
            composes: item;
            width: calc((100% - 40px) / 3);
        }

        @media (max-width: 600px) {
            padding: 15px 20px;
        }
    }
}

.text {
    margin-left: 20px;
}

.predictionTitle {
    max-height: 30px;
    line-height: 15px;
    max-width: 100px;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 12px;

    @media (max-width: 600px) {
        font-size: 10px;
    }

    &:hover {
        -webkit-line-clamp: unset;
        overflow: unset;
    }
}

.hidden {
    margin: 0 0 10px 0;
    width: 80px;
    height: 15px;

    background: $color-gray-5;
    border-radius: 10px;
}

.icon {
    min-width: 24px;

    @media (max-width: 600px) {
        max-width: 20px;
    }

    transform: scale(1.5);
}

.value {
    font-size: 18px;

    @media (max-width: 600px) {
        font-size: 16px;
    }
}