@import '@palette';

.filtersPanel {
  position: relative;
  width: 100%;

  padding: 20px 20px;

  border-radius: 0 4px 4px 4px;

  background-color: white;

  &--bottom-container {
    padding: 20px 0 0 0;
    border-top: 1px solid $color-gray-6;
  }

  &--filters {
    margin-bottom: 30px;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 16px 20px;

  padding: 0 1px;
}
