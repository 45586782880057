@import "@palette";

.root{
	width: 100%;
	height: 200px;
	padding: 40px;

	background: $color-gray-9;
	border-radius: 4px;
}

.text{

	font-family: 'Montserrat';
	font-style: normal;
	margin: 0;
}
.title{
	composes: text;
	font-weight: 600;
	font-size: 21px;
	line-height: 26px;

	color: $color-gray-1;
}
.helper{
	composes: text;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;

	color: $color-gray-3;
}
.note{
	composes: text;
	font-weight: 500;
	font-size: 12px;
	line-height: 20px;

	color: $color-violet-1;
}

.bar{
	height: 12px;
	&-container{
		composes: bar;
		width: 100%;

		background: #EBEBEB;
		border-radius: 10px;
	}
	&-filled{
		composes: bar;

		background: linear-gradient(90deg, #6C7CFF 0%, #6071FF 27.54%, #CCD1FF 38.54%, #6071FF 48.85%, #6C7CFF 100%);
		background-size: 200% 200%;
		animation: gradient 6s linear infinite;

		border-radius: 10px;
	}
}
@keyframes gradient {
    100% {
        background-position: -50% 50%;
    }
    0% {
        background-position: 150% 50%;
    }
}
@media (max-width: 600px) {
	.root{
		height: 250px;
		padding: 20px;		
	}
}