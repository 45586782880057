@import "@palette";
.icon-wrapper {
  margin-left: -5px;
  z-index: 2;
  position: relative;
  height: 30px;
  width: 30px;
  svg {
    width: 30px;
    height: 30px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    top: 0;
    left: -2px;
    border-radius: 50%;
    background-color: $color-white;
    z-index: -1;
  }
}