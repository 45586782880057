@import "@palette";

.root {
    width: 34px;
    height: 34px;

    border-radius: 4px;
    background: $color-violet-4;

    display: flex;
    align-items: center;
    justify-content: center;
    
    path {
        fill: $color-violet-1;
        opacity: .6;
    }

    &:hover {
        path {
            opacity: 1;
        }
    }
}