@import "@palette";

.root {
	width: 60px;
	height: 60px;

	border-radius: 50%;

	border: 1px solid $color-violet-1;

	position: relative;
}

.circle {
	position: absolute;

	border-radius: 50%;

	border: 1px solid $color-violet-1;
}

.circle__medium {
	composes: circle;
	top: 15%;
	left: 15%;

	width: 70%;
	height: 70%;
}

.circle__small {
	composes: circle;
	top: 35%;
	left: 35%;

	width: 30%;
	height: 30%;
}

.bar_start {
	width: 50%;
	height: 1px;

	position: absolute;
	top: 50%;
	left: calc(50% - 0.5px);

	transform-origin: left center;

	background-color: $color-violet-1;

	animation: bar_start_rotation 1s linear infinite;

	box-shadow: 0 0 10px $color-violet-1;
}

@keyframes bar_start_rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

.bar_end {
	width: 50%;
	height: 1px;

	position: absolute;
	top: 50%;
	left: calc(50% - 0.5px);

	transform-origin: left center;

	background-color: $color-violet-1;

	animation: bar_end_rotation 1s linear infinite;

	box-shadow: 0 0 10px $color-violet-1;
}

@keyframes bar_end_rotation {
	from {
		transform: rotate(20deg);
	}
	to {
		transform: rotate(380deg);
	}
}

.dot {
	width: 5px;
	height: 5px;

	background-color: $color-violet-2;

	border-radius: 50%;

	position: absolute;

	opacity: 0;

	box-shadow: 0 0 5px $color-violet-2;
}

.dot__first {
	composes: dot;

	top: 15%;
	left: 60%;

	animation: fade 1s ease-out 0.8s infinite;
}

.dot__second {
	composes: dot;

	top: 60%;
	left: 70%;

	animation: fade 1s ease-out infinite;
}

.dot__third {
	composes: dot;

	top: 65%;
	left: 15%;

	animation: fade 1s ease-out 0.3s infinite;
}

@keyframes fade {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}
