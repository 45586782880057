.root {
	margin: 25px 30px 0px;
	background: white;
	transition: background .3s;
	padding: 5px;

	font-size: 16px;
	color: white;
	font-weight: bold;
	text-align: center;

	border-radius: 4px;
	border: 1px solid #4D7CD6;
	height: 54px;
	cursor: pointer;

	background: #4285F4;
	div{
		width: 100%;
	}

	&--mobile {
		composes: root;
		height: 34px;
		font-size: 12px;
	}

	&:hover {
		background: #4D7CD6;
		color: white;
	}
}