@import "@palette";

.topicsPopup {
  z-index: 5;


  padding: 10px;
  margin-bottom: 10px;

  background: $color-white;

  border: 1px solid $color-gray-6;
  border-radius: 4px;

  box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);
}

.arrow {
  position: absolute;
  width: 0;
  height: 0;
  left: 5px;
  top: -11px;
  z-index: 20;
  border-left: 10px solid rgba(51, 51, 51, 0.02);
  border-right: 10px solid rgba(51, 51, 51, 0.02);
  border-top: 10px solid white;
}

.topicsPopupContainer {
  position: relative;
}

.chip {
  background-color: #F2F4F7;
  color: $color-gray-new-2;

  border-radius: 10px;

  line-height: 12px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  padding: 6px 10px;
  &:not(:first-child) {
      margin-top: 5px;
  }
}

.count {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 10px;
  border-radius: 10px;
  border: 1px solid #D6DBE6;
  cursor: pointer
}