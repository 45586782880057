.root {
    display: flex;
    align-items: center;
    position: relative;
    path {
        transform-origin: center;
    }
}

.chart {
    display: flex;
    align-items: center;
}

.center {
    background: #fff;
    border-radius: 50%;
    position: absolute;
}

.content {
    display: flex;
    flex-direction: row;
}
