@import "@palette";

.root {
	display: flex;
	margin-left: -10px;
	margin-top: -5px;

	border: 2px solid $color-violet-1;
	border-radius: 4px;
	
	min-width: calc(100% + 16px);

	input {
		width: 100%;
		background: transparent;
		padding: 2px 8px;
		border: none;
		outline: none;
	}
}