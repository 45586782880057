@import '@palette';

.accordionContainer {
  width: 100%;
}

.accordion {
  border-bottom: 1px solid #E6EAF0;

  &:last-child {
    margin-bottom: 0;
    border-bottom: none;
  }
}

.accordionSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: $color-white;
  cursor: pointer;
}

.accordionTitle {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: $color-gray-new-4
}

.toggleButton {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordionDetails {
  padding: 12px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.title-wrap {
  padding-bottom: 16px;
  border-bottom: 1px solid #E6EAF0;
}

.option {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  color: $color-gray-new-1;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  margin-left: 10px;

  input {
    margin-right: 8px;
  }

  &:last-child {
    margin-bottom: 0;
  }
}
.checked {
  font-weight: bold;
}
