@import "@palette";

.text {
  font-size: 16px;
  cursor: pointer;
  color: $color-violet-1;

  &--active {
    cursor: pointer;
    color: $color-violet-1;
    text-decoration: underline $color-violet-1;
  }
}

.text:hover {
  color: $color-violet-3;
}


@media (max-width: 600px) {
  .text {
    font-size: 10px;
    line-height: 12px;
  }
}