.root { 
	position: absolute;
	width: 20px;
	height: 20px;
	background: #fff;
	transform: rotate(45deg);
	border-radius: 5px;
}

[x-placement=bottom] .root {
	top: -8px;
	left: calc(50% - 10px);
}

[x-placement=top] .root {
	bottom: -8px;
	left: calc(50% - 10px);
}