@import '@palette';
.root{
    margin: 5px 0 10px 0;
}
.amount{
	margin: 0;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;

	color: $color-gray-1;
	&--units{
		composes: amount;
		font-weight: 500;
		font-size: 12px;
		line-height: 15px;

		color: $color-gray-3;
	}
} 
.original {
    margin: 0;
    display: flex;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    padding-bottom: 5px;
    &--percent{
        composes: original;
        background: rgba(#4ECF81, 0.2);
        padding: 3px 7px;
        border-radius: 10px;

        color: $color-green;
    }
    &--price{
        composes: original;
        margin-left: 10px;

        color: $color-gray-5;
        align-items: center;
        text-decoration: line-through;
    }
}