@import "@palette";

.root {
  background: $color-common-background;
}
.audience {
  composes: globalWidth from global;
  padding-top: 108px;
}
.create-btn {
  height: 34px;
  padding-top: 8px;
  border-radius: 4px;
  color: $color-white;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}