.root {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 30px;
	padding: 6px 10px;
	  border-radius: 4px;

	cursor: pointer;

	&:hover {

		background-color: rgba(204, 204, 204, 0.3);

	}
}

.text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	/* identical to box height, or 120% */

	/* Gray 2 */
	color: #5D697D;
	margin: 0 0 0 8px;
}

.icon {
	path {
		fill: #5D697D;
		width: 16px;
		height: 16px;
	}
}