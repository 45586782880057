@import "@palette";

.root {
	display: inline-flex !important;

	background-color: white;

	border-radius: 10px;

	height: 40px;

	padding: 0 3px;

	path {
		fill: $color-gray-3;
	}
}

.divider {
	width: 2px;
	height: 24px;

	background: $color-gray-5;
	border-radius: 1px;
}

.button {
	height: 26px;

	line-height: 26px;

	border-radius: 8px;

	background: #4212ff1f;
	border: solid 1px #4212ff7a;

	color: #4212FC;
	&:hover{
        color: #ffffff !important;
        background: #4212ffad !important;
	}
}