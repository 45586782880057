.root {
    composes: globalWidth from global;
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    padding: {
        top: 10px;
        bottom: 10px;
    };
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
}
.menu {
    display: flex;
    align-items: center;
    &_logo {
        display: flex;
        align-items: center;
        @media (max-width: 600px) {
            margin-bottom: 20px;    
        }
    }
    span {
        margin-left: 15px;
        font-weight: bold;
        font-size: 20px;
        color: #2a2a31;
        line-height: 20px;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
}
.item {
    margin-right: 30px;
    text-decoration: none;
    @media (max-width: 600px) {
        margin: 0 0 20px;
    }
}

.common { 
    font-size: 12px;
    color: #888;
}

.content { 
    display: flex;
    align-items: flex-end;
    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    } 
}

.footer {
    background: #fff;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0px -1px 0px #E5E5E1;
    z-index: 1000;
    &--fixed {
        width: 100%;
        z-index: 1;
    }
    &--bottom {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        z-index: 1;
        .common { 
            color: #fff;
        }
    }
}