.root {
    composes: card from global;
    position: relative;
    padding: 15px 20px 50px;
    display: flex;
    flex-direction: column;
    height: 340px;
    @media (max-width: 600px) {
        padding-bottom: 50px;
    }
    &_placeholder {
        filter: blur(3px);
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}

.chart {
    flex-grow: 1;
    margin: -20px 0 0 -10px;
}

.value {
    font-size: 30px;
    color: #2a2a31;
    font-weight: 600;
    margin: 0 0 14px;
    display: flex;
    align-items: center;
}

.grid {
    border-bottom: 2px solid #f1f1ee;
    border-left: 2px solid #f1f1ee;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    &--layout {
        flex-grow: 1;
        display: flex;
        position: relative;
        height: 100%;
        .grid {
            filter: blur(3px);
        }
    }
    &--placeholder {
        width: 90%;
        height: 60%;
        border-radius: 0 0 100% 0;
        border-bottom: 3px solid #f1f1ee;
        border-right: 3px solid #f1f1ee;
    }
}

.dif {
    font-size: 18px;
    line-height: 23px;
    color: #fff;
    padding: 2px 8px;
    border-radius: 6px;
    margin-left: 10px;
    font-weight: normal;

    transition: filter 1s;

    &--hidden {
        composes: dif;
        filter: blur(10px);
    }
    &--default {
        composes: dif;
        filter: blur(0px);
    }
}

.details-text {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.blur {
    transition: filter 1s;

    flex-grow: 1;

    &--active {
        composes: blur;
        filter: blur(10px);
    }
    &--default {
        composes: blur;
        filter: blur(0px);
    }
}
