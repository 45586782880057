@import "@palette";

.selector {
    width: 100%;

    &--block {
        background-color: $color-gray-6;
        pointer-events: none;
    }
}

.popper {
    z-index: 5;

    padding: 6px;

    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    &--disabled {
        pointer-events: none;
        background-color: #F2F4F7;
    }

    &--slider {
        padding: 12px 16px;
    }
}

.label {
    padding: 0px 6px;
    background: #F5F6F8;
    border-radius: 4px;

    margin-right: 6px;
}

.icon {
    transition: transform .3s;

    path {
        fill: $color-gray-4;
    }

    &--opened {
        composes: icon;
        transform: rotate(180deg)
    }

    &--closed {
        composes: icon;
        transform: rotate(0deg)
    }
}