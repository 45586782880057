.header {
  position: sticky;
  top: 0;
  z-index: 4;
  display: grid;
  background: #F8F9FB;
  border-radius: 4px;
  height: 60px;
  align-items: center;
  width: fit-content;
  padding: 20px 16px;
}

.menu {
  composes: header;
  display: flex;
  align-items: center;
  gap: 20px;
}

.menu-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 10px;
  height: 28px;
  border-radius: 4px;
  max-width: fit-content;
}

.menu-button:hover {
  background: rgba(214, 219, 230, 0.3);
}

.itemWithTooltip {
  display: flex;
  align-items: flex-start;

  gap: 6px;
}