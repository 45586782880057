@import "@palette";

.icon {

    transition: transform .3s;

    path {
        fill: $color-violet-1;
    }

    &--openned {
        composes: icon;
        transform: rotate(180deg);
    }

    &--closed {
        composes: icon;
        transform: rotate(0deg);
    }
}

.root {
    cursor: pointer;
    user-select: none;
}

.list {
    padding: 4px;

    margin: 0;

    list-style: none;

    width: 220px;

    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    li {
        padding: 6px 10px;

        border-radius: 4px;

        cursor: pointer;

        transition: background .3s;

        &:hover {
            background-color: $color-gray-8;
        }
    }
}