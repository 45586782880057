.tabs {
  width: 100%;
  gap: 50px;
  border-bottom: 1px solid #E6EAF0;
  margin-bottom: 20px;
}
.tab {
  padding-bottom: 10px;
  transform: translateY(1px);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border-bottom: 2px solid transparent;

  & span {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #323841;
  }
  & svg {
    margin-right: 10px;
    width: 16px;
    height: 16px;
    & path {
      fill: #323841;
    }
  }
}

.tab.active {
  border-bottom: 2px solid #6071ff;

  & span {
    color: #6071ff;
  }

  & path {
    fill: #6071ff;
  }
}