$color-white: #fff;
$color-white-hover: #ECECEC;

$color-primary: #2a2a31;
$color-secondary: #8F8F8F;
$color-new-primary: #3D3F42;

$color-gray-light: #F1F1EE;
$color-gray-light-hover: #d3d3d3;

$color-gray-new: #ADADAD;
$color-gray-new-2: #76849B;
$color-gray-2: #56595E;
$color-red: #FF6161;

$color-link: #6172FF;
$color-link-hover: #525EC2;

$color-light: #E5E5E1;

$color-green: #27AE60;
$color-light-green:#4ECF81;

$color-yellow: #ffda54;
$color-yellow-hover: #F5C208;
$color-yellow-light: #FFDA54;

$color-blue: #4A29CF;

$color-audience-background: #393870;

:export {
    red: $color-red;
    primary: $color-primary;
    newPrimary: $color-new-primary;
    secondary: $color-secondary;
    gray: $color-gray-new;
    gray-new: $color-gray-new-2;
    white: $color-white;
    link: $color-link;
    green: $color-green;
    yellow: $color-yellow;
    gray2: $color-gray-2;
    lightGreen: $color-light-green;
}