@import '@palette';

.range-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  height: 100%;
}

.options {
  display: flex;
  border: 1px solid #AEBACC;
  border-radius: 4px;
  background-color: $color-white;
  padding: 4px;
  gap: 4px;
  height: 100%;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #F2F4F7;
  border-radius: 4px;
  padding: 10px 16px;
  cursor: pointer;
  transition: background-color 0.2s, border-color 0.2s;
  color: $color-gray-new-4;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
  border: none;
  white-space: nowrap;

  &:hover {
    background-color: $color-violet-1;
    color: $color-white;
  }
}

.active {
  background-color: $color-violet-1;
  color: $color-white;
}
