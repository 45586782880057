.table {
    background-color: white;
    border-radius: 10px;
    padding: 12px;
    overflow: hidden;

    &-header {
        position: sticky;
        top: 0;
        border-radius: 4px;
        background: var(--Gray-7, #F2F4F7);
        height: 48px;
        width: 100%;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
    }

    &-item {
        border-radius: 4px;
        height: 48px;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
    }
}

.loader {
    path {
        fill: red;
    }

    div {
        div {
            border-color: #929EB0 transparent transparent transparent;
        }
    }
}
.dividerHoriz {
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}