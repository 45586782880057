.head {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600 !important;
	font-size: 30px !important;
	line-height: 36px !important;
	/* identical to box height, or 120% */

	color: #393F49 !important;
	padding: 108px 0 0 14px;
	margin: 0;
}

.main {
	margin: 30px 10px 0 10px;

	@media (max-width: 600px) {
		margin: 20px 0 0 0;
	}
}