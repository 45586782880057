@import '@polette';
@import '@palette';

.root {
    width: 360px;
    height: 100%;

    border-radius: 12px;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);

    background: #fff;

    padding: 24px 20px 5px 20px;

    &--hide {
        filter: blur(2px);
    }

    @media (max-width: 600px) {
        width: 100%;
    }
}
.current {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3d3f42;
    padding-left: 10px;
    margin: 0;
}
.status {
    background: $color-green;
    border-radius: 4px;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #fff;
    padding: 1px 8px;
    &--inactive {
        composes: status;
        background: #f58990;
    }
}

.plain_data {
    font-size: 18px;
    color: #888;
    margin-bottom: 20px;
    span {
        color: #2a2a31;
        font-weight: bold;
    }
}

.unsub {
    box-shadow: none;
    margin-top: 10px;
    margin-bottom: 5px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    color: $color-gray-4;
    border: none;
}
.sub {
    width: 328px;
    height: 42px;
    left: 136px;
    top: 644px;
    margin-bottom: 5px;
    background-color: $color-violet-1;
    border-radius: 4px;
    transition: background-color 0.3s;
    &:hover {
        background-color: rgba($color-violet-1, 0.1);
    }
}

.button {
    background-color: transparent;
    border-style: none;
    padding: {
        left: 3px;
        top: 5px;
        right: 3px;
        bottom: 5px;
    }
    &--title {
        color: $color-violet-1;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
    }
}
.tariff {
    display: flex;
    background-color: #ebecfd;
    align-items: center;
    border-radius: 30px;
    padding: 20px;
    &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 80px;
        height: 80px;
        margin-right: 20px;

        background: #8c9cf2;
        border-radius: 30px;
    }
    &--title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #3d3f42;
    }
    &--text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 20px;
        color: $color-gray-4;
        padding-left: 5px;
        &--value {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 24px;
            color: #3d3f42;
            padding-right: 5px;
        }
    }
    &--recurring {
        background: #fafafa;
        border-radius: 4px;
        padding-top: 8px;
        padding-bottom: 8px;
    }
    &--price {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #3d3f42;
        margin-top: 5px;
        margin-bottom: 10px;
        &--for {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            color: $color-gray-3;
        }
    }
    &--note {
        width: 328px;
        // height: 112px;
        background-color: rgba(#f58990, 0.1);
        // opacity: 0.1;
        border-radius: 4px;
        &--icon {
            width: 100px;
            margin-top: 10px;
            path {
                fill: $color-red-1;
            }
        }
        &--text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 20px;
            padding-right: 5px;
            color: $color-red-1;
        }
    }
}
