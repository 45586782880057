.switch {
	position: relative;
	width: 50px;
	height: 24px;
	padding: 0 5px;
	/* Gray 4 */
	background: #B7C3D6;
	border-radius: 20px;
	margin: 0 20px;
	transition: all 0.5s;

	&--checked {
		composes: switch;
		background-color: #6071FF;
		transition: all 0.5s;
	}

	&--slider {
		position: relative;
		height: 16px;
		width: 16px;
		background: #FFFFFF;
		border-radius: 16px;
		left: 0;
		transition: all 0.5s;

		&--checked {
			composes: switch--slider;
			left: 24px;
			transition: all 0.5s;
		}
	}
}