.switch{
    width: 100%;
    justify-content: flex-end;
    @media (max-width: 600px) {
        justify-content: center;
    }
    &--text{
        margin: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        /* Gray 2 */
        color: #76849B;

        &-active{
            composes: switch--text;
            color: #393F49;
        }
    }

    &--discount{
        height: 24px;

        /* Green 2 */
        background: #70D9A0;
        border-radius: 4px;
        padding: 0 5px;
        p {
            margin: 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            /* White */
            color: #FFFFFF;
        }
    }
}