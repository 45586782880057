@import "@palette";

.dots {
  cursor: pointer;
}

.popup {
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(57, 63, 73, 0.3);
  border-radius: 4px;
  padding: 6px;
  width: 220px;

  p {
    margin: 0;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    /* Gray 1 */
    color: #323841;
    padding-left: 10px;

  }

  div {
    width: 100%;
    height: 38px;
    padding: 10px;

    border-radius: 4px;

    &:hover {
      background: #F5F6F8;
    }

    cursor: pointer;
  }
}

.btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  border-radius: 4px;
  margin-left: 30px;
  ;
}

.btn:hover {
  background-color: rgba(204, 204, 204, 0.3);
}

.add-btn {
  &:hover {
    background: #F5F6F8;
  }

  cursor: pointer;
  border-radius: 4px;
  padding: 10px;

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    /* Gray 1 */
    color: #323841;
  }

}

.icon {
  width: 15px;
  height: 15px;
  path {
    fill: #5C5C5C;
  }
}