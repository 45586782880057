.root {
	composes: card from global;
	padding: 15px 20px;
}
.option {
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&_head {
		font-size: 18px;
		color: #2a2a31;
		margin-left: 16px;
	}

	&_icon {
		display: flex;
		align-items: center;
	}
	&_value {
		font-size: 18px;
		color: #888;
	}
}