@import "@palette";

.react-calendar{
	font-family: Montserrat;
    height: 230px;
	border: none;
	border-radius: 4px;
	padding:0 13px 15px;
	&__navigation{
		height: 38px;
		margin: 0;
		button {
			min-width: fit-content !important;
		}
		&__prev2-button{
			display: none
		}
		&__next2-button{
			display: none
		}
		&__next-button{
			background-color: transparent !important;
		}
		&__prev-button{
			background-color: transparent !important;
		}
		&__label{
			background-color: transparent !important;
			&__labelText{
				line-height: 16px;
				overflow: hidden;
				white-space: nowrap;
			}
		}
	}
	&__month-view__days{
		align-content: space-between;
		&__day{
			width: 26px;
			height: 26px;
			padding: 0;
		}
	}
	&__month-view__weekdays{
		font-family: Montserrat !important;
		font-size: 10px;
		text-transform: none;
		padding: none;
		text-decoration: none !important;
	}
	&__year-view__months__month{
		height: 47px;
		padding: 0 !important;
	}
	&__decade-view__years__year{
		height: 47px;
		padding: 0 !important;
	}
	&__century-view__decades__decade{
		height: 47px;
		padding: 0 !important;
	}
	&__tile{
		&--active{
			background-color: $color-violet-4 ;
			color: black;

		};
		&--active:enabled:hover{
			background: $color-violet-4  !important;
			border: 2px solid $color-violet-1;
			border-radius: 4px;
		}
		&--rangeEnd{
			background-color: $color-violet-1 !important;
			border-radius: 4px;
		}
		&--rangeStart{
			background-color: $color-violet-1 !important;
			border-radius: 4px;
		}
	}
}
.react-calendar__tile--now{
	background-color: transparent;
}

.react-calendar__tile--now:enabled:hover{
	background-color: $color-gray-6 !important;
}

.react-calendar__navigation__label {
	margin: 0 25px !important
}