@import "@palette";

.root {
  background-color: $color-white;
  border-radius: 10px;
  padding: 30px 30px 0 30px;
  height: 100%;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    background-color: #E6EAF0;
    bottom: 91px;
    left: 0;
  }
}

.step-container {
  border-radius: 10px;
  padding: 24px;
  background-color: $color-violet-4;
  margin-bottom: 30px;

  .header {
    width: 100%;
  }

  .content {
    width: 100%;
    display: flex;
    gap: 20px;

    .ai-block {
      width: 60%;

      .icon {
        margin-bottom: 16px;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        background-color: #ACB5FF;
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
        border-radius: 50%;
        color: $color-white;

        &:before {
          content: "New";
          position: absolute;
          top: -20px;
          right: -30px;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          color: #6071FF;
        }

      }

      p {
        line-height: 16px;
        text-align: center;
      }

      .field-wrapper {
        position: relative;
        width: 100%;
      }

      .textarea {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        padding: 10px 28px 10px 16px;
        border: none;
        resize: none;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        color: $color-gray-new-1;

        &:focus-visible {
          outline: none;
        }
      }

      .magic-icon {
        position: absolute;
        bottom: 10px;
        right: 20px;
      }
    }

    .description-block {
      width: 40%;
      padding: 24px;
      background-color: $color-white;
      border-radius: 10px;

      .description {
        border-radius: 10px;
        padding: 16px;
        background-color: $color-gray-new-7;
        margin-bottom: 16px;
      }

      .filters-block {
        border-top: 1px solid #E6EAF0;

        .filter {
          border-bottom: 1px solid #E6EAF0;
          padding: 10px 0;
          &:last-child {
            border-bottom: none;
            padding-bottom: 0;
          }
        }
        .chip-container {
          gap: 10px;
          flex-wrap: wrap;
        }

        .filter-item {
          border-radius: 4px;
          padding: 7px 10px;
          background-color: $color-gray-new-3;
          font-size: 12px;
          font-style: normal;
          font-weight: 600;
          color: $color-white;
        }
      }
    }
  }
}

.footer {
  padding: 25px 0;
}

.arrow-icon {
  margin-right: 6px;

  path {
    fill: $color-violet-1;
  }
}

.btn {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  color: $color-white;
  background-color: $color-violet-1;

  &:disabled {
    background-color: $color-gray-new-3;
    color: #D6DBE6;
  }
}

.step {
  background-color: #6071FF;
  color: $color-white;
  border-radius: 10px;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  padding: 6px 10px;
}


.wrapper {
  background-color: $color-white;
  border-radius: 10px;
}

.container {
  padding: 30px;
  display: flex;
  gap: 20px;
}

.block-form {
  padding: 0 24px 24px 24px;
  width: 50%;
  gap: 24px;

  & label {
    color: $color-gray-new-4;
    font-size: 12px;
    font-weight: 600;
  }
}

.select-wrapper {
  position: relative;
}

.input {
  border-color: $color-gray-11 !important;
}