@import "@palette";

.root {
  @media (min-width: 601px) {
    justify-content: space-between;
  }
}

.TopInfo {
  margin-bottom: 10px;

  &--text {
    margin-left: 8px;
    margin-right: 20px;
    font-size: 12px;
    line-height: 20px;
  }

  &--icon {
    &-active {
      path {
        fill: $color-violet-1;
      }

      animation: rotation 8s infinite linear;
    }
  }

}

.updDate {
  @media (min-width: 601px) {
    width: 100%;
    justify-content: right;
  }
}

.demo-blur {
  background: $color-gray-6;
  border-radius: 4px;
  margin-left: 8px;
  margin-right: 20px;
  height: 12px;
  align-self: center;

  &--email {
    composes: demo-blur;
    width: 164px;
  }

  &--phone {
    composes: demo-blur;
    width: 97px;
  }
}

@media (max-width: 600px) {
  .TopInfo {
    &--text {
      font-size: 10px;
      line-height: 14px;
    }

    &--icon {
      height: 12px;

      &-active {
        path {
          fill: $color-violet-1;
        }

        animation: rotation 8s infinite linear;
      }
    }
  }
}


@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}