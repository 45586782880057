.card {
  display: flex;
  align-items: center;
  background: #fff;
  padding: 16px;
}

.header {
  display: flex;
  align-items: center;

  .innInputWrapper {
    border: 1px solid #929EB0;
    padding: 4px;
    display: flex;
    border-radius: 4px;

    & input {
      border: none;
      outline: none;
      color: #323841;
      font-size: 14px;

      &::placeholder {
        color: #929EB0;
        font-size: 14px;
      }
    }
  }

  .saveButton {
    width: 22px;
    height: 22px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
  }

  .char {
    width: 60px;
    height: 60px;
    background: #EFF1FF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    margin-right: 12px;
    color: #6071FF;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
  }

  .title {
    color: #323841;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
  }

  .subtitle {
    font-size: 12px;
    color: #929EB0;
  }

  .action {
    margin-left: auto;
    background: none;
    border: none;
    font-size: 24px;
    color: #929EB0;
    cursor: pointer;
  }
}

.stats {
  display: flex;
  gap: 60px;

  .stat {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .value {
      font-size: 24px;
      font-weight: 700;
      color: #323841;
    }

    .label {
      font-size: 14px;
      color: #929EB0;
      display: flex;
      align-items: center;

      .tooltip {
        margin-left: 6px;
        font-size: 12px;
        background-color: #E6EAF0;
        color: #5D697D;
        font-weight: 600;
        min-height: 16px;
        min-width: 16px;
        width: 16px;
        height: 16px;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        &:hover {
          background-color: #5D697D;
          color: #fff;
        }
      }
    }
  }
}
