.btn {
	margin-right: 6px;
}

.big_line {
	width: 17px;
	border-bottom: 2px solid #fff;
	margin-bottom: 6px;
}

.small_line {
	width: 12px;
	border-bottom: 2px solid #fff;
	float: right;
}

.popup {
	padding: 20px;
	background: #302f35;
	border-radius: 10px;
	box-shadow: 0 4px 20px rgba(0, 0, 0, .4);
	a {
		display: block;
		text-decoration: none;
		color: #fff;
		font-size: 14px;
	}
}