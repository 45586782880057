.hider {
  width: 30px;
  height: 10px;
  background: linear-gradient(90deg, #e8ebf0 13.7%, #fbfcfd 34.28%, #e8ebf0 54.29%);
  border-radius: 4px;
  animation: gradient 3s ease infinite;
  background-size: 400% 400%;
  transition: width 0.3s;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
