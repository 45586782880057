@import "@palette";
.root {
    display: flex;
    justify-content: space-between;
    
    // background: #fff;
    // box-shadow: 0 0 10px rgba(0, 0, 0, 0.03);
    // border-radius: 12px;
    // padding: 20px;
    // margin: 20px 0;
    position: relative;
    flex-direction: column;
    @media (max-width: 600px) {
        padding: 12px 16px;
            &--main{
            flex-direction: column;
        }
    }

}

.ava_name {
    display: flex;
    align-items: flex-start;
    position: relative;
    @media (max-width: 600px) {
        align-items: flex-start;
    }
}

.name {
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
        text-decoration: none;
        display: block;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: $color-violet-1;
        @media (max-width: 600px) {
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
        }
    }
}

.fullname {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: $color-gray-1;
    @media (max-width: 600px) {
        font-size: 14px;
        line-height: 17px;
    }
}

.icon {
    min-width: 20px;
    height: 20px;
    background-size: cover;
    &--default {
        composes: icon;
        // margin-top: 12px;
        margin-left: 10px;
    }
    &--xs {
        composes: icon;
        position: absolute;
        top: 10px;
        right: 10px;
    }
}

.metrics {
    display: flex;
    padding-top: 11px;
    justify-content: space-between;
    margin-left: 100px;

    @media (max-width: 1000px) {
        margin-left: 0;
        width: 100%;
    }

    @media (max-width: 600px) {
        flex-wrap: wrap;
        padding-top: 0;
    }
}

.inform {
    width: 110px;
    font-size: 14px;
    color: #888;
    margin-left: 20px;
    padding-right: 5px;
    h3 {
        font-size: 30px;
        color: #2a2a31;
        font-weight: 600;
        margin: 0 0 6px 0;
    }
    @media (max-width: 600px) {
        width: 50%;
        margin-top: 30px;
        margin-left: 0px;
        h3 {
            font-size: 30px;
        }
    }
}

// .categories {
//     // position: absolute;
//     // left: 120px;
//     // bottom: 0;
//     display: flex;
//     flex-wrap: nowrap;
//     margin-top: 11px;
//     &--xs {
//         display: flex;
//         flex-wrap: wrap;
//         margin-top: 20px;
//     }
//     &--item {
//         padding: 6px 12px;
//         border-radius: 10px;
//         background: #f5f5f5;
//         font-size: 12px;
//         line-height: 15px;
//         color: #888;
//         margin-right: 10px;
//     }
// }

.pair {
    display: flex;
    width: 100%;
    .inform {
        width: 50%;
    }
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-height: 90px;
    // margin-left: 20px;
    @media (max-width: 600px) {
        max-height: 64px;
    }
}