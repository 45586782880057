.filterButton {
  padding: 6px 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
  border-radius: 4px;

  background-color: #D6DBE6;
  cursor: pointer;

  &--default {
    composes: filterButton;
  }

  &--active {
    composes: filterButton;
    background-color: #929EB0;
  }
}

.text {
  &--active {
    color: white;
  }
  &--default {
    color: #5D697D;
  }
}

.icon {
  width: 12px;
  height: 12px;

  &--active {
    composes: icon;
    path {
      fill: white;
    }
  }
  &--default {
    composes: icon;
    path {
      fill: #5D697D;
    }
  }
}