@import '@palette';

.root {
    position: fixed;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    background-color: rgba(#343437, 0.8);

    overflow-y: auto;

    padding-top: 5%;
    padding-bottom: 30px;

    z-index: 2000;
}

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: $color-gray-1;
    margin: 0 0 30px 0;
}
.modal {
    width: 1200px;

    background-color: #fff;

    border-radius: 10px;

    padding: 30px 40px;
}

.text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: right;
	margin: -16px 0 0 0;
    height: 0px;
    cursor: pointer;
}

.icon {
    &--container {
        display: flex;
        justify-content: flex-end;
        padding-bottom: 5px;
    }

    &--close {
        path {
            fill: $color-white;
        }
        cursor: pointer;
        width: 30px;
        height: 30px;
    }
}

@media (max-width: 600px) {
    .text {
        display: none;
    }
}
