@import '@palette';

.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    color: $color-gray-1;
}