@import "@palette";

.root {
    min-height: 100vh;

    padding: 0 0 35px;

    position: relative;
}

.root-no-padding {
    min-height: 100vh;

    position: relative;
}

.children {
    margin: 0 30px 0 30px;
    background-color: $color-common-background;
}

@media (max-width: 1650px) {
    .children {
        margin: 0 30px 0 230px;
    }
}

@media (max-width: 1279px) {
    .children {
        margin: 0 30px 0 30px;
    }
}

@media (max-width: 743px) {
    .children {
        margin: 0;
        background-color: $color-common-background;
        padding-top: 50px;
    }

}