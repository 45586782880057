@import "@palette";

.accounts {
    display: grid;
    margin-top: 20px;
    &--vertical {
        composes: accounts;
        grid-template-columns: repeat(5, 1fr);
        grid-column-gap: 25px;
        grid-row-gap: 20px;
    }
    &--horizontal {
        composes: accounts;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 20px;
    }
}

@media (max-width: 600px) {
    .accounts {
            overflow-x: scroll;
            grid-column-gap: 8px;
    }
}
