@import "@palette";

.selector {
    width: calc(50% - 8px);

    &--block {
        background-color: $color-gray-6;
        pointer-events: none;
    }
}



.calendar--form{
    background-color: white;
    width: 224px;
    height: 260px;
    border-radius: 4px;
    border: 1px solid $color-gray-6;
}

.button{
    height: 16px;
    width: 51px;
    font-size: 10px;
    color: $color-gray-4 !important;
    padding: 0 12px;
}