@import "@palette";

$breakpoint-lg: 1440px;
$breakpoint-md: 768px;
$breakpoint-sm: 500px;

.card {
  background-color: $color-white;
  border: 1px solid #D6DBE6;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  max-width: 338px;
  min-height: 400px;
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }

  h3 {
    font-size: 18px;
    font-weight: 600;
    color: #323841;
    margin-bottom: 8px;
  }

  p {
    font-size: 14px;
    color: #929EB0;
    margin-bottom: 20px;
    line-height: 18px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;

    button {
      width: 100%;
      background-color: $color-violet-1;
      color: $color-white;
      font-size: 14px;
      font-weight: 600;
    }
  }

  .icon {
    margin-bottom: 20px;

    img {
      width: 50px;
    }
  }

  .icons {
    max-width: 76px;
    flex-wrap: wrap;
    gap: 8px;
  }

  .icon-soc {
    width: 20px;
    height: 20px;
  }

  .button {
    background-color: #4f63d2;
    color: #ffffff;
    text-decoration: none;
    padding: 10px 25px;
    border-radius: 8px;
    display: inline-block;
    transition: background-color 0.3s;

    &:hover {
      background-color: #3b4fba;
    }
  }

  .search {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #A3ADFF;
    align-items: center;
    justify-content: center;

    & svg {
      width: 30px;
      height: 30px;

      & path {
        fill: #ffffff;
      }
    }
  }

  @media (max-width: $breakpoint-lg) {
    flex: 1 1 calc(50% - 10px);
    max-width: 100%;
    min-height: 211px;

    .content {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;

      button {
        max-width: 100px;
      }

      p {
        margin: 0;
      }
    }
  }

  @media (max-width: $breakpoint-md) {
    min-height: 365px;

    .content {
      flex-direction: column;
      align-items: flex-end;

      button, a {
        max-width: 100%;
        width: 100%;
      }

      p {
        margin: 0;
      }
    }
  }

  @media (max-width: $breakpoint-sm) {
    flex: 1 1 100%;
    max-width: 100%;
    min-height: 280px;
  }
}
