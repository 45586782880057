@import '@palette';

.emptyAllChats {
  background-color: #F2F4F7;
  border-radius: 10px;

  padding: 24px 20px 36px;
}

li {
  color: $color-gray-3; 
}

ul {
  margin: 0;
}

.link {
  text-decoration: underline;
}