@import "@palette";

.pagination-button {
  padding: 14px 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: $color-violet-1;
  background-color: #DFE2FF;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  span {
    color: $color-violet-1;
    margin-left: 8px;
    font-size: 23px;
    line-height: normal;
  }
}