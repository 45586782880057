.analyticsContainer {
  padding: 30px;
  background: #fff;
  border-radius: 0 10px 10px 10px;
}

.platforms {
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
}

.platformButton {
  padding: 8px 12px;
  border: none;
  background: #f0f0f0;
  border-radius: 4px;
  cursor: pointer;
  &.active {
    background: #007bff;
    color: #fff;
  }
}

.metrics {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 24px;

  div {
    text-align: center;

    h2 {
      margin: 0;
      font-size: 24px;
      color: #333;
    }

    p {
      margin: 4px 0 0;
      color: #777;
    }
  }
}
