@import "@/components/color/palette.module.scss";

$width-mobile: 768px;

.root {
    margin: 0;
    color: $color-gray-1;
    font-family: RFDewiExpanded;
}

h1.root {
    font-size: 56px;
    line-height: 67px;
}
h2.root {
    font-size: 38px;
    line-height: 46px;
}
h3.root {
    font-size: 30px;
    line-height: 36px;
}
h4.root {
    font-size: 24px;
    line-height: 29px;
}
h5.root {
    font-size: 20px;
    line-height: 24px;
}
h6.root {
    font-size: 16px;
    line-height: 19px;
}

@font-face {
    font-family: RFDewiExpanded;
    src: url("./RFDewiExpanded-Bold.ttf");
}

@media screen and (max-width: $width-mobile) {
    h3.root {
        font-size: 24px;
        line-height: 29px;
    }
}
