@import "@palette";

.chip {
  background-color: #F2F4F7;
  color: $color-gray-new-2;

  border-radius: 10px;

  line-height: 12px !important;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  padding: 6px 10px;

  &:not(:last-child) {
      margin-right: 10px;
  }
}

.topics {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: flex-start;
}