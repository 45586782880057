@import "@polette";

.root {
	transition: background .3s;
	font-weight: 600;
	border: none;
	text-align: center;
	&--active {
		composes: root;	
		background: $color-yellow;
		color: $color-primary;
		cursor: pointer;
		&:not(.root--disabled):hover {
			background: $color-yellow-hover;
		}
	}
	&--disabled {
		cursor: default !important;
		opacity: .7;
	}
	&--optional {
		composes: root;
		cursor: pointer;
		background: $color-gray-light;
		color: $color-secondary;
		&:not(.root--disabled):hover {
			background: $color-gray-light-hover;
		}
	}
	&--white {
		composes: root;
		cursor: pointer;
		background: $color-white;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
		color: $color-secondary;
		&:not(.root--disabled):hover {
			background: $color-white-hover;
		}
	}

	&--blue {
		composes: root;
		cursor: pointer;
		background: $color-link;
		box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
		color: $color-white;
		&:not(.root--disabled):hover {
			background: $color-link-hover;
		}
	}

}

.small {
	padding: 6px 25px;
	font-size: 14px;
	border-radius: 8px;
}

.medium {
	padding: 11px 25px;
	font-size: 14px;
	border-radius: 12px;
}

.huge {
	padding: 15px 25px;
	font-size: 18px;
	line-height: 23px;
	border-radius: 12px;
}