@import '@palette';

$active-link: #6071ff;

.btn {
    width: 40px;
    height: 40px;
    padding: 0 4px;
    border-radius: 10px !important;
    background-color: #fff;

    &--close {
        height: 10vh;
    }
}

.closeIcon {
    path {
        fill: black;
    }
}

.navbar {
    flex-direction: column;
    justify-content: center;
    background-color: $color-white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    height: 100vh;
}

.link {
    position: relative;

    font-weight: 600;
    color: black;
    font-size: 12px;
    margin-right: 79px;
    margin-left: 33px;
    line-height: 20px;

    &:not(:first-child) {
        margin-top: 5%;
    }

    &--active {
        color: $active-link;
        path {
            fill: $active-link;
        }
    }

    &--text {
        margin-left: 10px;
    }

    &--secondary {
        padding-top: 5%;
        padding-bottom: 5%;
        margin-left: 33px;
    }
}

.divider {
    height: 1px;
    background-color: $color-gray-6;
    margin-top: 10px;
}

.pages {
    &--main {
        flex-direction: column;
        height: 40vh;
    }

    &--secondary {
        flex-direction: column;
        height: 40vh;
    }
}

.icon {
    path {
        fill: $color-gray-3;
    }

    &--secondary {
        path {
            fill: $color-gray-3;
        }
    }
}
