@import "@palette";

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000vh;
    padding: 30px;
    z-index: 1600;
    
    background: rgba($color: #000000, $alpha: 0.2);
}

.root{
    /* White */
    background: #FFFFFF;
    border-radius: 10px;   
    width: 500px;
    min-height: 720px;

    padding: 30px 60px;

    @media (max-width: 600px){     
        width: 100vw;
        padding: 20px;
        min-height: unset;
        height: 90vh;
        margin-top: 10vh;
        overflow: scroll;
    }
}
.header{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;

    color: #323841;
    margin: 30px 0 0 0;

    @media (max-width: 600px){     
        font-size: 20px;
        line-height: 24px;
    }
}
.label{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #323841;
    margin: 20px 0 6px 0;

    @media (max-width: 600px){     
        font-size: 12px;
        line-height: 16px;
    }
}

.switch{
    &--circle{
        height: 42px;

        background: #FFFFFF;
        border: 1px solid #AEBACC;
        border-radius: 22px;
        
        padding: 0 18px;
        align-items: center;
        
        &:hover{
            background: #F3F5FF;
            border: 1px solid #AEBACC;
            border-radius: 22px;
            cursor: pointer;
        }

        &-active{
            composes: switch--circle;
            background: #EFF1FF;
            border: 1px solid #6071FF;
            border-radius: 22px;
        }

        &--text{
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;

            color: #323841;

            @media (max-width: 600px){     
                font-size: 14px;
                line-height: 18px;
            }
        }
    }
}

.input-box{
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #AEBACC;
    border-radius: 4px;
    input::placeholder, p {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        color: #929EB0 !important;
    }
    input{
        padding: 1px 2px 1px 12px;
    }
    &--active{
        composes: input-box;
        p{
            font-weight: 500;
            font-size: 14px;
            line-height: 18px;
            color: #323841 !important;
            
        }
    }
}

.button{
    justify-content: center;
    align-items: center;
    padding: 12px 16px 12px 24px;

    height: 42px;

    /* Gray 3 */
    // background: #929EB0;
    border-radius: 4px;
    margin-top: 30px;
}

.bar{
    &--title{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        color: #929EB0;
        margin: 0;
    }

    &--gray{
        margin-top: 6px;
        width: 100%;
        height: 4px;

        background: #dee3eb;
        border-radius: 2px;
    }

    &--blue{
        height: 4px;

        /* Violet */
        background: #6071FF;
        border-radius: 2px;
    }
}

.influencer{
    &--add{
        width: 30px;
        height: 30px;

        /* Gray 7 */
        background: #F2F4F7;
        border-radius: 10px;
        border: none;

        margin: 16px 0 0 10px;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    &--dash{

        width: 12px;
        height: 2px;
        background: #929EB0;
    }
}