.root {
    max-width: 1180px;
    width: 100%;
    min-height: 440px;
    border-radius: 4px;

    background-color: white;

    row-gap: 1px;
    margin-top: 1px;
}

.header {
    height: 60px;
    width: 100%;
    padding: 22px 16px;
    border-radius: 4px;

    background-color: #F8F9FB;

    display: grid;
    align-items: center;

    &-empty {
        composes: header;
        grid-template-columns: 35px 2fr 1fr 1fr 3fr;
    }

    &-campaigns {
        composes: header;
        grid-template-columns: 35px 3fr 1fr 1fr 1fr 1fr;
    }
}

.emptyIcon {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #F2F4F7;

    svg {
        width: 40px;
        height: 40px;

        path {
            fill: #A3ADBE;
        }
    }
}

.campaignItem {
    display: grid;
    align-items: center;
    grid-template-columns: 35px 3fr 1fr 1fr 1fr 1fr;
    padding: 16px 22px;
    background-color: white;

}

.socials{
    column-gap: 6px;
    svg{
        path{
            fill: #5D697D;
        }
    }
}