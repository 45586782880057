@import "@palette";

.root {
    padding: 24px 40px 30px;

    background: $color-white;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    border-radius: 4px;
}

.description {
    width: 170px;
}

.icon {
    margin-right: 30px;
    margin-top: 10px;
}

.title {
    line-height: 29px !important;
}

.info__item {
    &:not(:last-child) {
        margin-right: 100px;
    }
}

.up {
    line-height: 29px;

    padding: 0 8px;
    
    border-radius: 4px;
}