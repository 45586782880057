.color {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;

  &--chosen {
    composes: color;
    border: 1px solid;
  }
}
