@import '@palette';

.root {
  background: $color-common-background;
}
.page {
  padding-top: 62px;
  composes: globalWidth from global;
}
.not-allowed {
  cursor: not-allowed !important;
}