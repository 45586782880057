.root {
	width: 100%;
	height: 100%;

	background: #fff;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	border-radius: 4px;
		
	cursor: pointer;

	padding: 40px 0 38px;

	position: relative;

	&--disabled {
		composes: root;
		.icon {
			rect {
				fill: #e5e5e1
			}
			path {
				fill: #888888;
				opacity: .3;
			}
		}
	}

	&--active {
		composes: root;
		
	}
}

.icons {
	display: flex;
	align-items: center;
	justify-content: center;
	padding-left: 14px;
}

.icon {
	margin-left: -14px;
}

.locker {
	transform: scale(1.5);
	top: 20px !important;
	right: 20px !important;
}