.root {
	width: 25px;
	height: 25px;
	position: relative;
}

.ring {
	border-radius: 50%;
	border: 3px solid #6172FF;
	position: absolute;
	animation: loading 2s linear 0s infinite;
	opacity: 0;
}

@keyframes loading {
	0% {
		opacity: 0;
	}
	25% {
		opacity: 1;
	}
	50% {
		opacity: 0;
	}
	100% {
		opacity: 0;
	}
}