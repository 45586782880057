@import '@palette';

.root {
    min-height: 100vh;
    background: $color-common-background;
    padding-bottom: 40px;
    padding-top: 110px;
}
.title {
    font-size: 30px;
    line-height: 38px;
    font-weight: 600;
    color: $color-gray-1;
    margin: 0;
    font-family: RFDewiExpanded;
    margin-bottom: 20px;
}
.container {
    composes: globalWidth from global;
}
