.root {
  background: #FFFFFF;
  /* Gray 5 */
  border: 2px dashed #D6DBE6;
  border-radius: 4px;

  height: 200px;

  button:hover {
    p {
      color: #FFFFFF !important;
    }
  }

}
.dragging {
  background-color: #f0f8ff;
}

.name {
  margin-top: 16px;
  background: #F2F4F7;
  border-radius: 4px;
  width: 100%;
  padding: 6px 10px;

  p {
    span {
      color: #929EB0 !important;

    }
  }

  svg {
    cursor: pointer;

    path {
      fill: #AEBACC;
    }
  }
}