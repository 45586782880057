.root {
	display: flex;

	display: flex;
	align-items: center;
}

.timer {
	color: #2a2a31;
	text-align: center;
	font-weight: 600;
	
	width: 40px;
	
	background-color: #D5D4D4;

	border-radius: 4px;

	opacity: .7;
}

.resend {
	font-size: 14px;
	line-height: 20px;
	color: #6071FF;

	text-decoration: underline;
	margin-right: 10px;
	&--disabled {
		composes: resend;
		opacity: .5;

		cursor: default;
	}
	&--active {
		composes: resend;
		cursor: pointer;
	}
}