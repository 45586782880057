.icon {
    cursor: pointer;

    path {
        fill: rgb(143, 143, 143);
    }

    // height: 16px;

    &-active {
        composes: icon;

        path {
            fill: #6071FF;
        }
    }

}