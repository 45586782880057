.root {
	display: flex;
	align-items: center;
	margin-left: 30px;
	span {
		font-size: 12px;
		line-height: 15px;
		color: #888888;
	}
	@media (max-width: 600px){
		margin: 0 30px 0 0;
	}
}

.sq {
	width: 20px;
	height: 20px;
	border-radius: 6px;
	margin-right: 6px;
}