@import "@palette";

.root {
	width: 100%;
	height: 100%;

	background: #FFFFFF;
	box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);
	border-radius: 4px;

	padding: 20px 24px 24px;
	
	&--default {
		composes: root;
		cursor: default !important;
	}

	&--pointer {
		composes: root;
		cursor: pointer !important;
	}
}

.bloggers {
	display: flex;
	padding-left: 14px;
	margin-bottom: 12px;
}

.names {
	font-size: 14px;
	line-height: 18px;
	color: #888888;
	margin-bottom: 20px;
	height: 36px;
}

.metric {
	font-size: 12px;
	line-height: 15px;
	font-weight: normal;
}

.date {
	composes: metric;
	color: #878CB9;
}

.counter {
	composes: metric;
	color: #6172FF;
}

.process {
	padding: 3px 8px;

	margin-left: 10px;

	background: #27AE60;

	border-radius: 10px;

	font-size: 12px;
	line-height: 15px;
	color: #FFFFFF;
}

.absolute {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 4001;
}

.bg {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;

	z-index: 4000;

	background-color: rgba($color-gray-1, .3);
}

.demo_mark {
	line-height: 15px;

	border-radius: 4px;

	padding: 0 6px;

	background-color: $color-violet-1;
}