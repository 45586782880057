@import "@palette";

.popup {
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(57, 63, 73, 0.3);
    border-radius: 4px;
    padding: 6px;
    width: 220px;

    p {
        margin: 0;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        /* Gray 1 */
        color: #323841;
        padding-left: 10px;

    }

    div {
        width: 100%;
        height: 38px;
        padding: 10px;

        border-radius: 4px;

        &:hover {
            background: #F5F6F8;
        }

        cursor: pointer;
    }
}

.var {
    background: #E6EAF0;
    border-radius: 2px;
    display: flex;
    align-items: center;
    padding: 0 8px;
    height: 24px;
    cursor: pointer;
    margin-right: 8px;

    &--text{
        font-weight: 600;
        font-size: 11px;
        line-height: 16px;
    
        color: #5D697D;
        margin-left: 6px;
    }
}