@import '@palette';

.helper {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: fit-content;
  background-color: #D5DBFC;
  color: $color-violet-1;
  padding: 7px;
  font-size: 12px;
  border-radius: 6px;

  @media (max-width: 743px) {
    font-size: 8px;
  }
}