.mobilePopup {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 30;
  max-height: 80%;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.header {}

.content {
  position: relative;
}

.hider {
  @media (max-width: 743px) {
    background: rgba(9, 16, 31, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 6;
  }
}

.divider {
  width: 743px;
  margin-left: -40px;
  border: none;
  border-top: 2px solid #F2F4F7;
}