@import "@palette";
.wrap{
    background: #a3adff;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    @media (max-width: $mobile-break){
		width: 54px;
        height: 54px;
	}
}

.icon{
    path{
        fill: white;
    }
}