@import "@palette";

.root {
    background-image: url(./AdsBackground.svg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    background-color: $color-white;
    border-radius: 4px;

}

.container {
    max-width: 270px;
    margin-top: 233px;
    padding: 0px 30px 30px 30px;
}

.textContainer{
    span{
        white-space: nowrap;
        white-space: pre;

    }
}