@import "@palette";
.btn{
  width: 200px;
  @media (max-width: $mobile-break){
    flex-grow: 1;
  }
  &-text{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    text-align: center;

    /* White */
    color: #FFFFFF;
  }
}

.root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1000vh;
    padding: 30px;
    z-index: 110;
  
    background: rgba($color: #000000, $alpha: 0.2);
  }
  
.popup {
  background-color: white;
  padding: 30px;
  border-radius: 4px;
}

.title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  color: #323841;
  margin: 0;
}

.close{
  cursor: pointer;
  path{
      color: #929EB0;
  }
}

.input {
  padding: 9px 10px;
  flex-grow: 1;

  color: #2a2a31;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;


  height: 36px;

  background: #FFFFFF;
  border: 1px solid #AEBACC;
  border-radius: 4px;

  &:placeholder {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;

    color: #929EB0;
  }
}

.label{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;

  color: #323841;  
  margin: 22px 0 6px 0;
}
.divider{
  margin: 30px 0 20px 0;
  width: 100%;
  height: 1px;

  background: #E6EAF0;
}

.submit{
  width: 210px;
}

.tags{
  div{
    min-height: unset;
    border: 1px solid #AEBACC;
    border-radius: 4px;
    div{
      border: none;
    }
  }
}