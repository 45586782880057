.checkbox {
  border: 1px solid #AEBACC;
  border-radius: 4px;
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.checkboxContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  gap: 10px;
}