.root {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-right: 12px;
}

.bell {
    position: relative;
}

.dot {
    position: absolute;
    width: 9px;
    height: 9px;
    top: 1px;
    right: -2px;

    background-color: #FF6161;

    border-radius: 50%;
    border: 2px solid #4A29CF;

}