.root {
	position: relative;
}

.lock {
	border-radius: 8px;
	padding: 7px 17px 8px 15px;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: #2A2A31;
	opacity: .5;
	&--default {
		composes: lock;
		background: #888888;
	}
	&--light {
		composes: lock;
		background: #E5E5E1;
	}
}
