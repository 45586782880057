.infoPopup {
  width: 440px;
  height: 82px;
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 4px 10px rgba(57, 63, 73, 0.3);

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 16px 20px;
}

.arrow {
  position: absolute;
  z-index: 10;
  width: 0;
  height: 0;
  margin-left: 30px;
  margin-top: -50px;

  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 10px solid white;
}

.avatar {
  img {
    width: 50px;
    height: 50px;

    border-radius: 50%;
  }
}

.infoBlock {
  margin-left: 12px;
}