@import "@palette";

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  width: 500px;
  height: 600px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;

  position: relative;
}

.close {
  cursor: pointer;

  path {
    color: #A2AEC2;
  }
}

.mailingListItems {
  height: 450px;
  width: 100%;
  
  overflow-y: scroll;
}

.arrowRight {
  margin-left: 10px;

  path {
    fill: white;
  }
}