@import '@palette';

.icon {
  &--gray3 {
    path {
      fill: $color-gray-3;
    }
  }
}

.input {
  flex-grow: 1;

  padding: {
    left: 15px;
  }

  outline: none;
  border: none;

  background-color: transparent;

  &::placeholder {
    color: $color-gray-4;
  }

  &--inFilter {
    composes: input;
    width: 80%;
    font-size: 12px;
    padding: 0;
  }
}