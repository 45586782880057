.root {
	width: 100%;
	border-radius: 12px;
	background: #fff;
    h3 {
    	font-size: 30px;
    	color: #2a2a31;
    	font-weight: bold;
    	padding: 20px 30px 6px;
    	margin: 0;
    }
    
}
.subtitle {
	color: #2a2a31;
	font-size: 14px;
	padding: 0 30px 20px;
    a {
        text-decoration: none;
		color: #6172FF;
		font-size: 14px;
		margin-left: 6px;
    }
}

.sign_btn {
	margin: 14px 30px 25px;
	background: #ffda54;
	transition: background .3s;
	padding: 11px 0;
	color: #2a2a31;
	font-size: 14px;
    font-weight: bold;
    text-align: center;
    border: none;
    width: calc(100% - 60px);
    border-radius: 12px;
    cursor: pointer;
    &:hover {
    	background: #F5C208;
    }
}

.divider {
	height: 1px;
	background: #f1f1ef;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03)
}