.root {
	width: 40px;
	height: 40px;
	border-radius: 10px;
	background: white;
	position: relative;
	cursor: pointer;

	path {
		fill: #8f8f8f;
	}

	@media (max-width: 1279px) {
		display: none;
	}
}