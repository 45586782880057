.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 110;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  background-color: white;
  padding: 30px;
  border-radius: 4px;
}


.platforms {
  button {
    width: 32% !important;
  }

  margin-top: 10px;
  margin-bottom: 20px;

  &--icon {
    min-width: 16px !important;

    path {
      fill: #6071FF;
    }

    &-active {
      min-width: 16px !important;

      path {
        fill: white;
      }
    }
  }
}