@import "@palette";

.main {
  background-color: $color-white;
  border-radius: 10px;
}
.container {
  padding: 30px;
  gap: 20px;
}
.step {
  width: 50%;
}
.sidebar {
  width: 50%;
  gap: 22px;
  padding: 0 24px 24px 24px;
}