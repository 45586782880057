@import "@polette";

.modal {
    border-radius: 0;

    padding: 40px 50px;
}

.error {
    & * {
        color: $color-red !important;
        text-decoration: underline;
    }
}

.field{
    width: 340px;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    background-color: white;
    padding: 16px 0px;
    line-height: 1px;
    &--mobile{
        composes: field;
        width: 100%;
        height: 36px;
        padding: 6px 10px;
    }
}

.check{
    height: 20px;
    width: 20px;
    border: 1px solid #CCCCCC;
    background-color: white;
}

.grid{
    display: grid;
    grid-template: auto auto auto / 370px 340px;
}

.btn {
    height: 56px;
    border-radius: 4px;
    background-color: $color-link;
    color:white;
    width: 340px;
    &--mobile{
        composes: btn;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &--disabled {
        composes: btn;
        background-color: #CCCCCC;
    }
    &--disabled--mobile {
        composes: btn;
        background-color: #CCCCCC;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &:not(.btn--disabled):hover {
        background: $color-link-hover;
    }
    
}