.arrow {
  path {
    fill: #929EB0;
  }
}

.arrow-down {
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  path {
    fill: #929EB0;
  }
}

.arrow-up {
  cursor: pointer;

  transform: rotate(180deg);
  transition: transform 0.3s ease-in-out;

  path {
    fill: #6071FF;
  }
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.popup {
  width: 210px;
  height: 264px;
  background-color: white;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* White */
  box-shadow: 0px 4px 10px rgba(57, 63, 73, 0.3);
  border-radius: 6px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 10px;
  width: fit-content;
  font-size: 10px;
  border-radius: 10px;
  height: 24px;
}

.items {
  padding: 6px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.create {
  border-top: 1px solid #E6EAF0;
  padding: 6px;
}

.createFirstLine {
  display: flex;
  align-items: center;
  justify-content: space-between;


  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    border: 0;
    outline: 0;
  }

  input:focus {
    outline: none !important;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.colors {
  transition: all 0.3s ease-in-out;
  max-height: 0;
  margin-top: 6px;
  display: flex;
  flex-direction: row;
  gap: 6px;
}

.done {
  background: #6071FF;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;

  path {
    fill: white;
  }
}

.itemContainer {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
}