.postItem {
  display: flex;
  flex-direction: column;
  width: 280px;
  height: 440px;
  border-radius: 10px;
  background-color: white;

  &--header {
    padding: 7px 9px;
    display: flex;
    flex-direction: row;
    align-items: center;

    &--info {
      display: flex;
      flex-direction: column;

      margin-left: 10px;
    }
  }

  &--info {
    padding: 16px;

    &--top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }
}


img {
  border-radius: 4px;
}

.blackIcon {
  width: 12px;
  height: 12px;

  path {
    stroke: black;
  }
}

.whiteIcon {
  width: 12px;
  height: 12px;

  path {
    stroke: white;
  }
}

.ava {
  div {
    border-radius: 16px;
  }

  img {
    border-radius: 16px;
  }

  svg {
    top: 0;
    border-radius: 16px;
  }

}

.socials {
  svg {
    path {
      fill: #5D697D;
    }
  }
}

