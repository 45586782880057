.main {
    position: relative;
    width: 90px;
    height: 90px;
    border-radius: 10px;
    margin-right: 20px;

    &--instagram {
        border: double 4px transparent;
        background-origin: border-box;
        background-clip: content-box, border-box;
        composes: main;
        background-image: linear-gradient(white, white), linear-gradient(95deg, rgba(84, 64, 207, 1) 0%, rgba(254, 113, 81, 1) 33%, rgba(244, 191, 88, 1) 66%, rgba(242, 58, 140, 1) 100%);
    }

    &--youtube {
        composes: main;
        border: solid 4px #E04444
    }

    &--twitch {
        composes: main;
    }

    &--vk {
        composes: main;
    }
    &--Telegram {
        composes: main;
    }
    &--tiktok {
        composes: main;
    }
}

.icon {
    position: absolute;
    margin-top: 16px;
    margin-left: -8px;
    z-index: 2;
}

.icon--twitch {
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 4;
}

.icon--vk{
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 4;
}
.icon--tg{
    position: absolute;
    top: 10px;
    right: 16px;
    z-index: 4;
}

.icon--tiktok{
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 4;
}

.root {
    height: 29px;
}

@media (max-width: 600px) {
    .main {
        width: 64px;
        height: 64px;
    }
}