.header {
  display: grid;
  grid-template-columns: 30px 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  background: #F8F9FB;
  border-radius: 4px;
  height: 60px;

  padding: 20px 16px;
}

.emptyStateInner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 340px;
  width: 100%;
  text-align: center;
  background-color: white;
  border-radius: 4px;
}

.imgContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100px;
  height: 100px;
  border-radius: 50%;

  background-color: #F2F4F7;
}

.img {
  width: 30px;
  height: 30px;

  path {
    fill: #929EB0;
  }
}