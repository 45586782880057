@import "@palette";
.root {
  width: 100%;
  gap: 20px;
}
.field-wrapper {
  height: 36px;
}
.info-icon {
  background-color: #e0e0e0;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  cursor: pointer;
  color: #4a4a4a;
  min-width: 16px;
  min-height: 16px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  &:hover {
    background-color: #5D697D;
    color: white;
  }
}
.title-wrap {
  padding-bottom: 16px;
  border-bottom: 1px solid #E6EAF0;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  background-color: $color-violet-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  & svg {
    margin-left: 4px;
    path {
      fill: white;
    }
  }
}

.input-report-name {
  border: 1px solid #AEBACC;
  height: 36px;
  width: 100%;
  outline: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  padding: 0 8px;
  color: $color-gray-new-1;
  border-radius: 4px;
  &:focus-visible {
    outline: none;
  }
}