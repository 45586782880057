@import "@palette";

.load {
    animation: gradient 1s linear 0s infinite;

    background: linear-gradient(
        90deg,
        #6172ff 0%,
        #919cff 56.48%,
        #6172ff 98.96%
    );
    background-size: 200% 200%;
}

@keyframes gradient {
    100% {
        background-position: -50% 50%;
    }
    0% {
        background-position: 150% 50%;
    }
}

.icon {
    margin-top: 2.5px;
    box-sizing: content-box;
    margin-right: 10px;
    animation: loading 1.5s linear 0s infinite;
}

.violetIcon {
    path {
        fill: $color-violet-1;
    }
}

@keyframes loading {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
