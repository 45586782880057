@import "@palette";
.multi-select-wrapper {
  width: 100%;
  height: min-content;
}

.multiSelect {
  position: relative;
  width: 100%;
  height: min-content;
}

.selectBox {
  border: 1px solid #AEBACC;
  padding: 2px 4px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  min-height: 36px;
  &.selected {
    border: 2px solid #6071FF!important;
  }
}

.selectedOptions {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.chip {
  display: inline-flex;
  padding: 7px 7px 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #6071FF;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  white-space: nowrap;
}
.searchInput {
  border: none;
  height: 100%;
  width: 100%;
  outline: none;
  margin-left: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  color: $color-gray-new-1;
  &:focus-visible {
    outline: none;
  }
}

.chip-container {
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
  gap: 6px;
}

.tag {
  display: flex;
  padding: 7px 7px 7px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #929EB0;
  color: white;
  font-size: 12px;
  font-weight: 500;
  border-radius: 4px;
  white-space: nowrap;

  span {
    margin-left: 5px;
    cursor: pointer;
  }
}

.arrow {
  margin-left: auto;
  margin-right: 8px;
}

.optionsList {
  position: absolute;
  top: 104%;
  left: 0;
  right: 0;
  background-color: white;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0px 2px 10px rgba(57, 63, 73, 0.3);
  overflow: hidden;
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  opacity: 0;
  overflow-y: auto;
}

.show {
  max-height: 240px;
  opacity: 1;
}

.hide {
  max-height: 0;
  opacity: 0;
}

.option {
  padding: 10px 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  color: #323841;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 41px;

  &:hover {
    background-color: #F5F6F8;
  }

  &.selected {
    background-color: #F5F6F8;
  }
}

.placeholder {
  font-size: 14px;
  font-weight: 500;
  color: #929EB0;
  padding-left: 10px;
}
.open {
  transform: rotate(180deg);
}
.selected {

}