@import "@palette";

.root {
	flex-direction: column;
	position: fixed;
	bottom: 50px;
	left: 50%;
	margin-left: -220px;

	width: 440px;

	z-index: 600;

	@media (max-width: 600px) {
		left: 0;
		bottom: 0;
		width: 100%;
		margin: 0;
	}
}

.closeArea {
	background-color: $color-white;

	border: 1px solid $color-gray-6;

	box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.15);

	border-radius: 4px;

	line-height: 42px;

	text-align: center;

	cursor: pointer;

	user-select: none;

	transition: opacity .3s, visibility .3s;

	&--hidden {
		composes: closeArea;
		opacity: 0;
		visibility: hidden;
		display: none;
	}
	&--visible {
		composes: closeArea;
		opacity: 1;
		visibility: visible;
	}
}