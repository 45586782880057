@import "@palette";
.root {
	// composes: card from global;
	padding: 30px 0 10px 0 ;
	position: relative;
	flex-grow: 1;
	// &:not(:last-child){
	// 	padding-right: 40px;
	// }
	&_plh {
		filter: blur(3px);
		&_body {
			padding-top: 25px;
			&_line {
				height: 10px;
				background: #f1f1ee;
				border-radius: 10px;
			}
			&_label {
				height: 10px;
				background: #f1f1ee;
				border-radius: 10px;
				width: 70px;
				margin-bottom: 10px;
			}
		}
	}
}

.more{
	cursor: pointer;
}

.vals {
	padding-top: 10px;
	max-height: 700px;
	margin-bottom: 27px;
}


// .label {
// 	padding-left: 10px;
// }


.bar {
	width: 99%;
	&:not(:last-child) {
		margin-bottom: 20px;
	}
}

.tops {
	margin-bottom: 2px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.line {
	background: #6172FF;
	border-radius: 10px;
	height: 10px;
	&_container {
		background: #f1f1ee;
		border-radius: 10px;
		height: 10px;
	}
}

.label {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	color: $color-gray-1;
}

.value {
	font-size: 12px;
	color: #6172FF;
	font-weight: bold;
}

.blur {

	transition: filter 1s;

	&--active{
		composes: blur;
		filter: blur(10px);
	}
	&--default{
		composes: blur;
		filter: blur(0px);
	}
}