@import '@palette';

.root {
  background: $color-common-background;
}

.page {
  padding-top: 62px;
  composes: globalWidth from global;
}

.icon-back {
  transform: rotate(180deg) scale(0.7);
  width: 16px;
  height: 16px;
  margin-right: 6px;

  path {
    fill: $color-gray-new-3;
  }
}

.icon {
  rect {
    fill: $color-gray-new-3;
  }

  path {
    fill: $color-white;
  }
}