.alert {
    display: flex;
    padding: 4px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    background: var(--Red-3, #FFE1E7);
  
    svg {
      width: 20px;
      height: 20px;
  
      path {
        fill: #F5535D;
  
      }
    }
  }