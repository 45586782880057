.listItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  cursor: pointer;
}

.listItem:hover {
  background-color: #F8F9FB;
  border-radius: 4px;
}