@import "@palette";

.stepContainer {
  border: 1px solid $color-violet-4;
  padding: 20px;
  background-color: $color-violet-4;
  border-radius: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 426px;
  max-height: fit-content;
}
.ai-block {
  padding: 16px;
  border-radius: 10px;
  background-color: $color-violet-light-2;
}
.edit-icon {
  width: 18px;
  height: 18px;
  margin-left: 6px;
  & path {
    fill: $color-violet-1;
  }
}

.stepNumber {
  background-color: $color-violet-6;
  color: $color-white;
  padding: 6px 10px;
  border-radius: 12px;
  font-size: 10px;
  font-weight: 500;
}
.wrapper {
  padding: 30px;
}
.content {
  text-align: center;
}

.avatar {
  width: 100px;
  height: 100px;
  background-color: $color-white;
  border-radius: 50%;
  margin: 20px auto;
}