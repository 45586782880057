.root {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 1000vw;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  position: relative;
  width: 500px;
  background-color: white;
  padding: 32px 30px;

  border-radius: 4px;

  &--close {
    cursor: pointer;

    path: {
      fill: #929EB0;
    }
  }

  &--provider {
    cursor: pointer;
    border: 1px solid #E6EAF0;
    border-radius: 4px;
    height: 100px;
    width: 210px;
    height: 120px;
    margin-top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 22px;
  }

  &--desc {
    background: #F8F9FB;
    border-radius: 4px;
    padding: 16px 20px;
    margin-top: 20px;
  }
}

.mailIcon {
  width: 45px;
  height: 36px;
}