@import '@palette';

$width-mobile: 744px;

.root {
    padding: {
        top: 108px;
    }
}

.grow {
    flex-grow: 1;
}

.btn {
    color: $color-violet-1;
    background-color: transparent;
    width: 170px;

    @media (max-width: 1439px) {
        width: unset;
    }

}

.icon {
    margin: 0 10px -5px 0;
    @media (max-width: 1439px) {
        margin: 0 5px -5px 0;
    }
        
    path {
        fill: $color-violet-1
    }
}

.button {
    transition: opacity 0.5s, visibility 0.5s;
    padding: 12px 20px;
    font-size: 15px;

    @media (max-width: 1439px) {
        width: 140px !important;
        align-items: center;
    }
        
    &--visible {
        composes: button;
        opacity: 1;
        visibility: visible;
    }

    &--hidden {
        composes: button;
        opacity: 0;
        visibility: hidden;
    }
}

@media (max-width: 1279px) {
    .button {
        display: none;
    }
}


@media screen and (max-width: $width-mobile) {
    .button {
        display: none;
    }

    .btn {
        display: none;
    }
}

.mainFiltersBarContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 66px;
    background-color: white;
    border-radius: 10px;
    padding: 12px 12px 12px 24px;

    @media (max-width: 1439px) {
        display: grid;
        grid-template-columns: 4fr 1fr;
        align-items: center;
    }

    @media (max-width: 1279px) {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
        border-radius: 10px;
        height: 52px;
        margin-top: 30px;
        padding: 12px 12px 12px 12px;
    }

    @media (max-width: 744px) {
        padding: 4px;
        height: 46px;
        display: flex;
        justify-content: space-between;
    }
}

.filtersBtnTitle {
    font-size: 14px;
    @media (max-width: 1439px) {
        display: none;
    }

    @media (max-width: 1279px) {
        display: inline-block;
    }
}

.mainFiltersDisplay {
    width: 100%;
    @media (max-width: 1279px) {
        display: none;
    }
}

.inputDisplay {
    @media (min-width: 1280px) {
        display: none;
    }

    @media (max-width: 743px) {
        width: 100%
    }
}

.tabAdapterDisplay {
    @media (max-width: 1279px) {
        display: none;
    }
}

.hider {
    @media (max-width: 743px) {
        background: rgba(9, 16, 31, 0.2);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
    }
}