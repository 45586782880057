@import '@palette';

.searchBar {
  position: relative;
  flex-wrap: nowrap;
  max-width: 380px;
  width: 100%;
  svg {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 16px;
    top: calc(50% - 10px);
  }
}

.searchInput{
  border-radius: 10px;
  height: 40px;
  max-width: 380px;
  width: 100%;
  background-color: $color-white;
  padding: 10px 32px 10px 16px;
  border: none;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  color:$color-gray-new-1;

  &:focus-within {
    outline: 2px solid $color-violet-1;
  }
  &::placeholder {
    color: $color-gray-new-3;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}
