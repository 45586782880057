.container {
  padding: 0 30px 30px 30px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  gap: 30px;
  width: 100%;
  max-width: 1460px;
  margin: 150px auto 0 auto;

  @media (max-width: 1900px) {
    max-width: 1260px;
  }
}

.services {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 40px;
  @media (max-width: 1440px) {
    gap: 16px;
  }

  @media (max-width: 768px) {
    justify-content: center;
    gap: 10px;
  }
}
