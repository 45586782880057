.Email {
  margin-bottom: 16px;
  margin-left: 20px;

  &--text {
    margin-left: 8px;

    font-size: 12px;
    line-height: 20px;
  }

}

@media (max-width: 600px) {
  .Email {
    &--text {
      font-size: 10px;
      line-height: 14px;
    }

    &--icon {
      height: 12px;
    }
  }
}