.notification {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 16px;
  background-color: #ffffff;
  box-shadow: 0 4px 20px 4px rgba(37, 58, 96, 0.25);
  border: 1px solid #E0E0E0;
  border-radius: 4px;
  width: 400px;
  z-index: 1601;
  //position: fixed;
  //top: 0;
  //right: 20px;
}

.content {
  display: flex;
  span {
    color: #323841;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
  }
}

.link {
  color: #495CFB;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.closeButton {
  background: none;
  border: none;
  font-size: 20px;
  color: #999;
  cursor: pointer;
  padding: 0;
  margin-left: 10px;
  transform: translateY(-8px);
}

.closeButton:hover {
  color: #555;
}
