.btn {
	display: flex;
	align-items: center;
	cursor: pointer;
	background: rgba(255, 255, 255, .1);
	padding: 4px 7px 4px 12px;
	border-radius: 10px;
}
.arrow {
	&--up {
		transform: rotate(180deg);
	}
	&--down {
		transform: rotate(0deg);
	}
}

.popup {
	background: #fff;
	width: 160px;
	padding: 15px 20px;
	border-radius: 10px;
	font-size: 14px;
	box-shadow: 0 4px 20px rgba(0,0,0, .4);
	@media (max-width: 600px) {
		background: #302F35;
	}
}

.link {
	display: block;
	text-decoration: none;
	color: #2a2a31;
	&--disabled {
		composes: link;
		opacity: .6;
	}
	@media (max-width: 600px) {
		color: #fff;
	}
}

.divider {
	margin: 15px -20px;
	width: calc(100% + 40px);
	background: #F1F1EE;
	height: 1px;
}

.counter {
	line-height: 15px;
	font-size: 12px;
	font-weight: bold;
	padding: 0 6px;
	border-radius: 12px;
	&--btn {
		composes: counter;
		background: rgba(255, 255, 255, .2);
		color: #FFDA54;
		margin: {
			left: 6px;
			right: 5px;
		};
	}
	&--pop {
		composes: counter;
		background: #FFDA54;
		color: #302F35;
		margin: {
			left: 6px;
		}
	}
}