@import "@palette";
.empty {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.wrap {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-new-7;
}
.icon {
    path {
        fill: #929EB0;
    }
    width: 30px;
    height: 30px;
}
.btn {
  padding: 0 18px;
  font-size: 12px;
  font-weight: 600;
  height: 34px;
  display: flex;
  align-items: center;
  border-radius: 4px;
}