@import "@palette";
.root {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 1000vw;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  position: relative;
  width: 700px;
  height: 740px;
  background-color: white;
  padding: 32px 30px;

  border-radius: 10px;

  &--close {
    cursor: pointer;

    path: {
      fill: #929EB0;
    }
  }

  overflow: scroll;
}

.grid {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.card {
  display: flex;
  flex-direction: column;
  gap: 18px;

  background-color: #F8F9FB;
  border-radius: 4px;

  padding: 20px;
}

.header {
  top: 0;
  left: 0;
  padding: 30px;
  position: fixed;
  width: 700px;
  background-color: white;
  height: 80px;

  border-radius: 10px;
}

.signature {
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin-top: 26px;

  margin-bottom: 86px;
}

.footer {
  position: fixed;
  bottom: 0;
  left: 0;

  padding: 30px;
  width: 700px;
  background-color: white;
  height: 86px;

  border-radius: 0px 0px 10px 10px;
  border-top: 1px solid #D6DBE6;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.sendIcon {
  margin-left: 5px;

  path {
    fill: white;
  }
}

.editor {
  position: relative;
}

.editorArea {
  width: 100%;
  height: 200px;
  border: 1px solid #B7C3D6;
  border-radius: 4px;
  outline: none;
  line-height: 18px;
  font-size: 14px;
  color: $color-gray-1;
  background: transparent;
  resize: none;
  padding: 10px 10px 30px 10px;
  
  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}