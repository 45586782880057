@import '@palette';
$width-mobile: 743px;

.mobileWrap {
    display: none;
}

@media only screen and (max-width: $width-mobile) {
    .desktopWrap {
        display: none;
    }

    .mobileWrap {
        display: flex;
        flex-direction: column;
        margin-top: 10px;
        background: white;
        border-radius: 10px;

        &--user {
            display: flex;
            flex-direction: row;

            &-info {
                flex-direction: column;
                margin-top: 14px;
                margin-left: 10px;
            }
        }

        &--stats {
            display: flex;
            flex-direction: row;
            margin-top: 10px;
            margin-left: 10px;

            &-keyWord {
                font-size: 10px;
                color: $color-gray-3;
                font-weight: 500;
            }
        }

        &--buttons {
            flex-direction: row;
            margin-top: 14px;
            margin-left: 10px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    .chip {
        line-height: 14px !important;
    }
}

.icon {
    path {
        fill: $color-white;
    }

    &--gray {
        path {
            fill: $color-gray-4;
        }
    }
}

.text-overflow {
    width: 10vh;
}

.item {

    &:hover {
        .new-comment {
            visibility: visible;
        }
    }
}

.new-comment {
    visibility: hidden;
}