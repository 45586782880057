.tabs {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  margin-top: 10px;
}

.tab {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: row;

  margin: 0;
  width: 150px;
  cursor: pointer;
}

.leftCurve {
  position: absolute;
  left: -10px;
  bottom: 0px;

  width: 10px;
  height: 10px;
  background-color: white;
}

.leftCurveInner {
  position: absolute;
  left: -10px;
  bottom: 0px;

  width: 20px;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 50% 50%;
}

.rightCurve {
  position: absolute;
  right: -10px;
  bottom: 0px;

  width: 10px;
  height: 10px;
  background-color: white;
}

.rightCurveInner {
  position: absolute;
  right: -10px;
  bottom: 0px;

  width: 20px;
  height: 20px;
  background-color: #f2f2f2;
  border-radius: 50% 50%;
}

.beta {
  position: absolute;
  top: 6px;
  right: 10px;
}