.root {
    min-height: 100vh;
    padding-bottom: 50px;
	background: #F9F9F9;
}


.container {
	composes: globalWidth from global;
	background: #FFF;
	margin-top: -70px;
	border-radius: 4px;

	padding: 24px 50px;
    @media (max-width: 600px) {
        border-radius: 10px;
        padding: 0;
    }
}

.unlock {
    height: 54px;
    background-color: #6172FF;
    border-radius: 10px;

    margin-bottom: 20px;

    cursor: pointer;
}

.locker {

    margin-right: 10px;

    path {
        fill: #fff;
    }
}

.divider {
    height: 24px;
    width: 1px;

    background-color: #fff;

    opacity: 0.4;

    margin: 0 20px;
}

.tiket {
    margin-right: 8px;
    margin-top: 5px;
}

.back_button {
	display: flex;
    align-items: center;
    
    background: #fff;
    
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
    
    border-radius: 12px;
    
	font-size: 14px;
    color: #888;
    
    padding: 5px 15px 5px 8px;
    
	text-decoration: none;
}