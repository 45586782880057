.background {
    background: linear-gradient(90deg, #D6D6D6 0%, #CCCCCC 14.06%, #EBEBEB 27.08%, #CCCCCC 39.58%, #D1D1D1 100%);
	background-size: 400% 400%;
	animation: gradient 10s linear infinite;
    border-radius: 10px;
}

@keyframes gradient {
    100% {
        background-position: -50% 50%;
    }
    0% {
        background-position: 150% 50%;
    }
}