.empty {
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: white;
  border-radius: 4px;
  margin-top: 1px;
}

.mailContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;

  background-color: #F2F4F7;

  border-radius: 50%;
}

.mail {
  width: 30px;
  height: 24px;

  path {
    fill: #929EB0;
  }
}
