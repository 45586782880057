.root {
    position: relative;

    border-radius: 12px;

    transition: padding .3s, background-color .3s, margin .3s, width .3s;

    &--open {
        composes: root;
        width: calc(100% + 20px);

        margin-left: -10px;
        margin-bottom: 20px;

        background-color: #6172ff;

        padding: 10px 10px 15px;
    }

    &--close {
        composes: root;
        width: 100%;

        margin-left: 0;
        margin-bottom: 0;

        background-color: transparent;

        padding: 0;
    }
}

.grid {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    display: grid;
    grid-gap: 20px;
    // grid-auto-rows: 360px;

    z-index: 50;

    transition: opacity .3s, visibility .3s;

    &--hidden {
        composes: grid;
        opacity: 0;
        visibility: hidden;
    }
    &--visible {
        composes: grid;
        opacity: 1;
        visibility: visible;
    }
}

.details {

    transition: max-height .3s, opacity .3s;

    overflow: hidden;

    &--open {
        composes: details;
        max-height: 200px;
        opacity: 1;
    }
    &--closed {
        composes: details;
        max-height: 0px;
        opacity: 0;
    }
}
.blogger-link{
    margin: 0 10px 0 0;
}
@media (max-width: 600px) {
    .blogger-link{
        margin: 0 0 10px 0;
    }
}
@media (max-width: 600px) {
    .open-buttons--col{
        flex-direction: column;
        align-items: center;
    }
}