.cardholder {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1600;

	width: 100vw;
	height: 100vh;

	display: flex;
	align-items: center;
	justify-content: center;
	
	overflow-y: auto;

	background-color: rgba(58, 63, 81, .8);
}

.icon {
	position: fixed;

	bottom: 40px;
	right: 20px;

	width: 56px;
	height: 56px;

	border-radius: 50%;
	background-color: rgb(255, 218, 84);
	
	box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12);

	cursor: pointer;

	svg {
		width: 30px;
		path {
			fill: white;
		}
	}
}