@import "@palette";

.header {
  padding-top: 108px;
  position: relative;

  background-color: $color-common-background;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-bottom: 24px;
}

.heading {
  // margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background: #FFFFFF;
  border-radius: 10px;

  padding: 24px 30px;
  margin-top: 20px;
}