.VideoCover {
  &--image {
    max-width: 390px;
    width: 100%;
    height: 100%;
  }

  &--title {
    align-self: flex-end;
    max-height: 52px;
    max-width: 30ch;
    margin: 20px 0 0 0;

    overflow: hidden;
    overflow-wrap: break-word;

    line-height: 26px;
    font-size: 16px;
    text-overflow: ellipsis;
  }
}

@media (max-width: 600px) {
  .VideoCover {
    min-width: 260px;
    &--title {
      height: 48px;
      margin: 10px 0 0 0;
      max-width: 14ch;
      font-size: 14px;
    }
  }
}
