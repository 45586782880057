.customTooltip {
  background-color: #5D697D;
  color: #fff;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 12px;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  pointer-events: auto;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  flex-direction: column;
  min-width: 180px;
  gap: 10px;
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%) rotate(90deg);
    border-left: 10px solid #FFFFFF;
    border-right: 10px solid #FFFFFF;
    border-top: 10px solid #5D697D;
  }
}

.tooltipButton {
  background-color: #6071FF;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 5px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
}
.chart {
  position: relative;

  .apexcharts-canvas {
    cursor: default !important;
  }
  :global {

    .apexcharts-tooltip {
      background: #4B5563 !important;
      color: #fff !important;
      border-radius: 8px !important;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1) !important;
      padding: 8px 12px !important;
      font-size: 14px !important;
      border: none !important;
    }
  }

  // Отключение выделения даты
  .apexcharts-xaxis-label {
    pointer-events: none !important;
  }
}
.icon {
  position: absolute;
  top: 50%;
  left: -15px;
  transform: translateY(-50%);
}

