@import "@palette";

.root {
	width: 100%;
	height: fit-content;

	/* White */
	background: #FFFFFF;
	border-radius: 4px;
	position: -webkit-sticky;
	position: sticky;
	top: 69px;
}

.scrollable-div {
	max-height: 400px;

	@media (max-width: 600px) {
		max-height: 300px;
	}

	overflow-y: scroll;

	&>.list-item {
		margin-top: 20px;
	}

	&>.list-item~.list-item {
		margin-top: 0;
	}

	margin-right: 2px;

	&::-webkit-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, .5);
		box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}

	&::-moz-scrollbar {
		-webkit-appearance: none;
		width: 7px;
	}

	&::-moz-scrollbar-thumb {
		border-radius: 4px;
		background-color: rgba(0, 0, 0, .5);
		box-shadow: 0 0 1px rgba(255, 255, 255, .5);
	}
}

.header {
	width: 100%;
	height: 60px;
	border-style: solid;
	border-width: 0 0 1px 0;
	border-color: $color-gray-6;
	padding: 20px;
}

.text {
	font-family: Montserrat;
	font-style: normal;
	margin: 0;
	display: flex;
	align-items: center;
}

.title {
	composes: text;
	font-weight: 600;
	font-size: 16px;
	line-height: 20px;

	color: $color-gray-1;
}

.edit {
	composes: text;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;

	color: $color-violet-1;
	cursor: pointer;
}

.list-item {
	cursor: pointer;
	min-height: 41px;
	border-radius: 4px;
	padding: 0 10px 0 10px;
	margin: 0 3px 0 10px;

	&--name {
		composes: text;
		font-weight: 500;
		font-size: 14px;
		line-height: 17px;
		color: $color-gray-1;

		display: block;
		text-overflow: ellipsis;
		overflow: hidden;
		word-wrap: break-word;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;

		margin-right: 5px;
	}

	&--count {
		composes: text;
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: $color-gray-3;
	}

	&--active {
		composes: list-item;
		background: $color-gray-8;
		border-radius: 4px;
	}

	&:hover {
		background: $color-gray-8;
		border-radius: 4px;
	}

	&--input {
		min-width: 0;
		height: 41px;
		outline: none;
		flex-grow: 1;
		font-size: 12px;
		color: #2a2a31;
		border: none;
		background-color: transparent;

		&--crossed {
			composes: list-item--input;
			text-decoration: line-through;
			pointer-events: none;
		}
	}
}

// .new{
// 	composes: text;
// 	font-weight: 500;
// 	font-size: 14px;
// 	line-height: 17px;
// 	padding: 0 20px 0 20px;
// 	margin-top: 24px;
// 	margin-bottom: 30px;
// 	color: $color-violet-1;
// }

.writer {
	input {
		cursor: pointer;
		min-width: 0;
		height: 41px;
		outline: none;
		flex-grow: 1;
		font-size: 12px;
		color: #2a2a31;
		border: none;
		background-color: transparent;
		margin-bottom: 10px;

		&::placeholder {
			font-family: Montserrat;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 17px;
			padding: 0 20px 0 20px;
			color: $color-violet-1;
		}
	}

	&--active {
		background: $color-white;
		border-radius: 4px;

		border: 2px solid $color-violet-1;

		padding: 0 7px;

		height: 36px;

		margin: 10px 10px 0 10px;

		input {
			min-width: 0;
			outline: none;
			flex-grow: 1;
			font-size: 12px;
			color: #2a2a31;
			border: none;
			background-color: transparent;

			&:placeholder {
				font-size: 14px;
				color: #888;
				opacity: 0.7;
			}
		}

		&--icon {
			font-size: 16px;
			color: #888;
			cursor: pointer;
		}
	}
}

.icon {
	width: 13px !important;
	height: 13px !important;
	color: #888;
	cursor: pointer;
}

// @media (max-width: 600px) {
// 	.root{
// 		display: none;
// 	}
// }