.footer {
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: fixed;
  bottom: 0;
}

.mailingListItems {
  height: 440px;
  overflow-y: scroll;
}