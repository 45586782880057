.root {
	h3 {
		font-weight: normal;
		font-size: 18px;
		line-height: 23px;
		color: #2A2A31;

		margin: 0 0 6px;
	}
	p {
		font-weight: normal;
		font-size: 12px;
		line-height: 15px;
		color: #888888;

		margin: 0;
	}
}