.pageContainer {
  display: flex;
  flex-direction: column;
  padding: 30px 0;
}

.filtersContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 24px;
}
