@import "@palette";

.priceInput {
  display: flex;
  align-items: center;
  position: relative;
  width: 60px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input {
    text-decoration: underline;
    min-width: 50px;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.editIcon {
  // position: absolute;
  top: 1px;
  left: 40px;
  cursor: pointer;
}

.doneButton {
  position: absolute;
  // top: -9px;
  right: -70px;
  z-index: 4;

  width: 24px;
  height: 24px;

  background: $color-violet-1;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: white;
  }
}

.crossButton {
  position: absolute;
  z-index: 4;
  right: -96px;

  width: 24px;
  height: 24px;

  background: $color-gray-6;
  border-radius: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  path {
    fill: $color-gray-3;
  }
}

.priceInput input {
  outline: unset;
  border: unset;
  width: 100%;

  color: $color-gray-1;
  text-decoration: underline;
}

.priceInput input:focus {
  // position: absolute;
  top: -12px;
  left: 0;
  z-index: 3;

  border: 1px solid $color-gray-3;
  min-width: 160px!important;
  padding: 6px 60px 6px 20px;

  background: #ffffff;

  border: 1px solid #bfc6d2;
  box-shadow: 2px 2px 10px rgba(118, 132, 155, 0.2);
  border-radius: 4px;

  text-decoration: none;
}

.priceInput input::placeholder {
  color: $color-gray-3;
  text-decoration: none;
}

.currency {
  font-size: 12px;
  color: #323841;
}