@import "@polette";

.root {
    background-color: #EDFAF2;
    color: #4ECF81;
    border-radius: 20px;

    padding: 11px 32px;

    cursor: pointer;

    transition: background-color .3s;
    &--mobile{
        composes: root;
        padding:7px 32px
    }

    &:hover {
        background-color: rgba($color-green, .3);
    }
}