.MetricItem {
  max-width: 100px;
  margin-left: 20px;
  flex: 1;

  &-small {
    composes: MetricItem;
    max-width: 200px;
  }

  &--value {
    font-size: 24px;

    &-small {
      font-size: 18px;
    }
  }

  &--name {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .MetricItem {
    margin: 20px 20px 0 0;

    &--value {
      font-size: 16px;
    }

    &--name {
      font-size: 10px
    }
  }
}