.root {
	composes: card from global;
	padding: 15px 20px;
	&_val {
		color: #2a2a31;
		font-size: 30px;
		margin: 0;
	}
	&--hidden {
		filter: blur(3px);
	}
}
.placeholder {
	height: 30px;
}