@import "@palette";

.loadingButton {
  margin-top: 10px;
  color: $color-violet-1;
}

.root {
  position: absolute;
  top: 0;
  left: -1000px;
  width: 1000vw;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  position: relative;
  width: 460px;
  background-color: white;
  padding: 32px 40px;

  border-radius: 4px;
}

.divider {
  width: 460px;
  height: 1px;

  margin-left: -40px;
  background: #ebebeb;
}

.footer {
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.close {
  position: absolute;
  top: 25px;
  right: 25px;

  cursor: pointer;

  path {
    fill: $color-gray-3;
  }
}

.sideText {
  margin: 0 0 5px 4px;
}

.icon {
  margin: 0 0 10px 5px;
  path {
    fill: $color-gray-1;
  }

  &--red {
    path {
      fill: $color-red-2;
    }
  }
}
