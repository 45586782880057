.footer {
  gap: 12px;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    color: #323841;
    width: 75%;
    line-height: 18px;
  }
  @media (max-width: 500px) {
    align-items: flex-start!important;
    p {
      font-size: 12px;
    }
  }
}
