@import "@palette";

.btn {
	background: none;
	border-radius: 4px;
	height: 34px;
	width: 34px;
	color: #888;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		background: #DDDDDB;
	}
}

.icon {
	path {
		fill: $color-gray-5;
	}
}