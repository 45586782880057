.header {
  padding-top: 78px;
  position: relative;
  .helper {
    margin-top: 17px;
  }

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center!important;
    justify-content: center!important;
    text-align: center;
    .helper {
      align-items: center!important;
      justify-content: center!important;
    }
  }
  @media (max-width: 500px) {
    text-align: left;
    align-items: flex-start!important;
    justify-content: flex-start!important;
    .helper {
      align-items: flex-start!important;
      justify-content: flex-start!important;
    }
  }


  .title {
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    color: #323841;
    margin-bottom: 8px;
  }

  .sub-title {
    color: #323841;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    white-space: pre-wrap;
  }

  .subtitle {
    margin-top: 10px;
    color: #666;
  }
}
.img-wrapper {
  width: 120px;
  height: 120px;
  position: absolute;
  top: -60px;
  left: -4px;
  @media (max-width: 768px) {
   left: calc(50% - 60px);
  }
  @media (max-width: 500px) {
    left: -4px;
  }
}
.image {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
