@import '@palette';

$columns: 2fr 1fr 1fr 1fr 1fr;

.root {
    background: #ffffff;

    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);

    border-radius: 12px;

    flex-grow: 1;

    margin-left: 20px;

    overflow: hidden;

    height: 100%;
    padding-bottom: 10px;
}

.left {
    padding-left: 30px;
}

.right {
    padding-right: 40px;
}

.grid-row {
    display: grid;

    grid-template-columns: $columns;

    grid-column-start: 1;
    grid-column-end: 6;
}

.title {
    composes: left;

    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #3d3f42;
    margin-top: 24px;
    margin-bottom: 16px;
}

.hat {
    composes: grid-row;

    height: 50px;
    margin: {
        left: 20px;
        right: 20px;
    }
    background: #f5f5fe;
    border-radius: 4px;
}

.item {
    composes: grid-row;

    margin-left: 20px;
    margin-right: 20px;
    &--line {
        composes: item;
    }
}
.item:nth-child(even) {
    background: #fafafa;
    border-radius: 4px;
}
.loading {
    grid-column-start: 1;
    grid-column-end: 6;
}

.name {
    composes: left;
    &--text {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;

        color: #3d3f42;
    }
}

.price {
    composes: right;
}

.bold {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    color: $color-violet-2;
}

.error {
    grid-column-start: 1;
    grid-column-end: 6;

    min-height: 150px;

    padding: 20px 0;
}

.empty {
    grid-column-start: 1;
    grid-column-end: 6;

    grid-row-start: 3;
    grid-row-end: 11;

    padding: 86px 0 190px;
}

.items {
    display: grid;

    grid-template-columns: $columns;

    grid-auto-rows: 50px;
}
