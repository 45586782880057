.markerDot {
  width: 6px;
  height: 6px;
  border-radius: 50%;

  &--red {
    composes: markerDot;
    background-color: #F8848C;
  }

  &--green {
    composes: markerDot;
    background-color: #70D9A0;
  }

  &--yellow {
    composes: markerDot;
    background-color: #F3C10D;
  }
}