@import "@palette";
.list {
  width: 100%;
  background-color: $color-white;
  border-radius: 4px;
  border: 1px solid $color-common-background;
}

.headerRow {
  display: grid;
  grid-template-columns: 50px 1.3fr 1fr 1fr 1fr 1fr;
  background-color: #F8F9FB;
  font-weight: bold;
  border-radius: 4px;
}

.headerCell, .cell {
  border-bottom: 1px solid #f1f3f8;
  text-align: left;
  height: 60px;
  display: flex;
  align-items: center;
  color: var(--Gray-3, #929EB0);
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
}
.headerCell {
  padding: 16px 22px;
}
.headerCell:first-child {
  padding: 16px;
}
.body {
  display: contents;
}
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid $color-gray-11;
  background-color: $color-white;
}
