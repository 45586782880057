@import "@palette";

.root {}

.main {
    padding: 20px 24px;
    background: #ffffff;
    border-radius: 4px;
}

.segment {
    border-radius: 10px;
    background: var(--white, #FFF);
}

.pdfSoon{
    position: relative;
    button{
        cursor: default;
    }
    &-icon{
        position: absolute;
        right: 10px;
        top: -10px;
    }
}

// .over {
//     height: 30px;
//     width: 100%;
//     background-color: $color-common-background;
// }

// .arrow {
//     path {
//         fill: $color-gray-3;
//     }
// }

// @media (max-width: 600px) {
//     .main {
//         padding: 13px 12px;
//     }

//     .GlobalWidth {
//         padding: 0;
//     }
//     .addToList {
//         display: none;
//     }

//     .container {
//         composes: globalWidth from global;
//         background: #FFF;
//         margin-top: -30px;
//         border-radius: 10px;

//         padding: 0;
//     }
// }