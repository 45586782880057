@import "@palette";

.campaigns {
  display: flex;
  flex-direction: column;

  width: 280px;
  height: fit-content;
  padding: 20px 8px;

  background-color: white;
  border-radius: 10px;

  max-height: 592px;
}

hr.divider {
  width: 280px;
  margin-left: -8px;
  border: none;
  border-top: 2px solid #F2F4F7;
}

.items {
  display: flex;
  flex-direction: column;
  margin-top: 4px;

  margin-bottom: 20px;

  overflow-y: scroll;
}

.item:hover {
  background-color: #F2F4F7;
}

.item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 12px;
  border-radius: 4px;
  margin-bottom: 2px;

  cursor: pointer;

  &--active {
    background-color: #F2F4F7;
  }
}

.title {
  padding: 0px 12px 8px 12px;
}