.root {
	display: flex;
	align-items: center;
}

.lang {
	border-radius: 5px;
	margin-bottom: 10px;
	cursor: pointer;
	color: #fff;
	&_cur {
		border-radius: 5px;
		margin-right: 12px;
		cursor: pointer;
		color: #fff;
		padding: 5px;
		font-size: 12px;
		font-weight: bold;
	}
}

.popup {
	box-shadow: 0 0 10px rgba(0,0,0,.4);
	padding: 10px;
	border-radius: 10px;
}