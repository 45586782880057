@import '@palette';
.row {
  display: grid;
  grid-template-columns: 50px 1.3fr 1fr 1fr 1fr 1fr;
  border-bottom: 1px solid #f1f3f8;
  background-color: white;
  border-radius: 4px;
  &:hover {
    background-color: #f5f5f5;
  }
}

.row > div {
  padding: 16px 22px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.row > div:first-child {
    padding: 16px;
}
.name {
  color: $color-gray-new-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%
}
.sub-name {
  color: $color-gray-new-3;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%
}
.status-progress{
  padding: 6px 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-new-7;
}
.status-progress{
  padding: 6px 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: $color-gray-new-7;
}
.status-completed {
  padding: 6px 10px;
  border-radius: 10px;
  justify-content: center;
  align-items: center;
  background-color: #D9F5E6;
  & p {
    color: $color-green-2! important;
  }
}
.status {
  font-weight: bold;
  color: #4caf50;
}

.inProgress {
  font-weight: bold;
  color: #ff9800;
}

.inProgressIcon {
  margin-right: 5px;
}

.circle-icon {
  min-width: 40px;
  min-height: 40px;
  display: flex;
  border-radius: 30px;
  background-color: $color-violet-light;
  margin-right: 8px;
}

.moreCount {
  font-size: 0.875rem;
  color: #757575;
}

.actionButton {
  padding: 8px 12px;
  background-color: transparent;
  color: $color-violet-6;
  border: 1px solid $color-violet-6;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  &:hover {
    background-color: $color-violet-6;
    color: $color-white;
  }
}
.actionButton:disabled {
  background-color: $color-gray-new-3;
  color: $color-gray-new-5;
  border: 1px solid $color-gray-new-3;
}
.checkbox {
  width: 16px;
  height: 16px;
  border: 1px solid $color-gray-11;
  background-color: $color-white;
}