.VideoStats{
  margin: 10px 0 0 0;

  &--views {
    margin: 0 0 0 10px;
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .VideoStats{
    &--views {
      margin: 0 0 0 8px;
      font-size: 12px;
    }
  }
}