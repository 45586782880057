.templates {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

