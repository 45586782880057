@import "@palette";

.root {
	h1 { 
		font-size: 30px;
		color: #fff;
	}
	min-height: 100vh;
	background: #F9F9F9;
}

.container {
	composes: globalWidth from global;
	background: #FFF;
	border-radius: 4px;

	padding: 24px 50px;
}

@media (max-width: 600px) {
	.addToList {
		display: none;
	}
	
	.container {
		composes: globalWidth from global;
		background: #FFF;
		margin-top: -30px;
		border-radius: 10px;
	
		padding: 0;
	}
}

.main {
	composes: globalWidth from global;
	padding-bottom: 30px;
}

.error {
	color: #E62626;
	font-size: 30px;
	text-align: center;
	padding: 20px;
}
.button {
	font-size: 14px;
	color: #302f35;
	padding: 6px 20px;
	transition: background .3s;
	background: #FFDA54;
	border-radius: 12px;
	cursor: pointer;
	&:hover {
		background: #F5C208;
	}
}

.back_button {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	color: $color-gray-3;
	text-decoration: none;
}

.over {
    height: 30px;
    width: 100%;
    background-color: #393870;
}