@media (min-width: 1651px) {
    .children {
        margin: 0 30px 0 230px;
    }
}

.content {
    &-icon {
        path {
            fill: #929EB0;
        }

        &-active {
            path {
                fill: white;
            }
        }
    }

}