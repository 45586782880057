.root {
	height: 24px;
	align-items: center;
	justify-content: center;
	padding: 0 10px;

	border: 1px solid #A2AEC2;
	border-radius: 6px;
	cursor: pointer;
}

.text {
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */

	color: #76849B;
}