.table {
    background-color: white;
    border-radius: 10px;
    padding: 16px;
    overflow: hidden;
    
    min-height: 300px;

    &-header {
        position: sticky;
        top: 0;
        border-radius: 4px;
        background: var(--Gray-7, #F2F4F7);
        height: 48px;
        width: 100%;
        padding: 0 16px;
        margin-top: 10px;

        display: grid;
        align-items: center;
        grid-template-columns: 3fr 1fr 1fr;
        gap: 20px;
    }

    &-item {
        border-radius: 4px;
        height: 60px;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 3fr 1fr 1fr;
        // gap: 20px;
    }
}

.avatar {
    cursor: pointer;

    img {
        border-radius: 50%;
        position: absolute;
        width: 40px;
        height: 40px;
        border: 2px solid #FFFFFF;
    }

    div {
        border-radius: 50%;

        svg {
            border-radius: 50%;
            border: solid 2px white;
        }
    }
}

.divider {
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}

.title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}