@import "@palette";

.influencersList {
  width: 480px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  margin-top: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
}

.avatars {
  margin-top: 16px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 50px;
  width: 100%;
  position: relative;
}

.avatarContainer {
  position: relative;
  width: 30px;
}

.avatar {
  img {
    border-radius: 50%;
    position: absolute;
    width: 40px;
    height: 40px;
    border: 2px solid #FFFFFF;
  }
}

.moreIcon {
  position: absolute;
  right: 0;
  z-index: 100;
  bottom: 0;
  cursor: pointer;
}

.controlIcon {
  path {
    fill: $color-violet-1;
  }
}