@import "@palette";

.root {
	border-radius: 10px;

	background-color: rgba($color-white, 0.1);

	height: 32px;

	padding: 0 12px;

	svg {
		margin: {
			right: 7px;
		}
	}

	path {
		fill: $color-white;
	}
}

.iconBlack {
	path:first-child {
		fill: $color-gray-3;
	}
}
