@import "@polette";

.root {
    min-height: 100vh;
}

.image {
    width: 38%;
    height: 100vh;
    
    background-color: $color-white;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}

@keyframes imageIntro {
    from {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

.container {
    background-color: $color-white;
    
    height: 100vh;

    &--default {
        composes: container;

        width: 62%;

        .scroll {
            padding: 20vh 0 50px;
        }
    }

    &--mobile {
        composes: container;
        width: 100%;
        background-color: #E5E5E5;
        .scroll {
            padding: 50px 0;
        }
    }
}

.scroll {
    overflow: hidden;
}

.form {
    width: 420px;

    animation: intro .6s ease-in-out;
    &--mobile{
        composes: form;
        width: 90%;
        padding: 16px;
        margin: 0 5% 0;
        background-color: white;
        border-radius: 10px;
    }
}

@keyframes intro {
    from {
        opacity: 0;
        margin-right: -200px;
    }
    to {
        opacity: 1;
        margin-right: 0;
    }
}

.svg {
    margin-bottom: 15px;
    margin-left: 15px;
}


.desktop_svg {
    width: 140px;
    height: 35px;
}

.text {
    margin-top: 60px;
    margin-left: 7%;
    margin-right: 40px;
}