@import "@palette";

.filterBase {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  cursor: pointer;
}

.divider {
  width: 1px;
  height: 42px;
  border-right: 1px solid $color-gray-4;
  margin-left: 10px;
}

.icon {
  margin-right: 7px;

  &--active {
    composes: icon;

    path {
      fill: $color-gray-1;
    }
  }
}

.arrow {
  transition: transform .3s;

  path {
    fill: $color-gray-4;
  }

  &--opened {
    composes: arrow;
    transform: rotate(180deg)
  }

  &--closed {
    composes: arrow;
    transform: rotate(0deg)
  }
}