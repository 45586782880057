.tableContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  min-height: 1000px;
}

.headerRow {
  display: grid;
  grid-template-columns: 0.3fr 0.2fr 1.7fr 1fr 1fr 1fr 1fr 1fr 1.8fr;
  align-items: center;
  padding: 0 16px;
  background-color: #F8F9FB;
  font-weight: bold;
  border-radius: 4px;
  margin-bottom: 1px;
  & > div {
    padding: 16px;
  }
}

.row {
  display: grid!important;
  grid-template-columns: 0.3fr 0.2fr 1.8fr 1fr 1fr 1fr 1fr 1fr 1.4fr;
  padding: 0 16px;
  border-radius: 4px;
  margin-bottom: 1px;
  background-color: #FFFFFF;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: translateX(1px);
    background-color: #F2F5FA;
  }
}

.ava {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  max-width: 50px;
  max-height: 50px;
  min-width: 50px;
  min-height: 50px;
}
.channelIcon {
  width: 34px;
  height: 34px;
  min-height: 34px;
  min-width:34px;
  border-radius: 50%;
  margin-right: 10px;
}
.cell {
  padding: 16px;
}
.button {
  max-width: 160px;
  max-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
}
.name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  -webkit-line-clamp: 3;
  text-overflow: ellipsis;
  max-width: 130px;
  max-height: 60px;
  word-wrap: break-word;
  line-height: 20px;
}