@import '@palette';
.modalContent {
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  width: 100%;
  max-width: 500px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  background-color: $color-violet-4;
  svg {
    width: 30px;
    height: 30px;
    path {
      fill: $color-violet-1;
    }
  }
}

.textWrapper {

}

.inputWrapper {

  input {
    margin-bottom: 20px;
    width: 100%;

    &::placeholder {
      color: $color-gray-new-3;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
    }
  }

  button {
    width: 100%;
    color: $color-white;
    background-color: $color-violet-1;
    &:disabled {
      background-color: $color-gray-new-3;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: $color-gray-new-5;
    }
  }
}
