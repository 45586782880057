@import '@palette';
.root {
    width: 100%;
    background-color: #fff;
    padding: 0 20px 0px 20px;
}

.carousel {
    overflow-y: auto;
}

.wrapper {
    position: relative;

    width: calc((100% - 40px) / 3);

    @media (max-width: 1000px) {
        width: 300px;
        margin-right: 5px;
    }
}

.post { 
    height: 100%;
    margin-bottom: 0;
}

.btns {

    position: absolute;
    left: 0;
    bottom: 50px;

    width: 100%;

    transition: opacity .5s;

    &--visible {
        composes: btns;
        opacity: 1
    }

    &--hidden {
        composes: btns;
        opacity: 0    
    }
}

.header {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;

	color: $color-gray-4;
}