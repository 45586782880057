.root {
	composes: card from global;
	padding: 15px 20px 40px;
	@media (max-width: 600px) {
		padding-bottom: 40px;
	}
}

.value {
	font-size: 30px;
	color: #2a2a31;
	font-weight: 600;
	margin-bottom: 14px;
}

.bar_container {
	display: flex;

	transition: filter 1s;

	&--hidden {
		composes: bar_container;
		filter: blur(10px)
	}
	&--visible {
		composes: bar_container;
		filter: blur(0px)
	}
}

.name {
	height: 28px;
	margin: 0 13px 3px 0;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	color: #888;
	font-size: 12px;
}

.graph_container {
	flex-grow: 1;
	display: flex;
	justify-content: space-between;
	position: relative;
}

.del {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: -23px;
}

.line {
	flex-grow: 1;
	width: 1px;
	background: #f1f1ee;
}

.str {
	padding-top: 15px;
	font-size: 12px;
	color: #888;
}

.bars {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.bar {
	height: 28px;
	margin-bottom: 3px;
	border-radius: 0 10px 10px 0;
}