@import "@palette";

.head {
	padding-top: 108px;
	padding-bottom: 58px;
	background-color: $color-common-background;
}

.grid {
	margin-top: -40px;

	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 20px;
	grid-auto-rows: 230px;
	@media (max-width: 1150px){
		grid-template-columns: 1fr 1fr;
	}
	@media (max-width: 760px){
		grid-template-columns: 1fr;
	}
}