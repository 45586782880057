.root {
	padding: 0 30px 10px;
	&_radial {
		display: flex;
		align-items: center;
		padding: 0px 30px 10px;
	}
	.icon {
		cursor: pointer;
		font-size: 17px;
		transition: color .1s;
	}
}

.title {
	font-size: 12px;
	color: #888;
	cursor: pointer;
}

.label {
	font-size: 12px;
	color: #888;
	font-weight: bold;
	margin-bottom: 6px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	a {
		text-decoration: none;
		font-size: 12px;
		color: #6172FF;
		font-weight: 400;
	}
}

.err_label {
	font-size: 12px;
	color: #F14141;
	font-weight: 400;
}

.inp {
	outline: none;
	border: none;
	border-radius: 10px;
	background: #f1f1ee;
	padding: 12px;
	width: 100%;
	font-size: 14px;
	color: #2a2a31;
	&_error {
		background: rgba(255, 0, 0, .1)
	}
}

.imp_cont {
	display: flex;
	align-items: center;
	border-radius: 10px;
	background: #f1f1ee;
	padding: 12px;
	width: 100%;
	&_error {
		background: rgba(255, 0, 0, .1)
	}
}

.pass_inp {
	flex-grow: 1;
	outline: none;
	border: none;
	background-color: transparent;
	font-size: 14px;
	color: #2a2a31;
	&:focused {
		outline: 1px solid black
	}
}

.rad_self {
	width: 18px;
	height: 18px;
	border-radius: 6px;
	margin-right: 10px;
	cursor: pointer;
	background: #f1f1ee;
}

.value {
	margin: 2px;
	width: 14px;
	height: 14px;
	background: #616DFF;
	border-radius: 5px;
	transition: opacity .2s;
}