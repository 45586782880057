.tags {
    background-color: white;
    margin-bottom: 20px;
    border-radius: 10px;

    div {
        border: none;
    }
}

.border {
    div {
        border-radius: 10px;
    }
}