@import '@palette';

.anim {
    background: linear-gradient(90deg, #E6EAF0 0%, #F2F4F7 14.06%, #D6DBE6 27.08%, #F2F4F7 39.58%, #E6EAF0 100%);
    background-size: 400% 400%;
    animation: gradient 10s linear infinite;
    border-radius: 10px;
}

@keyframes gradient {
    100% {
        background-position: -50% 50%;
    }

    0% {
        background-position: 150% 50%;
    }

}

.flex {
    display: flex;
    align-self: center;
    justify-self: center;
}

.root {
    width: 100%;
    height: 74px;
    /* White */
    background: #FFFFFF;
    border-radius: 4px;
    margin-top: 1px;
    padding: 0 25px 0 0;

    @media (max-width: $mobile-break) {
        display: flex;
        align-items: center;
        padding: 16px;
    }
}

.check {
    composes: flex;
    width: 16px;
    height: 16px;
    /* Gray 7 */
    background: #F2F4F7;
    border-radius: 4px;
}

.ava {
    // composes: anim;
    width: 40px;
    min-width: 40px;
    height: 40px;
    /* Gray 7 */
    background: #F2F4F7;
    border-radius: 20px;
}

.name {
    composes: anim;
    width: 70%;
    height: 30px;
    /* Gray 7 */
    // background: #F2F4F7;
    border-radius: 10px;
    margin: 0 5% 0 4%;

    @media (max-width: $mobile-break) {
        width: calc(100% - 40px);
    }
}

.rest {
    composes: anim;
    composes: flex;
    width: 800px;
    height: 30px;
    /* Gray 7 */
    // background: #F2F4F7;
    border-radius: 10px;
}