.allChats {
  width: 320px;
  height: 592px;
  background-color: white;

  border-radius: 10px;

  padding: 20px 8px;
}

.title {
  padding: 0px 16px;
}

.search {
  display: flex;
  flex-direction: row;
  width: calc(100% -40px);
  margin: 12px 12px 20px 12px;
  background-color: #F2F4F7;
  border-radius: 10px;
}

.searchIcon {
  path {
    fill: #A2AEC2;
  }

  padding: 10px 3px 10px 15px;
  box-sizing: content-box;
}

.searchInput {
  border: none;
  flex-grow: 1;
  outline: none;
  font-size: 14px;
  color: #888;
  min-width: 0;
  background: transparent;
  padding: 8px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}

.chats {
  width: 100%;
  height: 440px;
  padding: 0;
  overflow-y: scroll;
}