.upgradeTariff {
  width: 100%;
  height: 80px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background-color: white;
  border-radius: 10px;
  border: 2px solid #6071FF;
  margin-bottom: 10px;
  padding: 20px 30px;
}

.arrowContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #DFE2FF
}

.smallArrow {
  path {
    fill: white;
  }
  
  margin-left: 10px;
  height: 10px;
}