.root {
	width: 100%;
	max-height: 345px;
	margin-top: 10px;
	overflow-y: auto;

	background: #FFFFFF;

	box-shadow: 0px 4px 20px rgba(37, 58, 96, 0.2);

	border-radius: 10px;
}

.loader {
	display: flex;
	justify-content: center;
	margin: 20px 0 20px 0;
}

.item {
	border-bottom: 1px solid #F2F2F0;

	padding: 10px 20px 10px 10px;

	cursor: pointer;

	&__data {
		flex-grow: 1;
	}
}

.icon {
	margin-left: 6px;
}

.center {
	display: flex;
	padding: 20px 0;
	justify-content: center;
}

.avatar {
	border-radius: 50%;
}