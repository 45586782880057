@import "@palette";

.filter {
  margin: 6px 0 12px 16px; 
}

.icon {

  transition: transform .3s;

  path {
    fill: $color-gray-new-2;
  }

  &--opened {
    composes: icon;
    transform: rotate(180deg)
  }

  &--closed {
    composes: icon;
    transform: rotate(0deg)
  }
}