@import "@palette";

.root {
    min-height: 100vh;
    background: $color-common-background;
    padding-bottom: 50px;
    padding-top: 110px;
    
    @media (max-width: 600px) {
        padding-bottom: 100px;
    }

    composes: globalWidth from global;
}
.hat {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: {
        bottom: 20px;
    }
    h1 {
        font-size: 30px;
        line-height: 38px;
        font-weight: 600;
        color: $color-gray-1;
        margin: 0;
        font-family: RFDewiExpanded;
    }
    @media (max-width: 600px) {
        flex-direction: column;
        h1 {
            width: 295px;
            text-align: left;
            margin-bottom: 10px;
        }
    }
}
.packs {
    padding-top: 55px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    @media (max-width: 600px) {
        padding-top: 20px;
    }
}
.error {
    text-align: center;
    font-size: 14px;
    line-height: 18px;
    color: #888;
    font-weight: bold;
    width: 100%;
}
.errStyle{
    font-size: 60px;
    line-height: 100px;
}