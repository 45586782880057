.table {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 1px;
}