@import "@palette";

.campaignHider {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  border-radius: 4px;
  background-color: white;
  width: 100%;
  height: 500px;

  margin-top: 20px;
}

.btn {
  height: 56px;
}

.form {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

.formInput {
  background-color: white;
  border: 1px solid $color-gray-5;
  padding: 0;
  height: 56px;
  border-radius: 4px;
}

.formInput > input {
  padding-left: 20px;
  height: 56px;
  font-size: 18px;
}