@import "@palette";

.rangeSlider {
  padding: 6px 8px;
}

.inputs {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.input {
  width: 95%;

  border: 1px solid #B7C3D6;
  border-radius: 4px;
  font-size: 12px;
  color: $color-gray-1;
  line-height: 16px;
  padding: 8px;

  &--error {
    composes: input;
    border: 1px solid $color-red-1;

    
  }
}

.input--error:focus {
  border: 1px solid $color-red-1;
  outline: none;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  width: 100%;
}