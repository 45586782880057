.root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: #FFFFFF;
  border-radius: 4px;
  min-height: 320px;
  padding: 20px;
  text-align: center;
}

.icon {
  width: 100px;
  height: 100px;
  background: #F2F4F7;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
}