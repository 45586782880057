@import "@palette";
.item {
    padding: 12px 0;
    margin-bottom: -2px !important;
    :nth-child(1) {
        margin: 0;
    }
    cursor: pointer;

    &--active {
        composes: item;
        border-bottom: 2px $color-violet-1 solid;
    }

    &--text {
        font-size: 12px;
        line-height: 15px;
    }
}
.root {
    width: 100%;
    border-bottom: 2px $color-gray-6 solid;
    > :first-child {
        margin: 0 25px 0 0;
    }
    > :last-child {
        margin: 0 0 0 25px;
    }
    > * {
        margin: 0 25px;
    }
}

@media (max-width: 600px) {
    .item {
        &--text {
            font-size: 10px;
            line-height: 12px;
        }
    }
}
