@import '@palette';

.searchBar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 4px;
  padding: 3px 3px 3px 0;
  background-color: white;

  input {
    width: 100%;
    border: none;
      flex-grow: 1;
      outline: none;
      font-size: 14px;
      color: #888;
      padding: 9px 12px;
      min-width: 0;
      background: transparent;
    
      &::placeholder {
        color: #888;
        opacity: 0.6;
      }
  }
}

.searchIcon {
  path {
    fill: $color-gray-4;
  }

  padding: 10px 3px 10px 20px;
  box-sizing: content-box;
}