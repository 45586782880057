.dataChange {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 24px;
  margin-left: 10px;
  padding: 2px 8px;
  border-radius: 4px;

  background-color: rgba(78, 207, 130, 0.15);

  color: #4ecf81;

  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
}

.negative {
  background-color: rgba(239, 59, 70, 0.2);
  color: #ef3b46;
}

.blur {
  transition: filter 1s;

  &--active {
    composes: blur;
    filter: blur(10px);
  }
  
  &--default {
    composes: blur;
    filter: blur(0px);
  }
}
