.icon {
    height: 20px;
    width: 20px;


    path {
        fill: #929EB0;
    }

    &:hover {

        path {
            fill: #323841;
        }
    }
}

.arrow {
    position: absolute;
    width: 0;
    height: 0;
    margin-left: 5px;
    margin-top: -15px;

    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid #5D697D;
}

.tooltip {
    max-width: 250px;
    border-radius: 4px;
    padding: 10px 12px;
    margin-bottom: 14px;

    background-color: #5D697D;
}