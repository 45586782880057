@import "@palette";

.tag {
    height: 30px;
    padding: 0 10px;

    background-color: #A6ADB9;

    user-select: none;

    border-radius: 4px;

    margin-top: 12px;

    animation: intro .3s ease-in-out forwards;

    overflow: hidden;

    svg {
        cursor: pointer;
    }

    path {
        fill: #fff;
    }

    &:not(:last-child) {
        margin-right: 10px;
    }
}

@keyframes intro {
    from {
        opacity: 0;
        max-height: 0;
    }
    to {
        opacity: 1;
        max-height: 30px;
    }
}