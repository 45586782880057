@import "@palette";

.global{
    @media (max-width: 1650px) {
        margin: 0 !important;
    }
}

.link{
    width: 460px;
    height: 430px;
    background-color: white;
    border-radius: 10px;
    padding: 60px 30px 36px;
}


.root-link{
    min-height: 100vh;
    background: $color-common-background;
    padding:120px 120px;
    @media (max-width: 1650px) {
        padding: 0;
    }
}

.icon{
    margin-top: 60px;
    width: 100%;
    margin-bottom: 30px;
}

.link-flex{
    height: 100%;
    flex-grow: 1;
    width: 100%;
}
.circle{
    background-color: #E8EBFF;
    border-radius: 50%;
    height: 80px;
    width: 80px;
}
.big{
    width: 42.6px;
    height: 42.6px;
}
.empty--flex{
    height: 100%;
    padding: 40px
}
.input {
    outline: none;
    padding: 17px 20px;
    height: 42px;
    width: 100%;
    border:1px solid #CCCCCC;
    border-radius: 4px;
    background-color: transparent;

    color: $color-gray-1;
    
    &::placeholder {
        color: $color-gray-4;
        z-index: -1;
    }
}

.balance{
    background-color: $color-gray-9;
    width: 500px;
    height: 140px;
    margin-left: 40px;
    border-radius: 4px;
    padding: 20px 30px 30px;
}

.list--head{
    border-radius: 4px 0 ;
    background: #EFF1FF;
}

.list{
    border-radius: 4px;
    background: #FAFAFA;
    width: 100%;
}

.checkout{
    max-width: 200px;
}

.stick{
    width: 2px;
    height: 90px;
    background-color: $color-gray-6;
}

.balance-text{
    margin-bottom: 8px;
    height: 100%;
}

.stat-part-small{
    background-color: $color-gray-9;
    border-radius: 4px;
    padding: 30px;
    width: 180px;
    height: 140px;
}

.stat-part{
    background-color: $color-gray-9;
    border-radius: 4px;
    padding: 30px;
    width: 280px;
    height: 140px;
}

.create-flex{
    padding: 20px;
    border-radius: 4px;
    background-color: $color-violet-4;
    min-width: 580px;
    height: 82px;
    margin-top: 14px;
}

.main{
    background-color: white;
    height: 600px;
    width: 100%;
    margin-top: 24px;
    padding: 30px 40px;
    border-radius: 10px;
}

.title{
    font-family: RFDewiExpanded;
    color: $color-gray-1;
    font-size: 30px;
    font-weight: 700;
    line-height: 36px;
}

.flex{
    height: 100%;
}