.root {
	padding: 5px 0;

	width: 60px;

	border-radius: 4px;

	font-size: 14px;
	line-height: 18px;

	text-align: center;
}