@import "@palette";

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  width: 500px;
  height: 395px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;

  position: relative;
}

.close {
  cursor: pointer;

  path {
    color: #A2AEC2;
  }
}

.inputField {
  width: 100%;
  flex-grow: 1;
  outline: none;
  font-size: 14px;
  color: $color-gray-1;
  min-width: 0;
  background: transparent;
  padding: 8px;

  border: 1px solid #B7C3D6;
  border-radius: 4px;

  &--correct {
    composes: inputField;
    border: 1px solid $color-green;
  }

  &--error {
    composes: inputField;
    border: 1px solid $color-red-1;
  }

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}

.security {
  background-color: #F8F9FB;
  border-radius: 4px;
  padding: 16px 20px;
}

.sendIcon {
  margin-left: 5px;

  path {
    fill: white;
  }
}