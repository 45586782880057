.root {
    position: absolute;
    top: -100%;
    left: -100%;
    width: 1000vw;
    height: 1000vh;
    padding: 30px;
    z-index: 110;

    background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
    background-color: white;
    padding: 30px;
    border-radius: 4px;
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #323841;
    margin: 0;
}

.close {
    cursor: pointer;

    path {
        color: #929EB0;
    }
}

.input {
    padding: 9px 10px;
    flex-grow: 1;

    color: #2a2a31;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;


    height: 36px;

    background: #FFFFFF;
    border: 1px solid #AEBACC;
    border-radius: 4px;

    &:placeholder {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;

        color: #929EB0;
    }
}

.label {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;

    color: #323841;
    margin: 22px 0 6px 0;
}

.labelSub {
    color: var(--Gray-2, #5D697D);
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    margin-bottom: 0;
    /* 142.857% */
}

.divider {
    margin: 30px 0 20px 0;
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}

.submit {
    width: 210px;
}

.tags {
    div {
        min-height: unset;
        border: 1px solid #AEBACC;
        border-radius: 4px;

        div {
            border: none;
        }
    }
}

.alert{
    width: 30px;
    height: 30px;
    path{
        fill: #6071FF;
    }
}

.done{
    width: 30px;
    height: 30px;
    path{
        fill: #70D9A0;
    }
}

.er{
    width: 30px;
    height: 30px;
    path{
        fill: #F5535D;
    }
}