.like {
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 4px;
  background: #F2F4F7;

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: #AEBACC;
    }
  }

  &-active {
    composes: like;
    background: #FFE1E7;

    svg {
      path {
        fill: #F8848C;
      }
    }
  }
}