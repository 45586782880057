@import "@palette";

.input {
    border: none;
    outline: none;

    flex-grow: 1;

    background-color: transparent;

    color: $color-gray-1;
    
    &::placeholder {
        color: $color-gray-4;
        z-index: -1;
    }
}

.icon {
    path {
        fill: $color-gray-4;
    }
}