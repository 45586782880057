@import "@palette";
.carousel {
	display: flex;
	overflow-x: scroll;
}

.info_board{
	composes: card from global;
	display: flex;
	padding: 20px 15px;
	@media (max-width: 600px) {
		flex-wrap: wrap;
	}
}
.inform {
	flex-grow: 1;
	font-size: 16px;
	color: #888;
	h3 {
		font-size: 26px;
		color: #2a2a31;
		margin: 0 0 6px 0;
	}
	@media (max-width: 600px) {
		width: 50%;
		h3 {
			font-size: 30px;
		}
	}
}

.row {
	width: 100%;
	display: flex;
	background-color: #fff;
}

.divider {
	width: 20px;
}

.standart_label {
	display: flex;
	align-items: center;
	margin-bottom: 15px;
	h3 {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 26px;
		color: $color-gray-4;
		margin: 0;
		@media(max-width: 600px){
			font-size: 14px;
			line-height: 24px;
		}
	}
}

.title {
	background: #6172ff;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
	border-radius: 10px;
	padding: 12px 20px;
	margin-bottom: 8px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.self {
	font-size: 18px;
	color: #fff;
}

.buttons {
	display: flex;
	align-items: center;
}

.switch_btn {
	padding: 7px 28px;
	font-size: 12px;
	font-weight: bold;
	cursor: pointer;
	transition: background .3s ease-in-out;
	&--default {
		composes: switch_btn;
		background: #F1F1EE;
		color: #888;
	}
	&--active {
		composes: switch_btn;
		background: #FFDA54;
		color: #2a2a31;
	}
}