.root {
	background: #fff;
	box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	cursor: pointer;
	padding: 6px;
	max-height: 200px;
	overflow-y: auto;
	.option {
		color: #888;
		font-size: 14px;
		opacity: .8;
		margin: 3px;
		padding: 3px;
		transition: background .3s;
		border-radius: 5px;
		&:hover{
			background: #ECEBEB;
		}
	}
}

.btn {
	padding: 8px 12px;
	font-size: 12px;
	color: #2a2a31;
	background: #f1f1ee;
	border-radius: 10px;
	display: flex;
	align-items: center;
	border: 2px solid #F1F1EE;
	justify-content: space-between;
	transition: border .3s;

	&--focused {
		border: 2px solid #6172FF
	}
	&--disabled {
		composes: btn;
		opacity: .4;
		cursor: default;
	}
	&--active {
		composes: btn;
		cursor: pointer;
	}
	.arrow {
		margin-left: 10px;
		color: #888;
		&_up {
			transform: rotate(180deg);
			transition: transform .5s;
		}
		&_down {
			transform: rotate(0deg);
			transition: transform .3s;
		}
	}
}