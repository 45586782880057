.topStats {
  padding: 20px 30px;
  background: white;
  border-radius: 4px;

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  margin-top: 20px;
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loading {
  width: 40px;
  height: 10px;
  background: linear-gradient(90deg, #E6EAF0 0%, #F2F4F7 14.06%, #D6DBE6 27.08%, #F2F4F7 39.58%, #E6EAF0 100%);
  background-size: 400% 400%;
  animation: gradient 10s linear infinite;
  border-radius: 10px;
}

@keyframes gradient {
  100% {
    background-position: -50% 50%;
  }

  0% {
    background-position: 150% 50%;
  }

}