.root {
    background: #F1F1EE;
    border-radius: 4px;

    cursor: pointer;
    
    &--small {
        composes: root;   
        width: 14px;
        height: 14px;
        svg {
            width: 9px;
        }
    }
    &--big {
        composes: root;    
        width: 20px;
        height: 20px;
    }
}