@import '@palette';

.row {
  display: grid;
  grid-template-columns: 5% 30% 25% 20% 20%;
  align-items: center;
  padding: 16px 18px;
  text-align: left;
  background-color: $color-white;
  transition: background-color 0.3s;
  &:hover {
    background-color: #f5f5f5;
  }
  &:not(:last-child) {
    margin-bottom: 1px;
  }

}

.downloadBtn {
  border: 1px solid $color-violet-1;
  color: $color-violet-1;
  background-color: $color-white;
  padding: 9px 12px 9px 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: fit-content;
  margin-left: auto;
  cursor: pointer;

  path {
    fill: $color-violet-1;
  }

  &:hover {
    background-color: $color-violet-1;
    color: $color-white;

    path {
      fill: $color-white;
    }
  }

  &:disabled {
    border: 1px solid $color-gray-new-3;
    background-color: $color-gray-new-3;
    color: #D6DBE6;
    cursor: not-allowed;
    path {
      fill: #D6DBE6;
    }
  }
}
.status {
  padding: 6px 10px;
  border-radius: 10px;
  display: flex;
  width: fit-content;
}

.green {
  background-color: #D9F5E6;
  color: #10BF61;
}

.gray {
  color: $color-gray-new-3;
  background-color: $color-gray-new-7;
}
.red {
  color: $color-red-1;
  background-color: $color-red-2;
}

.icon-wrap {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}
.ready {
  background-color: #F0EAF4;
  path {
    fill: #9D74B7;
  }
}
.process {
  background-color: #FCEED9;
  path {
    fill: #EC8E00;
  }
}
.icon {
  width: 13px;
  height: 13px;
  z-index: 5;
}