@import "@palette";

.tooltip {
  padding: 16px 0 16px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.date {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600px;
  color: $color-violet-1
}

.title {
  font-size: 12px;
  color: $color-gray-3;
  margin-right: 16px;
}

.duration {
  font-size: 14px;
  font-weight: 600px;
  color: $color-violet-1
}