.datePickerContainer {
  position: relative;
  width: 250px;
  margin: 0 auto;
}
.wrapper {
  border-radius: 10px;
  background-color: #fff;
  padding-right: 16px;
  & svg {
    cursor: pointer;
  }
}
.dateInput {
  width: 100%;
  padding: 10px 12px;
  border: none;
  outline: none;
  font-size: 14px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 10px;
  color: #929EB0;
  &::placeholder {
    color: #929EB0;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
  }

}

.dateInput:focus {
}

.calendarContainer {
  position: absolute;
  top: 45px;
  left: -50px;
  z-index: 10;
  max-width: 250px;
  background: #fff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

}
.clearIcon {
  width: 16px;
  height: 16px;
}