@import "@palette";

.add {
    cursor: pointer;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */
    display: flex;
    align-items: center;

    /* Gray 3 */
    color: #929EB0;
    margin: 0;
}

.container {
    width: 100%;
    height: 28px;
    padding: 2px;

    /* White */
    background: #FFFFFF;
    /* Gray 4 */
    border: 1px solid #AEBACC;
    box-shadow: 2px 2px 10px rgba(118, 132, 155, 0.2);
    border-radius: 4px;

}

.doneButton {
    cursor: pointer;
    width: 22px;
    height: 22px;

    background: $color-violet-1;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;

    path {
        fill: white;
    }
}

.crossButton {
    cursor: pointer;
    width: 22px;
    height: 22px;

    background: $color-gray-6;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    path {
        fill: $color-gray-3;
    }
}

.tags {
    width: 50%;

    &-open {
        composes: tags;
        width: unset;
        max-width: 50%;
    }

    overflow: scroll;
    margin-right: 2px;

    &::-webkit-scrollbar {
        display: none;
    }

    &-container {
        max-width: 93%;
        overflow: scroll;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--tag {
        p {
            margin: 0;
            font-family: 'Montserrat';
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 16px;
            /* identical to box height, or 133% */

            /* White */
            color: #FFFFFF;
            white-space: nowrap;
        }

        svg {
            cursor: pointer;

            path {
                fill: white;
            }
        }

        // width: 130px;
        padding: 0 10px;
        margin-right: 2px;

        /* Violet 2 */
        background: #7685FF;
        border-radius: 4px;

        &-grey {
            composes: tags--tag;
            background: #F2F4F7;
            border-radius: 10px;
            padding: 6px 10px;

            p {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;

                color: #5D697D;
            }
        }
    }
}

.input {
    flex-grow: 1;
    width: 50%;

    input {
        height: 90%;
        width: 85px;
        border: none;
        outline: none;
    }
}

.edit {
    path {
        fill: #5D697D;
    }

    cursor: pointer;
}