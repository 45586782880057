@import '@palette';

.root {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 8px;
    padding-left: 15px;
    width: 280px;
    &--default {
        composes: root;
        input {
            color: $color-gray-4;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
        }
    }

    input {
        background-color: transparent;
        outline: none;
        border: none;
        border-radius: 10px;
        flex-grow: 1;
        width: 150px;
        height: 42px;

        color: $color-gray-4;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }
    @media (max-width: 600px) {
        button{
            width: 100px;
            padding: 0;
            margin: auto;
        }
    }
}

.container {
    position: relative;
}

.opener {
    position: absolute;
    color: $color-gray-3 !important;
    cursor: pointer;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    top: calc(50% - 9px);
    right: 0;

    @media (max-width: 600px) {
        right: unset;
        left: 0;
    }
}
