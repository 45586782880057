.listItemPopup:hover {
  background: #F3F3F3;
  border-radius: 4px;
}

.listItemPopup {
  cursor: pointer;
  padding: 8px 16px;
}
.error{
  color: rgb(255, 97, 97) !important;
  margin: 2px 0px 0px;
  font-size: 12px;
}
.add-list{
  background-color: #EFF1FF;
  border-radius: 4px;
  padding: 6px 10px 6px 6px;
  display: flex;
  align-items: center;
  width: fit-content;
  height: 28px;
}