@import "@palette";

.row {
  display: grid;
}

.item {
  background-color: white;
  border-radius: 4px;
  align-items: center;
  padding: 12px 16px;
  // width: 100%;
  // min-width: 1180px;
  width: fit-content;

}

.ava {
  div {
    border-radius: 24px;
  }

  img {
    border-radius: 24px;
  }

  svg {
    top: 0;
    border-radius: 24px;
  }

  @media (max-width: $mobile-break) {
    div {
      border-radius: 10px;
    }

    div:not(:first-child) {
      //twitch
      position: absolute;
      top: 10px;
      left: 58px;
    }

    div:first-child {

      //avatar filler
      svg {
        top: unset;
        left: unset;
      }
    }

    img {
      border-radius: 10px;
    }

    svg {
      top: 10px;
      left: 58px;
    }
  }
}