@import "@palette";

.createTemplate {
  width: 680px;
  // height: 592px;
  height: fit-content;
  border-radius: 10px;
  background-color: white;
  padding: 24px 30px;
}

.blockTitle {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  gap: 4px;
}

.inputBlock {
  display: grid;
  grid-template-rows: auto;
  gap: 6px;
}

.firstBlock {
  margin-bottom: 20px;
}

.secondBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.letterTemplate {
  display: grid;
  grid-template-rows: auto;
  gap: 6px;
  margin-top: 20px;

  &--border{
    border: 1px solid #AEBACC;
    border-radius: 4px;
  }
}

.inputField {
  width: 100%;
  flex-grow: 1;
  outline: none;
  font-size: 14px;
  color: $color-gray-1;
  min-width: 0;
  background: transparent;
  padding: 8px;

  border: 1px solid #B7C3D6;
  border-radius: 4px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }

  &--in-editor {
    composes: inputField;
    border: none;

    &--line {
      width: 98%;
      margin: auto;
      border-bottom: 1px solid #D6DBE6;
    }
  }
}

hr.divider {
  width: 680px;
  margin-left: -30px;
  border: none;
  border-top: 2px solid #F2F4F7;
}

.save {
  display: grid;
  grid-template-columns: min-content max-content;
  align-items: center;
  gap: 8px;

  margin-bottom: 58px;
}

.checkbox {
  border: 1px solid red;
}

.sendIcon {
  margin-left: 5px;

  path {
    fill: white;
  }
}