.emptyChat {
  display: flex;
  justify-content: center;
  flex-direction: column;

  width: 270px;
  margin: 230px auto;
}

.icon {
  display: flex;
  justify-content: center;
  margin-bottom: 24px;
}