.GeoInfo {
  margin-bottom: 16px;
  &--country {
    margin-left: 8px;

    font-size: 12px;
    line-height: 20px;
  }

}

@media (max-width: 600px) {
  .GeoInfo {
    &--country {
      font-size: 10px;
      line-height: 14px;
    }
    &--icon {
      height: 12px;
    }
  }
}