@import "@palette";

.header {
  padding-top: 108px;
  position: relative;

  background-color: $color-common-background;

  display: flex;
  flex-direction: row;

  margin-bottom: 20px;
}

.title {
  font-size: 30px;
  line-height: 40px;
  text-decoration-line: underline;
  text-decoration-thickness: 2px;

  /* Gray 3 */
  color: #929EB0;
}

.arrow {
  path {
    fill: #929EB0;
  }
}

.heading {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.back_button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: $color-gray-3;
  text-decoration: none;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;

  background: #C9D49B;
  border-radius: 30px;

  path {
    fill: white;
  }

  svg {
    width: 25px;
    height: 25px;
  }
}