@import "@palette";

.head {
	background-color: $color-common-background;
	padding: {
		top: 108px;
		bottom: 71px;
	};
}

.center {
	display: flex;
	justify-content: center;

	padding: 20px 0;
}

.matrix {
	display: flex;
	align-items: center;

	margin-bottom: 15px;
	margin-top: 30px;

	h2 {
		font-weight: normal;
		font-size: 22px;
		line-height: 28px;
		color: #2A2A31;

		margin: 0 6px 0 0;
	}
}

.matrix-container {
	display: flex;

	width: 100%;

	overflow-x: auto;
}

.tooltip {
	background-color: #fff !important;
	box-shadow: 0 0 10px rgba(0, 0, 0, .1);
	color: #2a2a31 !important;
}

.arrow {
	color: #fff !important;
}

.popper {
	z-index: 800 !important;
}

.icon {
	path {
		fill: $color-gray-4;
	}
}

.paper {
	width: 115px;

	background: $color-white;

	border: 1px solid $color-gray-6;

	box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

	border-radius: 4px;

	padding: 6px;

	margin-top: 6px;
}

.item {
	line-height: 32px;

	padding: 0 10px;

	cursor: pointer;

	border-radius: 4px;

	&:hover {
		background-color: $color-gray-8;
	}
}

.subline {
	height: 33px;
}

.download_icon {
	path {
		fill: white;
	}
}