@import '@palette';

$width-mobile: 744px;

.root {
    border-radius: 10px;
    max-width: 50%;
    flex-grow: 1;
    padding: 3px 3px 3px 0;
    &--default {
        composes: root;
        background: #fff;
    }
    &--focused {
        composes: root;
        background: #fff;
        border: 2px solid #6071FF;
    }

    input {
        border: none;
        flex-grow: 1;
        outline: none;
        font-size: 14px;
        color: #888;
        padding: 9px 12px;
        min-width: 0;
        background: transparent;
        &::placeholder {
            color: #888;
            opacity: 0.6;
        }
    }

    @media (max-width: 770px) {
        width: 100%;
    }

    @media screen and (max-width: $width-mobile) {
        max-width: 100%;
    }
}

.search_icon {
    path {
        fill: $color-gray-4;
    }
    padding: 10px 3px 10px 20px;
    box-sizing: content-box;
}

.massParsing {
    cursor: pointer;
}

@media (max-width: 600px) {
    .massParsing {
        display: none;
    }
}