.root {
	text-align: center;
	font-size: 14px;
	line-height: 18px;
	color: #888;
	font-weight: bold;

	span {
		font-size: 60px;
		line-height: 100px;
	}
}