.listItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  background-color: #F8F9FB;

  margin-bottom: 2px;

  border-radius: 4px;
  padding: 12px 16px;
}

.listItemNoEmail {
  composes: listItem;
  background-color: #FFF3F5;
}

.close {
  cursor: pointer;

  path {
    color: #A2AEC2;
  }

  width: 15px;
  height: 15px;
}

.avatar {
  img {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }

  margin-right: 10px;
}

.name {
  display: flex;
  flex-direction: column;
}

.input {
  width: 100%;
  margin-bottom: 0;
}