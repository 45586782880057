.root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	background: linear-gradient(169.28deg, #F9947D 13.4%, #616DFF 86%);
	h1, h2 {
		color: #fff;
	}
	h1 {
		font-size: 50px;
		margin: 0;
	}
	h2 {
		font-size: 20px;
		font-weight: normal;
		opacity: .9;
		margin: 10px;
	}
}