@import "@polette";

.back {
    margin: 0 30px 20px;
    border-radius: 12px;
    background: #f5f5f3;
    width: 80px;
    padding: {
        top: 6px;
        right: 20px;
        bottom: 7px;
        left: 12px;
    }
    display: flex;
    align-items: center;
}

.text {
    margin-left: 12px;
    font-size: 14px;
    color: #888;
    text-decoration: none;
}

.field{
    width: 420px;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    background-color: white;
    padding: 16px 0px;
    &--mobile{
        composes: field;
        width: 100%;
        height: 36px;
        padding: 6px 10px;
    }
}

.btn {
    height: 56px;
    border-radius: 4px;
    background-color: $color-link;
    color:white;
    font-size: 16px;
    &--mobile{
        font-size: 12px;
        composes: btn;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &--disabled {
        composes: btn;
        background-color: #CCCCCC;
    }
    &--disabled--mobile {
        composes: btn;
        background-color: #CCCCCC;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &:not(.btn--disabled):hover {
        background: $color-link-hover;
    }
    
}