$width-mobile: 743px;

.adapter {
    &--head {
        display: unset;
    }
}
@media screen and (max-width: $width-mobile) {
    .adapter {
        &--head {
            display: none;
        }
    }
}
