@import '@palette';

.transition {
    transition: background-color 0.3s;
}
.root {
    composes: transition;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 8px 7px;
    margin-left: -7px;
    width: 70px;
    height: 36px;
    left: 220px;
    top: 333px;

    background: $color-gray-9;
    border-radius: 4px;
    &--active {
        composes: root;

        border: 2px solid $color-gray-5;
        box-sizing: border-box;
        border-radius: 4px;
    }
}


.label {
    composes: transition;
    flex-grow: 1;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    justify-content: center;
    color: #2a2a31;
}