
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  padding: 30px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  &:before {
    content: "";
    position: absolute;
    bottom: 90px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #E6EAF0;
  }
}

.closeButton {
  cursor: pointer;
  width: 24px;
  height: 24px;
  color: #929EB0;
}

.title {
  font-size: 18px;
  font-weight: bold;
}

.content {
}
