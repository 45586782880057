@import "@palette";

.inputField {
  width: 100%;
  outline: none;
  font-size: 14px;
  color: $color-gray-1;
  min-width: 0;
  background: transparent;
  padding: 8px;

  border: 1px solid #B7C3D6;
  border-radius: 4px;
  height: 36px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}

.inputDisabled {
  composes: inputField;
  background-color: #E6EAF0;

  &::placeholder {
    color: #929EB0;
    font-size: 14px;
  }
}

.input {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 6px;

  label {
    font-size: 12px;
  }
}