.locker {
	flex-grow: 1;
}
.popup {
	width: 270px;
	background: #fff;
	box-shadow: 0 4px 20px rgba(0,0,0,.25);
	border-radius: 10px;
	padding: 12px;
	h3 {
		margin: 0 0 12px;
		color: #2a2a31;
		font-size: 18px;
		font-weight: 400;
	}
}
.btns {
	display: flex;
	justify-content: space-between;
	padding-bottom: 4px;
}
