.row {
  display: grid;
  grid-template-columns: 52px 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 1px;
  padding: 16px 20px 16px 0;
  background-color: #FFFFFF;
  border-radius: 4px;

  &:last-child {
    border-bottom: none;
  }
}

.cell {
  flex: 1;
  display: flex;
  align-items: center;

  &:nth-child(6) {
    flex: 1;
    justify-content: flex-end;
  }
}

.inn {
  font-size: 12px;
  color: #999;
}

.keywords {
  display: flex;
  gap: 8px;
  align-items: center;
}

.keyword {
  background-color: #F2F4F7;
  padding: 6px 10px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #5D697D;
}

.bloggers {
  display: flex;
  align-items: center;
  gap: 4px;

  .avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
    border: 2px solid white;

    &:not(:first-child) {
      margin-left: -15px;
    }
  }

}

.openReport {
  padding: 9px 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;

  &:disabled {
    cursor: not-allowed;
    background-color: #929EB0;
    color: #D6DBE6;
  }
}
.moreCount {
  font-size: 10px;
  color: #5D697D;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  border: 1px solid #D6DBE6;
  border-radius: 10px;
  padding: 6px 10px;
}
.keywords-tooltip {
  display: flex;
  max-width: 300px;
  flex-wrap: wrap;
  gap: 6px;
}