:global {
	.card {
		margin-bottom: 20px;
		position: relative;
	}

	.globalWidth {
		margin: 0 auto;
		width: 1180px;
		overflow: visible;
		@media (max-width: 1442px) {
			width: 100%;
			padding: {
				left: 10px;
				right: 10px;
			}
		}
	}
}

.loadingBarContainer {
	display: flex;
	justify-content: center;
	padding: 20px;
}

.globalWidth {
	margin: 0 auto;
	width: 1200px;
	@media (max-width: 1200px) {
		width: 100%;
		padding: {
			left: 10px;
			right: 10px;
		}
	}
}

.linkPreset {
	color: #6172FF;
	transition: color .3s;
	font-size: 18px;
	cursor: pointer;
	text-decoration: none;
	font-weight: bold;
	&:hover {
		color: #525EC2;
	}
}

.infoStyle {
	background: #fff;
	padding: 10px;
	font-size: 10px;
	color: #888;
	z-index: 1600;
	border-radius: 10px;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.35);
	width: 200px;
}

.userBgPreset {
	min-height: 100vh;
	padding-bottom: 20px;
	display: flex;
	justify-content: center;
    background: linear-gradient(169.28deg, #F9947D 13.4%, #616DFF 86%);
}
.content {
	width: 360px;
    &_logo {
    	display: flex;
    	align-items: flex-end;
    	justify-content: center;
    	margin: 50px 0;
    }
}