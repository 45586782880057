@import "@palette";

.root {
	width: 100%;

	background: #FFFFFF;

	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

	border-radius: 4px;

	overflow: hidden;
}

.hat {
	background-color: $color-gray-6;

	&__item {
		word-spacing: 100px;
		&--pointer {
			composes: hat__item;
			cursor: pointer;
		}
	}
}

.item {
	&:not(:last-child) {
		border-bottom: 1px solid $color-gray-7;
	}
}