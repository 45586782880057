@import "@palette";

$height: 76px;
$height-mobile: 65px;
$width-mobile: 743px;

.root {
    width: 100%;
    background: $color-violet-3;
    padding: 8px;
    justify-content: space-between;

    position: fixed;
    top: 0;
    z-index: 2000;
}

@media screen and (min-width: $width-mobile+1) {
    .root {
        // margin: 0 -30px;
        width: 100vw;
        background: $color-violet-3;
        padding: 8px;
        justify-content: center;
        column-gap: 12px;
    }
}


@media screen and (max-width: '600px') {
    .root {
        width: 100%;
        background: $color-violet-3;
        padding: 8px;
        flex-direction: column;
    }

    .btn {
        width: 100%;
    }
}