@import "@palette";

.root {
    background-image: url(./bgImage.svg);
    background-repeat: no-repeat;
    background-position: bottom right;

    background-color: $color-white;

    padding: 30px 54px 24px;

}

.container {
    max-width: 588px;
}

.tiktok {
    border-radius: 4px;
    background: var(--Violet-Light-2, #DFE2FF);
    display: flex;
    width: 100%;
    padding: 18px 20px 18px 30px;
    justify-content: center;
    align-items: center;

    margin-top: 10px;
    cursor: pointer;

    p {
        color: var(--Violet, #6071FF);
        text-align: center;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 0;
    }

    &:hover {
        border-radius: 4px;
        background: var(--Violet, #6071FF);

        p {
            color: white;
        }

        svg {
            path {
                fill: white;
            }
        }
    }

}