@import "@palette";

.sidebar {
  position: absolute;
  top: 0px;
  min-width: 230px;
  height: 100vh;
  background-color: $color-common-background;
  padding-left: 30px;
}

.links {
  position: fixed;
}

.links--bottom {
  position: fixed;
  bottom: 0;
}

.link {
  position: relative;

  height: 100%;

  font-weight: 600;
  font-size: 12px;
}

.title {
  margin-left: 10px;

  &--active {
    margin-left: 10px;
    color: $color-violet-1 !important;
  }
}

@media (max-width: 1440px) {
  .sidebar {
    width: 12vw;
  }
}

@media (max-width: 1279px) {
  .sidebar {
    display: none;
  }
}

.icon {
  path {
      fill: $color-gray-1;
  }

  &--active {
    path {
      fill: $color-violet-1!important;
    }
  }

  &--white {
      path {
          fill: $color-white;
      }
  }
}

.icon--bottom {
  path {
      fill: #76849B;
  }
}

.title--bottom {
  margin-left: 10px;
  color: #76849B !important;
}

.new {
  background-color: #F87B36;
  border-radius: 2px;
  font-size: 10px;
  color: white;
  margin-left: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26px;
  height: 15px;
  line-height: 12px;
}