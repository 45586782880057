.root {
	background: #F1F1EE;
	display: flex;
	opacity: .7;
	border-radius: 10px;
	width: fit-content;
	align-items: center;
}
.point {
	white-space: nowrap;
	border-radius: 10px;
	padding: 9px 20px;
	margin: 2px;
	text-transform: none;
	transition: opacity .3s, background .3s;
	font-size: 14px;
	&--default {
		composes: point;
		opacity: .6;
		color: #888;
		&:hover {
			background: #D3D3D2
		}
	}
	&--active {
		composes: point;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
		background: #fff;
		opacity: 1;
		color: #2a2a31;
	}
}
.border {
	width: 1px;
	height: 20px;
	background: #888;
	opacity: .4;
	&_empty {
		width: 1px;
		height: 20px;
		background: transparent;
		opacity: .4;
	}
}