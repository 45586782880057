.root {
	border-radius: 12px;
	background: #fff;
	box-shadow: 0 4px 10px rgba(0,0,0, .4);
	width: 160px;
}

.header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-wrap: wrap;
	padding: 10px 12px;
	border-bottom: 1px solid #f1f1ef;
	&_label {
		font-size: 12px;
		color: #2a2a31;
		font-weight: bold;
		line-height: 15px;
	}
	&_btn {
		background: #ffda54;
		width: 26px;
		height: 26px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}
}

.popper_lists {
	padding: 5.5px 5px 18px;
}

.context_item {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 12px;
	border-radius: 6px;
	padding: 4.5px 7px;
	transition: background .3s;
	cursor: pointer;
	&_icon {
		color: #6172FF;
		font-size: 18px;
		transition: opacity .1s;
		box-sizing: content-box;
	}
	&:hover {
		background: #F1F1EE;
	}
	span {
		transition: color .3s;
	}
}

.writer {
	width: 100%;
	display: flex;
	align-items: center;
	background: #F1F1EE;
	border-radius: 6px;
	padding: 5px 7px;
	input {
		min-width: 0;
		outline: none;
		flex-grow: 1;
		font-size: 12px;
		color: #2a2a31;
		border: none;
		background: transparent;
		&:placeholder {
			font-size: 14px;
			color: #888;
			opacity: .7;
		}
	}
	.icon {
		font-size: 16px;
		color: #888;
		cursor: pointer;
	}
}

.newset {
	font-size: 12px;
	color: #6172FF;
	line-height: 15px;
	padding: 0 7px;
	cursor: pointer;
}

.add-link {
	font-size: 12px;
	line-height: 15px;
	color: #6172FF;
	margin-left: 7px;

	cursor: pointer;
	transition: color .3s;
	&:hover {
		color: #495CFC;
	}
}