@import "@palette";

.root {
    /* White */
    background: #FFFFFF;
    /* Gray 4 */
    border: 1px solid #AEBACC;
    box-shadow: 2px 2px 10px rgba(118, 132, 155, 0.2);
    border-radius: 4px;

    padding: 2px;
    input {
        width: 100%;
        border: none;
        outline: none;
    }
}

.comment {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    /* identical to box height, or 133% */

    /* Gray 2 */
    color: #5D697D;
    margin: 0 0 0px 6px;

    &--root {
        cursor: pointer;
    }

    @media (max-width: $mobile-break) {
        margin: 0 0 0 6px;

        &--root {
            margin-top: 12px;
            margin-left: 0;
        }
    }
}

.doneButton {
    cursor: pointer;
    width: 22px;
    height: 22px;

    background: $color-violet-1;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2px;

    path {
        fill: white;
    }
}

.crossButton {
    cursor: pointer;
    width: 22px;
    height: 22px;

    background: $color-gray-6;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;

    path {
        fill: $color-gray-3;
    }
}
