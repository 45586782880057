.checkboxContainer {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
}

.checkboxLabel {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.checkboxContainer:hover .iconContainer {
  opacity: 0.8;
}
