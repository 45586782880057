.message {
  margin-bottom: 20px;

  &--blogger {
    display: flex;
    flex-direction: row;
  }

  &--user {
    display: flex;
    flex-direction: row-reverse;
  }
}

.messageContent {
  max-width: 420px;
  background-color: #EFF1FF;
  padding: 10px 12px;
  border-radius: 10px;
  white-space: pre-wrap;
}

.message-info {
  &--blogger {
    display: flex;
    justify-content: flex-start;
  }

  &--user {
    display: flex;
    justify-content: flex-end;
  }
}

.avatar--blogger {
  img {
    width: 30px;
    height: 30px;

    margin-top: 2px;
    margin-right: 10px;

    border-radius: 50%;
  }
}

.avatar--user {
  width: 30px;
  height: 30px;

  margin-top: 2px;
  margin-left: 10px;

  border-radius: 50%;

  background-color: #FF659C;
  color: white;

  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  width: 380px;
  height: 210px;
  border-radius: 10px;
}