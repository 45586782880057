@import "@palette";

.trans {
    :hover {
        transition: ease-in;
    }

    transition-duration: 2s;
}

.abs {
    position: absolute;
    height: 50px;
    width: 240px;
    margin-top: -55px;
    margin-left: -120px;
    background-color: #6071FF;
    border-radius: 4px;
}


.triangle {
    width: 0;
    height: 0;
    margin-top: -5px;
    margin-right: -10px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #6071FF;
}

.unlock {
    margin-left: 15px;
}

.margin {
    margin-left: 15px
}

.margin--unlock {
    margin-left: 14.5px
}

.link {
    color: #FFFFFF;
    text-decoration: underline;

    &:focus,
    &:hover,
    &:visited,
    &:link,
    &:active {
        color: #FFFFFF;
        text-decoration: underline;
    }
}