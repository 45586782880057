.chatItem {
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 12px 12px;
  border-radius: 10px;

  margin-bottom: 2px;

  cursor: pointer;

  &--active {
    background-color: #EFF1FF;
  }
}

.chatItem:hover {
  background-color: #EFF1FF;
}

.content {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 10px;
  margin-top: -4px;
}

.avatar {
  img {
    width: 40px;
    height: 40px;

    border-radius: 50%;
  }
}

.online {
  position: absolute;
  bottom: 3px;
  right: 3px;

  width: 10px;
  height: 10px;
  border-radius: 50%;

  background: #10BF61;
  border: 2px solid #FFFFFF;
}

.avatarContainer {
  position: relative;
}

.unreadCount {
  background: #6071FF;
  border-radius: 50%;
  width: 17px;
  height: 17px;
  color: white;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}