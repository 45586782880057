.add{
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	margin-right: 20px;
	width: 200px;
}

.popup {
	margin-top: 1px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(57, 63, 73, 0.3);
    border-radius: 4px;
    padding: 6px;
    width: 200px;

    p {
        cursor: pointer;
        width: 100%;
        height: 38px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        /* identical to box height, or 129% */

        /* Gray 1 */
        color: #323841;
        margin: 0 0 2px 0;
        padding: 10px;

        &:hover {
            background: #F5F6F8;
        }

        border-radius: 4px;
    }
}
