@import "@palette";

.root {
	width: 100%;
	min-height: 213px;

	background: $color-white;

	border-radius: 4px;

	padding: 4px;

	cursor: text;

	position: relative;

	transition: border .3s;

	&--default {
		composes: root;
		border: 2px solid $color-gray-6;
	}
	&--focused {
		composes: root;
		border: 2px solid $color-violet-1;
	}
}

.container {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	position: relative;

	max-height: 400px;
	min-height: 200px;

	overflow-y: scroll;

	textarea {
		flex-grow: 1;
		min-width: 20px;

		// height: 32px;
		height: 100px;
		resize: none;
		&::placeholder{
			font-size: 12px;
		}
		&:focus::placeholder {
			color: transparent;
		}

		font-size: 14px;
		color: #2A2A31;

		background-color: transparent;

		outline: none;
		border: none;
	}
}

.item {
	background-color: $color-violet-1;

	border-radius: 4px;

	padding: 0 5px 0 10px;

	height: 30px;

	cursor: pointer;
}

.icon {

	margin-left: 2px;
	
	path {
		fill: $color-white;
	}
}

.counter {
	position: absolute;
	bottom: 0;
	right: 5px;

	opacity: .4;
}
.helper {
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	bottom: 0;
	font-size: 14px;
	color: $color-gray-new-3;
	& b{
		color: $color-gray-new-2;
	}

}