@import "@palette";

.settings {
  position: absolute;
  height: 60px;
  background: #F8F9FB;
  display: flex;
  align-items: center;

  padding: 20px 16px 20px 5px;
  right: 0;
  top: 0;
  cursor: pointer;

  path {
    fill: $color-gray-3;
  }
}

.menu {
  background: #FFFFFF;
  box-shadow: 0px 4px 10px rgba(57, 63, 73, 0.3);
  border-radius: 4px;
  padding: 10px 22px;
}

.menu-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  cursor: pointer;
}