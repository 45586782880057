@import '@palette';

.sendIcon {
  path {
    fill: #6071FF;
  }

  cursor: pointer;

  box-sizing: content-box;
  padding: 8px 8px;
}

.messageArea {
  border: none;
  flex-grow: 1;
  outline: none;
  line-height: 18px;
  font-size: 14px;
  color: $color-gray-1;
  background: transparent;
  resize: none;
  height: 25px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}