@import '@palette';

.root {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    margin-bottom: 20px;
    width: 280px;
    height: 450px;
    display: flex;
    flex-direction: column;
    position: relative;

    transition: max-height 0.5s;

    &--with-specialOffer {
        composes: root;
        border-radius: 0 0 10px 10px;
        @media (max-width: 600px) {
            margin-top: 40px;
        }
    }

    @media (max-width: 600px) {
        margin: {
            right: auto;
            left: auto;
            bottom: 20px;
        }
    }
    // @media (max-width: 370px) {
    //     width: 100%;
    //     margin: 0 0 20px 0;
    // }
}
.specialOffer {
    display: flex;
    justify-content: center;
    color: #fff;
    font-size: 14px;
    line-height: 36px;
    padding: 4px 6px;
    border-radius: 10px 10px 0 0;
    position: absolute;
    top: -44px;
    right: 0px;
    width: 100%;
}
.card_label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    margin: 0;
    color: $color-gray-1;
}
.brief {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 140%;
    color: $color-gray-3;
    margin: 0;
    padding: {
        top: 10px;
        bottom: 10px;
    }
}
.pros {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    overflow: hidden;
    transition: max-height 0.3s;
    position: relative;
    margin-top: 15px;

    &--item {
        display: flex;
        align-items: center;
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: $color-gray-1;
    }
}
.icon {
    width: 12;
    height: 8;
    margin: 0;
    margin-right: 10px;
    path {
        fill: #f58990;
    }
}
.checkIcon{
     margin-left: 5px;
     margin-right: 10px;
}
.btns {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-grow: 1;
    Button {
        width: 100%;
    }
}
.checkbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 5px;
    &--title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 140%;
        color: $color-gray-3;
    }
}

.modal{
    width: 360px;
    border-radius: 10px;
    padding: 26px 26px 40px 30px;
}

.modal--input{
    width: 100%;
    height: 36px;
    padding: 6px 10px 6px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    outline: none;
    background-color: transparent;
}

.button{
    width: 100%;
    height: 42px;
}