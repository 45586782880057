.root {
	transition: color 0.3s;
	text-decoration: none;
	font-weight: bold;

	color: #6172ff;
	font-size: 18px;

	cursor: pointer;

	&:hover {
		color: #525ec2;
	}
}

.hide {
	display: none;
}
