.locker {
    margin-right: 8px;
    path {
        fill: #fff;
    }
}
.second-button{
    @media (max-width: 600px) {
        margin-top: 10px;
    }
    @media (min-width: 601px) {
        margin-left: 10px;
    }
}
.column{
    @media (max-width: 600px) {
        flex-direction: column;
    }
}
