@import "@palette";

.nameInput input {
  border: unset;
  background-color: $color-common-background;

  font-size: 30px;
}

.nameInput input:focus {
  outline: unset;
}

.icon{
    height: 16px ;
    width: 16px;
}