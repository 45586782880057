.root{
  background-color: white;
  padding: 20px 24px;
  border-radius: 10px;
}
.blur {
  transition: filter 1s;
  white-space: nowrap;
  &--active {
    composes: blur;
    filter: blur(10px);
  }

  &--default {
    composes: blur;
    filter: blur(0px);
  }
}