.root {
	min-height: 100vh;
	padding-bottom: 50px;
	header {
		background: #52599A;
	}
}

.container {
	composes: globalWidth from global;
	h1 {
		font-size: 30px;
		color: #2a2a31;
		margin: 40px 0 20px;
	}
}

.main {
	composes: globalWidth from global;
}