.icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    &--orange {
      composes: icon;
      background-color: #ec8e0026;
  
      path {
        fill: #EC8E00;
      }
    }
  
    &--red {
      composes: icon;
      background-color: #FF4A7626;
  
      path {
        fill: #FF4A76;
      }
    }
  
    &--grey {
      composes: icon;
      background-color: #9D74B726;
  
      path {
        fill: #9D74B7;
      }
    }
  
    &--blue {
      composes: icon;
      background-color: #0050EC26;
  
      path {
        fill: #0050EC;
      }
    }
  
    &--green {
      composes: icon;
      background-color: #6EAD0726;
  
      path {
        fill: #6EAD07;
      }
    }
  
    &--violet {
      composes: icon;
      background-color: #B53DFF26;
  
      path {
        fill: #B53DFF;
      }
    }
  }