.root {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1000vw;
    height: 1000vh;
    padding: 30px;
    z-index: 2001;

    background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
    position: relative;
    width: 550px;
    background-color: white;
    padding: 32px 30px;

    border-radius: 4px;

    &--close {
        cursor: pointer;

        path: {
            fill: #929EB0;
        }
    }
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #323841;
    margin: 0;

    &-sub {
        composes: title;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #5D697D;
        margin: 20px 0 30px 0;
    }
}

.line {
    position: relative;
    left: -30px;
    width: 550px;
    height: 1px;
    background: #E6EAF0;
    margin-bottom: 20px;
}
.icon{
    cursor: pointer;
    path{
        fill:  #929EB0;
    }
}