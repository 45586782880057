@import "@palette";
.root {
	// composes: card from global;
	padding: 15px 20px 55px;
	background: $color-gray-9;
	border-radius: 4px;
	margin-top: 20px;
}
.data {
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 211px;
	margin: 22px 0 0 40px;
	position: relative;
	
	transition: filter 1s;

	&--hidden{
		composes: data;
		filter: blur(10px);
	}
	&--default{
		composes: data;
		filter: blur(0px);
	}
}

.grid {
	display: flex;
	align-items: center;
	margin-left: -60px;
	position: relative;
	padding-left: 60px;
	&_str {
		width: 40px;
		text-align: right;

		position: absolute;
		top: -8px;
		left: 0;

		font-family: Montserrat;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;

		color: $color-gray-3;
	}

	&_line {
		flex-grow: 1;
		height: 1px;
		background: #f1f1ee;
	}
}

.chart {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: space-around;
}

.hat {
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media (max-width: 600px){
		flex-direction: column;
		align-items: flex-start;
	}
}
@media (max-width: 600px) {
	.data{
		margin: 0 0 0 80px;
	}
	.chart{
		flex-direction: column;
	}
	.root{
		padding: 15px 20px 20px;
	}
}