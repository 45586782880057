.noData {
  position: absolute;
  left:0;
  right:0;
  top: 50%;

  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  
  text-align: center;
}