@import "@palette";

.root {
	background: $color-white;
	box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);
	border-radius: 4px;
	border: 1px solid $color-gray-6;
	
	list-style: none;

	padding: 6px;
}

.item {
	padding: 0 10px;
	cursor: pointer;

	line-height: 32px;
		
	border-radius: 4px;

	&:hover {
		background-color: $color-gray-8;
	}
}

.btn {
	width: 22px;
	height: 4px;
	
	position: relative;
}

.fill {
	position: absolute;
	top: -18px;
	left: -10px;

	width: 40px;
	height: 40px;

	border-radius: 50%;

	background-color: transparent;
	
	transition: background-color .3s;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background-color: #F1F1EE;
	}
}