@import "@palette";

.popper {
  z-index: 5;

  width: 240px;

  padding: 6px;

  background: $color-white;

  border: 1px solid $color-gray-6;
  border-radius: 4px;

  box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);
}

.item {
  min-height: 32px;

  border-radius: 4px;

  padding: 0 10px;

  cursor: pointer;

  user-select: none;

  &:hover {
    background-color: $color-gray-8;
  }

  &--main {
    composes: item;
    width: 240px;
  }
}

.done {
  path {
    fill: $color-violet-1;
  }

  &--placeholder {
    width: 20px;
    height: 20px;
  }
}