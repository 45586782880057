@import "@palette";

.grid {
	display: grid;
	grid-template-columns: 2% 30% 20% 18% 10% 20%;
	padding: 0 16px 0 16px;
}

.header {
	composes: grid;
	width: 100%;
	height: 60px;
	background: #F8F9FB;
	;
	border-radius: 4px;
	text-align: center;

	position: -webkit-sticky;
	position: sticky;
	top: 69px;
	z-index: 4;
}

.column-title {
	display: flex;
	align-items: center;

	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 10px;
	line-height: 14px;
	color: #929EB0;

	height: 60px;
	margin: 0 5px 0 5px;
}

@media (max-width: 600px) {
	.header {
		display: none;
	}
}

.delete {
	margin: 0 0 0 8px;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 10px;
	line-height: 12px;
	/* identical to box height, or 120% */

	/* Gray 2 */
	color: #5D697D;
	cursor: pointer;
}