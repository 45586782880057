.root {
	min-height: 100vh;
	background: linear-gradient(163.13deg, #530D8A 23.64%, #B8A6FF 85.81%);
	padding-bottom: 50px;
}

.h1 {
	composes: globalWidth from global;
	font-weight: 600;
	font-size: 30px;
	color: #FFFFFF;
	font-weight: normal;
	padding: {
		top: 21px;
		bottom: 16px;
	}
}

.card {
	background: #FFFFFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
	border-radius: 12px;
}

.container {
	composes: globalWidth from global;
	display: flex;
	justify-content: space-between;
	@media (max-width: 770px) {
		flex-direction: column;
	}
}

.legend {
	composes: card;
	flex-grow: 1;
	margin-right: 30px;
	padding: 0 40px 20px;
	h2 {
		font-size: 22px;
		line-height: 28px;
		color: #2A2A31;
		font-weight: normal;
		margin: 0 0 15px;
	}
	p {
		font-size: 14px;
		line-height: 18px;
		color: #2A2A31;
		margin: 0;
	}
	@media (max-width: 770px) {
		margin-right: 0px;
		padding: 0 20px 20px;
		margin-bottom: 20px;
	}
}

.emoji {
	display: flex;
	justify-content: space-between;
	margin-bottom: 50px;
	img {
		width: calc((100% - 100px) / 3);
		@media (max-width: 770px) {
			width: 100px;
		}
	}
	@media (max-width: 770px) {
		margin-bottom: 20px;
	}
}

.contacts {
	composes: card;	
	padding: 25px 30px;
	min-width: 360px;
	h2 {
		font-weight: normal;
		font-size: 22px;
		line-height: 28px;
		color: #2A2A31;
		margin: 0 0 20px;
	}
	ul {
		list-style-type: none;
		padding-left: 0;
		li {
			margin-bottom: 20px;
			h3 {
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
				color: #888888;
				margin: 0 0 2px;
			}
			p {
				font-weight: 600;
				font-size: 18px;
				line-height: 23px;
				color: #2A2A31;
				margin: 0;
			}
		}
	}
	@media (max-width: 770px) {
		width: 100%;
		min-width: 0;
	}
}