@import "@palette";

.website-input-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;

  label {
    font-size: 14px;
    font-weight: 500;
  }
}

.input-wrapper {
  display: flex;
  gap: 8px;
  position: relative;
  height: 100%;

  input {
    flex: 1;
    padding: 4px 36px 4px 4px;
    border: 1px solid $color-gray-11;
    border-radius: 4px;
    font-size: 14px;
    height: 36px;
    color: $color-gray-new-1;
    width: 100%;
    &:focus-visible {
        outline: none;
    }
    &::placeholder {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      color: $color-gray-new-3;
    }
  }

  .add-button {
    position: absolute;
    top: 50%;
    right: 2px;
    transform: translateY(-50%);
    background-color: $color-violet-1;
    color: $color-white;
    border: none;
    padding: 0;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.websites-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.website-chip {
  background-color: $color-gray-new-3;
  border-radius: 4px;
  padding: 7px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  color: $color-white;

  .remove-button {
    cursor: pointer;
    font-size: 12px;
    color: $color-white;

    &:hover {
      color: $color-gray-6;
    }
  }
}
