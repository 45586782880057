@import "@palette";

.root {
	background: $color-common-background;
}

.layout {
	composes: globalWidth from global;
}

.sort {
	cursor: pointer;
	font-family: 'Montserrat';
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	/* identical to box height, or 133% */
	text-align: center;

	/* Gray 2 */
	color: #929EB0;
	margin: 0 6px 0 0;
	
	&-active {
		composes: sort;
		color: #5D697D;
		&-svg{
			path{
				fill: #5D697D;
			}
		}
	}
}