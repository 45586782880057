@import "@palette";

.empty{
    height: 340px;
    
    border-radius: 4px;
    margin-top: 1px;

    background: #FFFFFF;
}

.wrap{
    background: #F2F4F7;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    
    @media (max-width: $mobile-break){
		width: 70px;
        height: 70px;
	}
}

.icon{
    width: 26px;
    height: 26px;
    path{
        fill: #A3ADBE;
    }
    @media (max-width: $mobile-break){
		width: 20px;
        height: 20px;
	}
}

.title{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #323841;
    margin: 24px 0 0 0;

    @media (max-width: $mobile-break){
        font-size: 15px;
        line-height: 18px;
        margin-top: 30px;

        &-sub{
            font-size: 12px;
            line-height: 16px;
            margin-bottom: 0;
        }
    }
    

    &-sub{
        composes: title;
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;

        margin-bottom: 20px;

        color: #929EB0;
    }
}