hr.divider {
  width: 100%;
  border: none;
  border-top: 2px solid #F2F4F7;
}

.messagesContainer {
  display: flex;
  flex-direction: column-reverse;
  height: 420px;
  overflow-y: scroll;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
}

.btn {
  cursor: pointer;
  height: 50px;
  width: max-content;
  background: #EFF1FF;

  border: 2px solid #DFE2FF;
  border-radius: 10px;

  padding: 16px 20px;
  margin: auto;
}

.btn:hover {
  background: #EFF1FF;

  border: 2px solid #7685FF;
}