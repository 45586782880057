@import "@palette";
.root {
  width: 100%;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 100%;
  background-color: $color-violet-1;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  & svg {
    margin-left: 4px;
    path {
      fill: white;
    }
  }
}