@import "@polette";

.btn {
    height: 56px;
    border-radius: 4px;
    background-color: $color-link;
    color:white;
    font-size: 16px;
    &--mobile{
        font-size: 12px;
        composes: btn;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &--disabled {
        composes: btn;
        background-color: #CCCCCC;
    }
    &--disabled--mobile {
        composes: btn;
        background-color: #CCCCCC;
        width: 90%;
        height: 34px;
        text-align: center;
    }
    &:not(.btn--disabled):hover {
        background: $color-link-hover;
    }
    
}

.field{
    width: 420px;
    height: 56px;
    border-radius: 4px;
    border: 1px solid #CCCCCC;
    background-color: white;
    padding: 16px 0px;
    line-height: 1px;
    &--mobile{
        composes: field;
        width: 100%;
        height: 36px;
        padding: 6px 0;
    }
}

.demo{
    position: absolute;
    top: 0px;
    right: 60px
}

.line {
    height: 1px;

    background-color: $color-light;
}