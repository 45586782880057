.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 110;

  background: rgba($color: #000000, $alpha: 0.2);
}

.icon-wrapper {
  width: 70px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;

  & svg {
    width: 20px;
    height: 20px;
  }
  &:hover {
    background-color: #E6EAF0;
  }
  &.active {
    background-color: #929EB0;
    svg path {
      fill: #FFFFFF;
    }
  }
  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}
.active {
  background-color: #929EB0;
}
.close-icon {
  cursor: pointer;
  path {
    fill: #929EB0;
  }
}

.popup {
  background-color: white;
  padding: 30px;
  border-radius: 4px;
}


.platforms {
  row-gap: 4px;
  column-gap: 4px;
  background-color: #F2F4F7;
  border-radius: 4px;
  padding: 2px;
  margin-bottom: 20px;

  //button {
  //  width: 32% !important;
  //}
  //
  //margin-top: 10px;
  //margin-bottom: 20px;
  //
  //&--icon {
  //  min-width: 16px !important;
  //
  //  path {
  //    fill: #6071FF;
  //  }
  //
  //  &-active {
  //    min-width: 16px !important;
  //
  //    path {
  //      fill: white;
  //    }
  //  }
  //
  //  &-disabled {
  //    min-width: 16px !important;
  //
  //    path {
  //      fill: white;
  //    }
  //  }
  //}
}