@import "@palette";

.root {
    border-radius: 4px;

    background-color: $color-white;

    box-shadow: 0px 4px 20px 4px rgba(0, 0, 0, 0.15);

    transition: opacity .5s, visibility .5s, max-height .5s, margin .5s, padding .5s;

    overflow: hidden;

    p {
        color: white !important;
    }

    div {
        align-items: center;
    }

    div:nth-of-type(1) {
        svg {
            display: none;
        }
    }

    &--hidden {
        composes: root;
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        margin-top: 0px;
        padding: 0 15px;
    }

    &--visible {
        composes: root;
        opacity: .99;
        visibility: visible;
        max-height: 100px;
        margin-top: 10px;
        padding: 15px 15px;

        @media (max-width: 600px) {
            margin-top: 0;
            border-top: 1px solid #f1f1f1;
        }
    }

    @media (max-width: 600px) {
        border-radius: 0;
        box-shadow: none;
    }
}

.container {
    cursor: pointer;

    width: 20px;
    height: 20px;

    svg {
        transform: scale(1.2);

        path {
            fill: white;
        }
    }
}

.animation {
    animation: show .5s linear 0s forwards;

    @media (max-width: 600px) {
        animation: show--mobile .5s linear 0s forwards;
    }
}

@keyframes show {
    from {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        margin-top: 0px;
        padding: 0 15px;
    }

    to {
        opacity: .99;
        visibility: visible;
        max-height: 100px;
        margin-top: 10px;
        padding: 15px 15px;
    }
}

@keyframes show--mobile {
    from {
        opacity: 0;
        visibility: hidden;
        height: 0;
        padding: 0 15px;
    }

    to {
        opacity: .99;
        visibility: visible;
        height: 76px;
        padding: 15px 15px;
    }
}

.opacity {
    opacity: .6;

    &:hover {
        opacity: 1
    }
}