.tableContainer {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.headerRow {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  align-items: center;
  padding: 16px;
  background-color: #F8F9FB;
  font-weight: bold;
  border-radius: 4px;
  margin-bottom: 1px;
}

.row {
  display: grid;
  grid-template-columns: 2fr 1.5fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0 16px;
  border-radius: 4px;
  margin-bottom: 1px;
  background-color: #FFFFFF;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: translateX(1px);
    background-color: #F2F5FA;
  }
}

.row > div {
  flex: 1;
}
.ava {
  width: 100%;
  height: auto;
  border-radius: 4px;
  max-width: 214px;
  max-height: 120px;
  min-width: 214px;
  min-height: 120px;
  object-fit: cover;
}
.channelIcon {
  width: 34px;
  height: 34px;
  min-height: 34px;
  min-width:34px;
  border-radius: 50%;
  margin-right: 10px;
}
.cell {
  padding: 16px;
}
.textClamp {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Ограничение на 3 строки */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis; /* Добавляет многоточие */
  max-width: 100%; /* Ограничение по ширине контейнера */
  word-wrap: break-word; /* Перенос длинных слов */
  word-break: break-word; /* Перенос длинных слов */
}

