.root {
  display: flex;
  justify-content: space-between;

  position: relative;
  flex-direction: column;

  @media (max-width: 600px) {
    padding: 12px 16px;

    &--main {
      flex-direction: column;
    }
  }

}

.header {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

@media (max-width: 600px) {
  .header {
    flex-direction: column;
  }
}

.MetricItem {
  max-width: 100px;
  margin-left: 20px;
  flex: 1;

  &--value {
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px;
  }

  &--name {
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

@media (max-width: 600px) {
  .MetricItem {
    margin: 20px 20px 0 0;

    &--value {
      font-size: 14px;
    }

    &--name {
      font-size: 8px
    }
  }
}

.Metrics {

}

@media (max-width: 600px) {
  .Metrics {
    margin-top: 20px;
  }
}
