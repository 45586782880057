@import "@palette";

.criteriaContainer {
  background-color: $color-gray-new-7;
  padding: 24px;
  border-radius: 10px;
  flex-direction: column;
}
.title-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid $color-gray-11;
  margin-bottom: 10px;
  & svg {
    width: 18px;
    height: 18px;
  }
}
.info {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: $color-gray-new-5;
  color: $color-gray-new-4;
  font-size: 12px;
  text-align: center;
  margin-left: 6px;
  cursor: pointer;
  &:hover {
    background-color: $color-gray-new-4;
    color: $color-white;
  }
}

.criterion {
  padding-bottom: 12px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-gray-11;
  margin-bottom: 10px;
  &:last-child {
    border-bottom: none;
    margin-bottom: 0;
  }
}

.criterionHeader {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333;

  .icon {
    margin-left: 8px;
    color: #888;
  }

  .editButton {
    background: none;
    border: none;
    color: #888;
    cursor: pointer;
    font-size: 14px;
    &:hover {
      color: #000;
    }
  }
}

.criterionValues {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  .value {
    background-color: $color-gray-new-3;
    border-radius: 4px;
    padding: 7px 10px;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: $color-white;

    .remove {
      margin-left: 8px;
      cursor: pointer;
      font-weight: bold;
    }
  }
}
