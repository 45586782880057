.root {
    position: absolute;
    top: -100%;
    left: -100%;
    width: 1000vw;
    height: 1000vh;
    padding: 30px;
    z-index: 110;

    background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
    background-color: white;
    padding: 30px;
    border-radius: 4px;
    height: 100vh;
}



.close {
    cursor: pointer;

    path {
        color: #929EB0;
    }
}




.divider {
    margin: -30px 0 -30px;
    width: 1px;
    height: 150%;

    background: #E6EAF0;
}

.dividerHoriz {
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}


.table {
    &-header {
        position: sticky;
        top: 0;
        border-radius: 4px;
        background: var(--Gray-7, #F2F4F7);
        height: 48px;
        width: 100%;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
    }
    &-item{
        border-radius: 4px;
        height: 48px;
        padding: 0 16px;

        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        gap: 20px;
    }
}