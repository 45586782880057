@import "@palette";

.selectorWithInput {
  position: relative;
}

.selector {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  height: 36px;
  padding: 6px 12px;
  input{
    border: none;

      // border: 1px solid #CCCCCC;
  // border-radius: 4px;
  height: 34px;
  width: 100%;

  &:focus{
    outline: none;
  }
  }
}

.icon {

  transition: transform .3s;

  path {
    fill: $color-gray-4;
  }

  &--opened {
    composes: icon;
    transform: rotate(180deg)
  }

  &--closed {
    composes: icon;
    transform: rotate(0deg)
  }
}

.list {
  background-color: white;
  max-height: 152px;
  overflow-y: scroll;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #CCCCCC;
  box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);
  padding: 4px 6px;
}

.listName {
  display: flex;
  position: relative;
  margin-bottom: 20px;
}

.listNameInput {
  border: 1px solid #CCCCCC;
  border-radius: 4px;
  height: 36px;
  width: 100%;
  padding: 6px 24px 6px 12px;
}

.listNameInput::placeholder {
  color: #ADADAD;
}

.close {
  cursor: pointer;
  position: absolute;
  right: 3px;
  top: 3px;
  padding: 3px;
  height: 30px;
  width: 30px;
  background-color: #E6EAF0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.done {
  position: absolute;
  top: 3px;
  right: 36px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #6071FF;
  border-radius: 4px;
  padding: 3px;
  height: 30px;
  width: 30px;
  margin-left: 6px;

  path {
    fill: white
  }
}