.head {
    background-color: #F8F9FB;
    align-items: center;
    padding: 7px 0 0 0;
    height: 60px;
    border-radius: 4px;
    position: -webkit-sticky;
    position: sticky;
    top: 69px;
    z-index: 4;
  
    display: grid;
    grid-template-columns: 40px 30px 1.5fr 0.5fr 0.5fr 1fr 1fr;
  
    padding: 20px 16px;
  }
  
  .head--controls {
    composes: head;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }