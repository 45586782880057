@import "@palette";

.button {
	background: $color-gray-8;
	border-radius: 4px;
	height: 34px;
	width: 34px;
	color: #888;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		background: #DDDDDB;
	}

	&--icon {
		path {
			fill: $color-gray-5;
		}
	}
}

.root {
	border-radius: 4px;
	background: #fff;
	box-shadow: 0 4px 10px rgba(0, 0, 0, .4);
	width: 240px;

	&--scrollable {
		max-height: 300px;

		@media (max-width: 600px) {
			max-height: 240px;
		}

		overflow-y: scroll;
		overflow-x: hidden;
		margin-right: 2px;

		&::-webkit-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}

		&::-moz-scrollbar {
			-webkit-appearance: none;
			width: 7px;
		}

		&::-moz-scrollbar-thumb {
			border-radius: 4px;
			background-color: rgba(0, 0, 0, .5);
			box-shadow: 0 0 1px rgba(255, 255, 255, .5);
		}
	}
}

.header {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 12px;
	line-height: 15px;
	color: $color-gray-1;
	margin: 10px;
}

.divider {
	border-style: solid;
	border-width: 0 0 1px 0;
	border-color: $color-gray-6;
}

.item-container {
	min-height: 41px;

	&:hover {
		background: $color-gray-8;
		border-radius: 4px;
	}

	cursor: pointer;
}

.item {
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 17px;
	color: $color-gray-1;
	margin: 0;
	padding: 0 10px;
	text-align: initial;
	// min-height: 41px;

	width: 100%;
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	word-wrap: break-word;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.mass-add {
	width: 140px;
	height: 28px;
	margin-left: 24px;

	border-radius: 4px;
	// color: #888;

	display: flex;
	align-items: center;
	justify-content: center;

	cursor: pointer;

	&:hover {
		background-color: rgba($color: $color-gray-5, $alpha: 0.3);
	}

	&--icon {
		path {
			fill: $color-gray-2;
		}
	}

	&--text {
		margin-left: 5px;
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;

		color: $color-gray-2;
	}
}

.mobileDivider {
	width: 743px;
	margin-left: -40px;
	border: none;
	border-top: 2px solid #F2F4F7;
}