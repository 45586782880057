@import "@palette";

.background {
	position: fixed;
	top: 0;
	left: 0;

	width: 100vw;
	height: 100vh;

	background-color: rgba(42, 42, 49, .9);

	z-index: 1600;

	overflow-y: auto;

	display: flex;
	align-items: center;
	justify-content: center;
}

.root {
	background: #FFFFFF;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.03);
	border-radius: 4px;
	
	padding: 24px 32px 32px;

	width: 460px;
}

.textarea {
	margin-top: 20px;
	margin-bottom: 20px;
}

.hat {
	display: flex;
	justify-content: space-between;
	align-items: center;

	margin-bottom: 6px;
	&__title {
		font-weight: 600;
		font-size: 12px;
		line-height: 15px;
		color: #888888;
	}

	&__clear {
		font-size: 12px;
		line-height: 15px;
		text-align: right;
		color: #6172FF;

		cursor: pointer;
	}
}

.btns {
	display: flex;
	justify-content: space-between;

	width: 100%;
}

.error {
	font-size: 12px;
	line-height: 15px;
	color: #F14141;
}

.title {
	color: $color-gray-4;
}

.input {
	border-radius: 4px !important;
	border: 2px solid $color-gray-6 !important;

	background: $color-white !important;

	&:focus {
		border: 2px solid $color-violet-1 !important;
	}
}