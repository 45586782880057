.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loadingContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.loading {
  margin-top: 20px;
  width: 40px;
  height: 10px;
  background: linear-gradient(90deg, #E6EAF0 0%, #F2F4F7 14.06%, #D6DBE6 27.08%, #F2F4F7 39.58%, #E6EAF0 100%);
  background-size: 400% 400%;
  animation: gradient 10s linear infinite;
  border-radius: 10px;
}

@keyframes gradient {
  100% {
    background-position: -50% 50%;
  }

  0% {
    background-position: 150% 50%;
  }
}

.currencyItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.value {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.icon {
  width: 11px;
  height: 11px;

  path {
    fill: #5D697D;
  }
}

.popup {
  margin-top: 1px;
  background: #FFFFFF;
  box-shadow: 0px 2px 10px rgba(57, 63, 73, 0.3);
  border-radius: 4px;
  padding: 6px;
  width: 200px;

  p {
    cursor: pointer;
    width: 100%;
    height: 38px;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    /* Gray 1 */
    color: #323841;
    margin: 0 0 2px 0;
    padding: 10px;

    &:hover {
      background: #F5F6F8;
    }

    border-radius: 4px;
  }
}