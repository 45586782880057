@import '@palette';

.btn {
    border-radius: 10px !important;
    background-color: #fff;
    height: 40px;
}

.icon {
    path {
        fill: $color-gray-3;
    }
}

.modal {
    background: $color-white;

    border: 1px solid $color-gray-6;
    border-radius: 4px;

    box-shadow: 0px 4px 20px rgba(51, 51, 51, 0.1);

    width: 240px;

    padding: 10px;
}

.link {
    display: block;
    padding: 4px 10px;
    border-radius: 4px;
    &:hover{
        background-color: $color-gray-8;
    }
}

.divider {
    margin-left: -6px;
    margin-top: 8px;
    margin-bottom: 8px;

    width: calc(100% + 12px);
    height: 1px;

    background-color: $color-gray-6;
}

.fullName {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
