@import "@palette";
.root{
	width: 100%;
	height: 280px;
	border-radius: 4px;
	padding: 24px 30px;

	background: $color-gray-9;
	@media (max-width: 600px){
		height: 250px;
		padding: 12px 16px;
	}
	
}
.blur{
		filter: blur(10px);
}
.head{
	@media (max-width: 600px){
		flex-direction: column;
		align-items: flex-start;
	}
	align-items: center;
}
.buttons{
	@media (max-width: 600px){
		margin: 12px 0 0 0;
	}
	&--blur{
		filter: blur(10px);
	}
}
.title{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	margin: 0;

	color: $color-gray-4;
}
.value{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 30px;
	line-height: 37px;

	color: $color-gray-1;

	margin: 12px 0 0 0;
	@media (max-width: 600px){
		font-size: 20px;
		margin: 8px 0 0 0;
	}
}
.helper{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 15px;
	color: $color-gray-4;

	margin: 8px 0 0 0;
	@media (max-width: 600px){
		font-size: 10px;
		line-height: 12px;
		margin: 6px 0 0 0;
	}
}
.bar {
	height: 30px;
	border-radius: 10px;
	background: $color-violet-1;
	&--filled{
		height: 30px;
		border-radius: 10px 0 0 10px;
		background: #5559D1;
		@media (max-width: 600px){
			height: 20px;
		}
	}
	margin: 30px 0 0 0;
	@media (max-width: 600px){
		height: 20px;
		margin: 12px 0 0 0;
	}
}
.types{
	margin: 30px 0 0 0;
	@media (max-width: 600px){
		margin: 16px 0 0 0;
	}
}
.type{
	font-family: Montserrat;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;

	color: $color-gray-1;
	margin: 0;
	@media (max-width: 600px){
		font-size: 12px;
		line-height: 16px;
	}
}
.card{
	width: 58px;
	height: 28px;
	border-radius: 4px;
	&--first{
		composes: card;
		background-color: rgba(85, 89, 209, 0.2);
		&--text{
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: rgba(85, 89, 209, 1);
			margin: 0;
			@media (max-width: 600px){
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
	&--second{
		composes: card;
		background-color: rgba(96, 113, 255, 0.2);
		&--text{
			font-family: Montserrat;
			font-style: normal;
			font-weight: 600;
			font-size: 14px;
			line-height: 24px;
			color: rgba(96, 113, 255, 1);
			margin: 0;
			@media (max-width: 600px){
				font-size: 12px;
				line-height: 16px;
			}
		}
	}
	
}