@import "@palette";
.title{
	margin: 0;
	font-family: Montserrat;
	font-style: normal;
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
	color: $color-gray-4;

}
.brand-container{
	@media (max-width: 600px){
		flex-direction: column;
	}
}

.root{
	border-radius: 10px;
	border: 1px solid var(--Gray-6, #E6EAF0);
	background: var(--White, #FFF);
	padding: 0 24px 24px 24px;
}