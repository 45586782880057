@import "@palette";

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  width: 700px;
  height: 652px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;

  position: relative;
}

.close {
  cursor: pointer;

  path {
    color: #A2AEC2;
  }
}

.inputField {
  width: 100%;
  flex-grow: 1;
  outline: none;
  font-size: 14px;
  color: $color-gray-1;
  min-width: 0;
  background: transparent;
  padding: 8px;

  border: 1px solid #B7C3D6;
  border-radius: 4px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}

.fieldContainer {
  width: 100%;
}

.productArea {
  width: 100%;
  height: 72px;
  border: 1px solid #B7C3D6;
  border-radius: 4px;
  outline: none;
  line-height: 18px;
  font-size: 14px;
  color: $color-gray-1;
  background: transparent;
  resize: none;
  padding: 10px 10px 30px 10px;

  &::placeholder {
    color: #A2AEC2;
    font-size: 14px;
  }
}

.generatedTemplate {
  composes: productArea;
  height: 180px;
}

.generateIcon {
  margin-right: 6px;

  path {
    fill: white;
  }
}