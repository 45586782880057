.root {
    position: absolute;
    top: -1000px;
    left: -1000px;
    width: 1000vw;
    height: 1000vh;
    padding: 30px;
    z-index: 2001;

    background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
    position: relative;
    width: 550px;
    background-color: white;
    padding: 32px 30px;

    border-radius: 4px;

    &--close {
        cursor: pointer;

        path: {
            fill: #929EB0;
        }
    }
}

.title {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    color: #323841;
    margin: 0;

    &-sub {
        composes: title;

        margin: 20px 0 6px 0;

        color: var(--Gray-1, #323841);
        /* Subtitle */
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        &-sent{
            composes: title-sub;
            font-weight: 500;
        }
    }
}

.line {
    position: relative;
    left: -30px;
    width: 550px;
    height: 1px;
    background: #E6EAF0;
    margin-bottom: 20px;
}

.icon {
    cursor: pointer;

    path {
        fill: #929EB0;
    }
}

.input {
    padding: 0 6px;
    width: 100%;
    height: 36px;
    flex-shrink: 0;
    border-radius: 4px;
    border: 1px solid var(--Gray-4, #AEBACC);
    background: var(--White, #FFF);
}

.done{
    border-radius: 50%;
    background-color: #DFE2FF;
    width: 60px;
    height: 60px;
    &-red{
        composes: done;
        background-color: #ffe1e7;
    }
}