.VideoTable {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px 40px;

  margin-top: 20px;
}

@media (max-width: 600px) {
  .VideoTable {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 0px 10px;

    margin-top: 12px;

    overflow-x: scroll;
  }
}
