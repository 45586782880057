.root {
	background: #F1F1EE;
	position: relative;
	border-radius: 10px;
	display: flex;
	align-content: flex-start;
	width: 100%;
	border: 2px solid transparent;
	&_focused {
		border: 2px solid #7583F7;
	}
	input {
		border: none;
		background: none;
		outline: none;
		font-size: 14px;
		color: #2a2a31;
		flex-grow: 1;
		padding: 8px 2px 8px 12px;
		&::placeholder {
			color: #888;
			opacity: .6;
		}
	}
}