/* components/NewSearchModal/new-search-modal.module.scss */
.container {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: 600;
}
.infoIcon {
  margin-left: 6px;
    &:hover {
        path {
          fill: #FFFFFF;
        }
      circle {
        fill: #5D697D;
      }
    }
}

.input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &::placeholder{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #929EB0;
  }
}

.brandInputContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 50px;
}

.tagInput {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  position: relative;
  min-height: 250px;
}

.brandWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  height: min-content;
  width: 100%;
}

.brandItem span {
  margin-left: 5px;
}
// brandItem span div h1 p2 #fjfjgj @dffdd12
//.brandItem button {
//  background: none;
//  border: none;
//  cursor: pointer;
//  font-size: 14px;
//}

.textInput {
  flex: 1;
  border: none;
  outline: none;
  font-size: 14px;
  min-width: 150px;
  width: 100%;
  max-height: 100px;
  height: 100px;
  resize: none;
  &::placeholder{
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    color: #929EB0;
  }
}
.searchBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-left: auto;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 12px 24px;
  max-width: 210px;
  height: 42px;
  width: 100%;
  &:disabled {
    background-color: #929EB0;
    cursor: not-allowed;
  }
}
.btnTag {
  padding: 7px 10px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  svg {
    width: 14px;
    height: 14px;
    margin-left: 6px;
    path {
      fill: #FFFFFF;
    }
  }
}
