@import "@palette";

.card {
    background-color: white;
    width: 32%;
    height: 400px;
    border-radius: 10px;
    padding: 16px;
}


.avatar {
    div {
        border-radius: 24px;
    }

    img {
        border-radius: 24px;
    }

    svg {
        top: 0;
        border-radius: 24px;
    }

    img {
        width: 40px;
        height: 40px;

        border-radius: 50%;
    }

    margin-right: 10px;
}

.text {
    margin-top: 16px;
    max-height: 210px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 9; /* Number of lines to show */
    overflow: hidden;
}
.dividerHoriz {
    margin-top: 10px;
    width: 100%;
    height: 1px;

    background: #E6EAF0;
}
.icon{
    path {
        fill: #929EB0;
    }
}