.filtersContainer {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 24px;
  padding-top: 16px;
  border-top: 1px solid #eaeaea;
}

.filterBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 8px;
  flex: 1;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }

  h4 {
    margin-bottom: 6px;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px;
    max-height: 34px;
  }
}

.mentionsCount {
  span {
    border-radius: 10px;
    padding: 6px 10px;
    background-color: #f2f4f7;
    color: #5d697d;
    font-size: 10px;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #929EB0;
  padding: 2px;
  border-radius: 8px;
  max-width: 200px;
}

.inputField {
  flex: 1;
  padding: 4px;
  font-size: 12px;
  border: none;
  outline: none;
}

.addButton {
  padding: 6px 10px;
  border: none;
  background-color: #6071ff;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  cursor: pointer;
}

.addButton:disabled {
  background-color: #dcdcdc;
  cursor: not-allowed;
}
