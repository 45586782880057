@import '@palette';

.root {
    padding: 0 10px;
}

.modal {
    padding: 30px 30px 67px;
    height: 240px;
    border-radius: 10px;
}
.title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    margin: 0;
    color: #3d3f42;
}
.text {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;

    color: $color-gray-3;
}
.btn {
    height: 42px;
    min-width: 170px;
    &--gray {
        composes: btn;
        background-color: rgba($color-gray-7, 1);
        border: none;
        &:hover {
            background-color: rgba($color-gray-7, 0.5);
        }
    }
}
.background {
    background-color: #000000;
    opacity: 60%;
}
