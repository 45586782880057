$color-gray-1: #333;
$color-gray-2: #5c5c5c;
$color-gray-3: #8f8f8f;
$color-gray-4: #adadad;
$color-gray-5: #cccccc;
$color-gray-6: #e0e0e0;
$color-gray-7: #ebebeb;
$color-gray-8: #f3f3f3;
$color-gray-9: #f9f9f9;
$color-gray-10: #8897BC;
$color-gray-11: #AEBACC;
$color-gray-new: #3a393f;
$color-gray-new-1: #323841;
$color-gray-new-2: #76849B;
$color-gray-new-3: #929EB0;
$color-gray-new-4: #5D697D;
$color-gray-new-5: #D6DBE6;
$color-gray-new-7: #F2F4F7;

$color-violet-1: #6071ff;
$color-violet-2: #7685ff;
$color-violet-3: #4d60ff;
$color-violet-4: #eff1ff;
$color-violet-5: #dfe3ff;
$color-violet-6: #6071FF;
$color-violet-light: #ACB5FF;
$color-violet-light-2: #DFE2FF;

$color-white: #fff;

$color-orange-1: #eea97a;
$color-orange-2: #fceee4;
$color-orange-3: #f8dcca;

$color-green: #21c262;
$color-green-2: #10BF61;

$color-red-1: #ef3b46;
$color-red-2: #F58990;

$color-audience-background: #393870;

$color-search-page-background: #484651;

$color-intersection-bg: #35436c;

$color-pricing-background1: #6b4bc8;
$color-pricing-background2: #4629b9;
$color-referals-background: #6B4BC8;


$color-ads-explorer: #193FA0;

$color-common-background: #F1F3F8;

$mobile-break: 600px;


:export {
    gray-1: $color-gray-1;
    gray-2: $color-gray-2;
    gray-3: $color-gray-3;
    gray-4: $color-gray-4;
    gray-5: $color-gray-5;
    gray-6: $color-gray-6;
    gray-7: $color-gray-7;
    gray-8: $color-gray-8;
    gray-9: $color-gray-9;
    gray-10: $color-gray-10;
    gray-new: $color-gray-new;
    gray-new-1: $color-gray-new-1;
    gray-new-2: $color-gray-new-2;
    gray-new-3: $color-gray-new-3;
    gray-new-4: $color-gray-new-4;
    gray-new-7: $color-gray-new-7;

    violet-1: $color-violet-1;
    violet-2: $color-violet-2;
    violet-3: $color-violet-3;
    violet-4: $color-violet-4;
    violet-5: $color-violet-5;
    violet-6: $color-violet-light-2;

    white: $color-white;

    orange-1: $color-orange-1;
    orange-2: $color-orange-2;
    orange-3: $color-orange-3;

    green: $color-green;

    red-1: $color-red-1;
    red-2: $color-red-2;

    audience-background: $color-audience-background;
    ads-explorer: $color-ads-explorer;

    search-page-background: $color-search-page-background;
    intersection-bg: $color-intersection-bg;

    pricing-background1: $color-pricing-background1;
    pricing-background2: $color-pricing-background2;
    referals-background:#6B4BC8;

    common-background: $color-common-background;

    mobile-break: $mobile-break;
}
