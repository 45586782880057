@import "@palette";

.sendBtn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 6px 10px;
  border-radius: 4px;
  margin-left: 30px;
}

.sendBtn:hover {
  background-color: rgba(204, 204, 204, 0.3);
}

.root {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1000vh;
  padding: 30px;
  z-index: 1600;

  background: rgba($color: #000000, $alpha: 0.2);
}

.popup {
  width: 500px;
  height: 600px;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
}

.close {
  cursor: pointer;

  path {
    color: #A2AEC2;
  }
}

.mailingListItems {
  height: 310px;
  overflow-y: scroll;
}

.arrowRight {
  margin-left: 10px;

  path {
    fill: white;
  }
}

.info {
  display: flex;
  justify-content: flex-start;
  margin-top: 12px;
  margin-bottom: 20px;
}

.infoSelected {
  padding: 8px 12px;
  background-color: #EFF1FF;
  border-radius: 4px;
}

.infoEmail {
  padding: 8px 12px;
  background-color: #FFF3F5;
  border-radius: 4px;
  margin-left: 10px;
}


.newBTN {
  &:hover {
    background: #F5F6F8;
  }

  cursor: pointer;
  border-radius: 4px;
  padding: 10px;

  p {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */

    /* Gray 1 */
    color: #323841;
  }
}

.campaign {
  &--block {
    height: 230px;
    overflow: scroll;
  }

  width: 100%;
  cursor: pointer;

  min-height: 60px;


  &--text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    /* identical to box height, or 129% */
    display: flex;
    align-items: center;

    /* Gray 1 */
    color: #323841;
    margin: 0;
    margin-bottom: 4px;
    &-sub {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      /* identical to box height, or 133% */
  
      /* Gray 3 */
      color: #929EB0;
      margin: 0;
    }
  }

}

.divider {
  min-height: 1px;
  width: 100%;
  /* Gray 6 */
  background: #E6EAF0;

  &--big {
composes: divider;
    width: calc(100% + 60px);
    margin-left: -30px;
  }

}

.message {
  &-icon {
    width: 18px;
    height: 18px;

    path {
      fill: #6071FF;
    }

    &-back {
      background: #EFF1FF;
      padding: 10px;
      border-radius: 50%;
    }
  }

  &-text {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    /* identical to box height, or 133% */

    /* Violet */
    color: #6071FF;
    margin: 0;

    &-sub {
      composes: message-text;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }
  }
}