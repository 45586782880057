.templateList {
  width: 480px;
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}

.list {
  max-height: 492px;
  overflow-y: scroll;
  margin-top: 20px;
  display: grid;
  grid-auto-rows: 72px;
  gap: 6px;
}

.listItem {
  cursor: pointer;
  display: grid;
  height: 90px;
  grid-template-rows: min-content;
  gap: 6px;
  padding: 10px 12px;
  background-color: #F2F4F7;
  border-radius: 4px;
}

.selected {
  background: #EFF1FF;
  border: 2px solid #6071FF;
}

.listItem:hover {
  background-color: #E6EAF0;
}

.divider {
  width: 480px;
  margin-left: -20px;
  border: none;
  border-top: 2px solid #F2F4F7;
}

.itemHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.noTemplates {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin-top: 40px;
}