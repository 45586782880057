@import "@palette";

.root {
	background: none;
	color: inherit;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
	&--highlight{
		border-radius: 4px;
		padding: 1px;
		border: 1px solid $color-violet-3;
	}
}

.checkbox {
	width: 16px;
	height: 16px;

	background: #FFFFFF;
	border: 1px solid $color-gray-5;
	box-sizing: border-box;
	border-radius: 4px;

	cursor: pointer;

	&--checked {
		composes: checkbox;
		border: 1px solid $color-gray-2;
	}

	&--dashed {
		composes: checkbox;
		background: #76849B;
		border-radius: 4px;
	}

	&--disabled {
		composes: checkbox;
		cursor: unset;
		background: #cccccc;
	}
}

.delete-button {
	// width: 138px;
	height: 28px;
	padding: 6px 10px;

	cursor: pointer;
	border-radius: 4px;

	&:hover {
		background-color: rgba($color: $color-gray-5, $alpha: 0.3);
	}

	&--text {
		font-family: Montserrat;
		font-style: normal;
		font-weight: 600;
		font-size: 10px;
		line-height: 12px;
margin-left: 8px;
		color: $color-gray-2;
	}
}