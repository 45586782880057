@import "@palette";

.root {
  background: $color-common-background;
}

.audience {
  composes: globalWidth from global;
  padding-top: 108px;
}

.create-btn {
  height: 34px;
  border-radius: 4px;
  color: $color-white;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  background-color: $color-violet-1;
  display: flex;
  align-items: center;

  &:hover {
    background-color: $color-violet-1 !important;
  }
}
.header-wrapper {
  & :global(#title) {
    color: $color-gray-new-3 !important;
  }
  & .arrow-icon {
    transform: rotate(180deg) scale(1.8);
    margin: 12px 8px 0 8px;
  }

}