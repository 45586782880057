.root {
    composes: import globalWidth from global;
}

.fixedWidth {
    composes: import globalWidth from global;
    @media (max-width: 1439px) {
        margin: 0 auto;
        width: 1180px !important;
        overflow: visible;
        padding: 0;
    }
}