.header-table {
  background-color: #F8F9FB;
  border-radius: 4px;
  margin-bottom: 1px;
  padding: 23px 20px 23px 0;
  display: grid;
  grid-template-columns: 52px 1fr 1fr 1fr 1fr 1fr;
}
.trash {
  cursor: pointer;
  width: 16px;
  height: 16px;
  &:hover {
    transform: scale(1.1);
  }
}